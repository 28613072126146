import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Container, Row, Col } from "react-bootstrap";

const ProductSkeleton = () => {
  return (
    <div className="product_Skeleton">
      <div className="webheaderSkeleton">
        <Skeleton />
      </div>
      <Container>
        <Row className="bread_crumbs">
          <Col sm="3">
            {" "}
            <div className="shop_category">
              <Skeleton />
            </div>
          </Col>
          <Col sm="3">
            <div className="shop_category">
              <Skeleton />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="py-2 deatils-main_skeleton">
        <Row>
          <Col md="9">
            <Row>
              <Col md="7">
                <Row className="d-flex">
                  <Col md="2" className="d-none d-md-block">
                    <div className="d-md-flex flex-column">
                      <Skeleton width={75} height={75} />
                      <Skeleton width={75} height={75} />
                      <Skeleton width={75} height={75} />
                      <Skeleton width={75} height={75} />
                      <Skeleton width={75} height={75} />
                      <Skeleton width={75} height={75} />
                    </div>
                  </Col>
                  <Col md="10">
                    <div className="Skeleton_img">
                      <Skeleton />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="5">
                <p>
                  <Skeleton height={43} />
                </p>
                <p>
                  <Skeleton height={24} />
                </p>
                <hr />
                <p>
                  <Skeleton height={21} width={75} className="mb-1" />
                </p>
                <p>
                  <Skeleton height={40} width={200} className="mb-2" />
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <p>
                    <Skeleton height={50} width={165} />
                  </p>
                  <p>
                    <Skeleton height={50} width={165} />
                  </p>
                </div>
                <p>
                  <Skeleton height={21} width={125} className="mt-3" />
                </p>
                <div className="d-flex align-items-center mt-2">
                  <p>
                    <Skeleton height={30} width={30} circle={true} />
                  </p>
                  <p className="ml-2">
                    <Skeleton height={30} width={30} circle={true} />
                  </p>
                </div>
              </Col>
            </Row>
            <div className="mt-4">
              <p className=" border-bottom">
                <Skeleton height={30} width={120} />
              </p>
              <p className="mt-2">
                <Skeleton height={21} />
                <Skeleton height={21} />
                <Skeleton height={21} />
                <Skeleton height={21} />
                <Skeleton height={21} />
                <Skeleton height={21} />
                <Skeleton height={21} width={500} />
              </p>
            </div>
            <div className="mt-4">
              <p className=" ">
                <Skeleton height={30} width={120} />
              </p>
              <Row className="mt-2">
                <Col md="4">
                  <div>
                    <Skeleton height={210} />
                    <p>
                      <Skeleton height={44} />
                    </p>
                    <p>
                      <Skeleton height={21} width={75} />
                    </p>
                    <p>
                      <Skeleton height={21} width={125} />
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <Skeleton height={210} />
                    <p>
                      <Skeleton height={44} />
                    </p>
                    <p>
                      <Skeleton height={21} width={75} />
                    </p>
                    <p>
                      <Skeleton height={21} width={125} />
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <Skeleton height={210} />
                    <p>
                      <Skeleton height={44} />
                    </p>
                    <p>
                      <Skeleton height={21} width={75} />
                    </p>
                    <p>
                      <Skeleton height={21} width={125} />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md="3">
            <div className="d-flex align-items-center">
              <p className="mr-2">
                <Skeleton height={30} width={30} circle={true} />
              </p>
              <span>
                <Skeleton height={30} width={165} />
              </span>
            </div>
            <hr />
            <p>
              <Skeleton height={50} />
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductSkeleton;
