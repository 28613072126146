import React, { Fragment, useMemo } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
const StikcyActionBar = (props) => {
  const {
    addToCart,
    selectedAdOns: selectedAddOns,
    storeFrontData,
    multipleSelectedAddOns,
    key = null,
    type,
    isDeal,
    allAddons,
    product_deals,
    ...rest
  } = props;
  const getPrice = (item) => {
    let discount = '';
    if (item?.quantity_available <= 0) {

      return (<b>Out of Stock</b>)
    } else if (storeFrontData.config['0'].separate_addon_price === 'Y' && (parseInt(item?.storeFrontActualPrice) === 0 || item?.storeFrontActualPrice === '')) {
      let price = 0;
      if (item.add_ons && item.add_ons?.length > 0) {
        price = parseInt(item?.add_ons[0]?.items[0]?.price)
        item.add_ons.map((i, index) => {
          i.items.map(adon => {
            if (parseInt(adon.price) < price) {
              price = parseInt(adon.price)
            }
          })
        })
      }
      return <>From {getPriceWithCurrency(parseInt(price))} {discount} </>
    } else {

      if (item?.call_for_price === 'Y') {
        return (<b>Call For Price</b>)
      } else {
        if (item?.storeFrontActualPrice != '' && item?.storeFrontActualPrice != 0 && item?.storeFrontActualPrice > item?.storeFrontPrice) {
          discount = <strike>{storeFrontData.config['0'].currency} {parseInt(item?.storeFrontActualPrice).toLocaleString()}</strike>


        }

        return <b>{storeFrontData.config['0'].currency}  {parseInt(item?.storeFrontPrice).toLocaleString()} {discount} </b>


      }
    }
  }

  const getPriceWithCurrency = (price) => {
    if (storeFrontData.config['0'].currency_position === 'left') {
      return storeFrontData.config['0'].currency + ' ' + parseInt(price).toLocaleString()
    } else {
      return <>{parseInt(price).toLocaleString() + ' '}<small>{storeFrontData.config['0'].currency} </small></>
    }
  }
  // useEffect(()=> {
  //   filterAddOns()
  // }, [props.xkey])

  const aligned = useMemo(() => {
      let f = [];
      if (!allAddons?.length || isDeal) return f;
      allAddons.map((adon, i) => {
          let curent = [];
          let selectedFiltered = selectedAddOns.filter((sadon) => sadon.add_on_id === adon.id);
          if (selectedFiltered.length > 0) {
              curent.heading = adon.heading;
              curent.items = selectedFiltered;
              f.push(curent);
          }
      });
      if (key || type) return f;
      return f;
  }, [allAddons, selectedAddOns, key, type, isDeal]);
  
  return (
    <div className="StikcyActionBar fixed-bottom d-flex align-items-center">
      <Row className="align-items-center w-100 no-gutters">
        <Col lg={"6"}>
            <SelectedItems {...{aligned, isDeal, getPriceWithCurrency, multipleSelectedAddOns, product_deals}} />
        </Col>
        <Col lg={"6"}>
          <div className="sticky-add-btn d-flex align-items-center justify-content-end">
            <p className="mr-2  font-14">
              Total Amount: <strong> {getPriceWithCurrency((parseFloat(props.addOnsprice) + parseFloat(props.pprice)) * props.quantity)}</strong>
            </p>

            <div className="qty-box mr-2">
              <div className="quantity-box d-flex align-items-center justify-content-between">
                <Button variant="link" className="p-0">
                  <i>
                    <FaMinus
                      onClick={(e) => { props.seQuatintyAdd('sub') }}
                    />
                  </i>
                </Button>
                <span>{props.quantity}</span>
                <Button variant="link" className="p-0">
                  <i>
                    <FaPlus
                      onClick={(e) => { props.seQuatintyAdd('add') }}
                    />
                  </i>
                </Button>
              </div>
            </div>
            <div className="btns-group d-flex btn-g p-0">
              <Button
                className={`${window.$isStoreFront ? "store-front-btn1" : "cta-one"
                  } bg-transparent text-uppercase font-14`}
                onClick={() => props.addToCart(props.product)}
                disabled={window.$allowCart}
              >
                Add to Cart
              </Button>
              <Button className={`${window.$isStoreFront ? "store-front-btn2" : "cta-two"} text-uppercase font-14`}
                onClick={() => props.addToCart(props.product)}
                disabled={window.$allowCart}
              >
                Buy it Now
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StikcyActionBar;

function SelectedItems({ aligned, isDeal, getPriceWithCurrency, multipleSelectedAddOns, product_deals }) {
  
    if (!isDeal) {
      return (
        <div className="addons_list d-flex flex-wrap">
          {aligned?.map((adon, index) => (
              <p key={index} className="mr-3  font-14">
                  <strong>{adon.heading}: </strong>
                  {adon.items.map((item, idx) => (
                      <Fragment key={idx}>
                          {item?.name} {getPriceWithCurrency(item?.price)}
                          {index < adon.items.length - 1 ? ", " : ""}
                      </Fragment>
                  ))}
              </p>
          ))}
        </div>
      )
    }
  return product_deals.map(({ product_id, name, deal_quantity }, idx) => {
      if(!multipleSelectedAddOns || !multipleSelectedAddOns[`${product_id}_${idx}`]?.length) return null;
      return (
          <div key={idx} className="addons_list d-flex flex-wrap">
              <p className="mr-3  font-14">
                  <strong>{name}: </strong>
                  {multipleSelectedAddOns[`${product_id}_${idx}`]?.map((item, i) => (
                      <Fragment key={i}>
                          {item?.name} ({getPriceWithCurrency(parseFloat(item?.price))})
                          {i < multipleSelectedAddOns[`${product_id}_${idx}`]?.length - 1 ? ", " : ""}
                      </Fragment>
                  ))}
              </p>
          </div>
      );
  });
}
