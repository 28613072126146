import React, { Component } from "react";
import {Image } from "react-bootstrap";


export default class Loader extends Component {
  render() {
    return (
      <>
        <div className="h-100 w-100 loaderoverlay">
          <div className="loading-main">
            <div id="loading-bar-spinner" className="spinner">
              <div className="spinner-icon"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
