import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Container, Row, Col } from "react-bootstrap";

const StoreFrontSkeleton = () => {
  return (
    <>
      <div className="storefront_skeleton">
        <div className="webheaderSkeleton">
          <Skeleton />
        </div>
        <Row>
          <Col sm="12">
            <Skeleton height={550} />
          </Col>
        </Row>
        <Container>
          <p className="mt-3">
            <Skeleton height={30} width={125} />
          </p>
          <p className="mt-2">
            <Skeleton height={25} width={75} />
          </p>
          <Row className="my-3">
            <Col md="1">
              <p>
                <Skeleton height={25} />
              </p>
            </Col>
            <Col md="2">
              <p>
                <Skeleton height={25} />
              </p>
            </Col>
            <Col md="3">
              <p>
                <Skeleton height={25} />
              </p>
            </Col>
            <Col md="3">
              <p>
                <Skeleton height={25} />
              </p>
            </Col>
            <Col md="3">
              <p>
                <Skeleton height={25} />
              </p>
            </Col>
          </Row>
          <div className="mt-4">
            <p className=" ">
              <Skeleton height={30} width={120} />
            </p>
            <Row className="mt-2">
              <Col md="3">
                <div>
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-4">
            <p className=" ">
              <Skeleton height={30} width={120} />
            </p>
            <Row className="mt-2">
              <Col md="3">
                <div>
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div>
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div className="mt-3">
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div className="mt-3">
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div className="mt-3">
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
              <Col md="3">
                <div className="mt-3">
                  <Skeleton height={210} />
                  <p>
                    <Skeleton height={21} width={125} />
                  </p>
                  <p>
                    <Skeleton height={21} width={75} />
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="webheaderSkeleton mt-5">
          <Skeleton />
        </div>
      </div>
    </>
  );
};

export default StoreFrontSkeleton;
