import React, { useState } from "react";
import { Nav, Accordion, useAccordionToggle, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const SmashFooterAccordian = (props) => {
  const [show, setShow] = useState(false);
  const [show2, setshow2] = useState(false);
  const currentMenu = useSelector(state => state.theme.current_menu)
  const storeData = useSelector((state) => {
    return { ...state.mall.storeFrontData?.store?.data }
  })
  const storefrontData = useSelector((state) => {
    return { ...state.mall.storeFrontData?.store }
  })
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleClose2 = () => {
    setshow2(false);
  };
  const handleShow2 = () => {
    setshow2(true);
  };

  const [panelIndex, setPanelIndex] = useState();

  const CustomToggle = ({ children, eventKey }) => {
    // Decorate the click event to also set state in the component so we can
    // compare the open panel key with the state and toggle classes accordingly
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey);
    });

    const customClass = eventKey === panelIndex ? "open-class" : "closed-class";

    return (
      <Nav.Link className={customClass} onClick={customOnClick}>
        {children}
      </Nav.Link>
    );
  };

  return (
    <>
      <Accordion
        defaultActiveKey="1"
        className={`accordiancategories footeraccordian ${props.bgColor}`}
      >
        <CustomToggle eventKey={1}>
          <span className="text-white font-14">Company</span>
          <i className="icon-arrowdown-i"></i>
        </CustomToggle>

        <Accordion.Collapse eventKey={1}>
          <div className="footer_box">
            <div className="footer_Links">
              {

                currentMenu?.length > 0 && currentMenu?.map((menu, index) => {
                  return (<Link className="d-block font-12" to={menu.link}>{menu.title}</Link>)
                })
              }

            </div>
          </div>
        </Accordion.Collapse>
        {
          window.$storeId !== "1791" ?
            <CustomToggle eventKey={2}>

              <span className="text-white font-14">Contact us</span>
              <i className="icon-arrowdown-i"></i>
            </CustomToggle>
            : ''
        }
        {
          window.$storeId !== "1791" ?
            <Accordion.Collapse eventKey={2}>
              <div className="footer_box">
                <div className="footer_Links">

                  {
                    storeData?.store_email != '' && storeData?.store_email != undefined && storeData?.store_email != null ? (<Link className="d-block font-12">
                      Email: {storeData?.store_email}
                    </Link>) : ""
                  }
                  {
                    storeData?.contact_mobile != null && storefrontData.show_contact_mobile === 'Y' ? (<Link className="d-block font-12">
                      Call us: {storeData?.contact_mobile}
                    </Link>) : ""
                  }

                  {
                    storeData?.open_time != '' && storeData?.close_time != '' ? (<Link className="d-block font-12">
                      Time: {storeData?.open_time} - {storeData?.close_time}
                    </Link>) : ""
                  }
                  {
                    window.$storeId === "827135" || window.$storeId === "843155" ?
                      <>
                        <Link className="d-block font-12">
                          Gulberg Branch: 51-MM Alam Road, Block B 1, Gulberg 3, Lahore
                        </Link>
                        <Link className="d-block font-12">
                          DHA Branch: Gold Crest, Grand Mall, Sector DD Dha Phase 4, Lahore
                        </Link>
                      </>
                      : ''
                  }
                </div>
              </div>
            </Accordion.Collapse>
            :
            <Accordion.Collapse eventKey={2}>
              <div className="footer_box">
                <div className="footer_Links">
                  <Link className="d-block font-12">
                    Email: monis.rahman@gmail.com
                  </Link>
                  <Link className="d-block font-12">Call us: +92 306 8904585</Link>

                </div>
              </div>
            </Accordion.Collapse>}
      </Accordion>
    </>
  );
};

export default SmashFooterAccordian;
