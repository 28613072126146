import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddonsAccordian from "../../../storefront/ThemeSpringtide/ThemeSpringtideComponents/AddonsAccordian";
import StikcyActionBar from "../../../storefront/ThemeSpringtide/ThemeSpringtideComponents/StikcyActionBar";
import { ConditionalRender, Each } from "../../../../util/Helper";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Accordion, Button } from "react-bootstrap";
import { AddAddOns, AddOnsModal, unSetStates } from "../../../../store/actions/addonsactions";
import { updateCartItems, updateFinalCartData } from "../../../../store/actions/mallActions";
import { toast } from "react-toastify";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { makeAnalyticsFunnel } from "../../../../store/actions/CommonActions";
import { useHistory } from "react-router-dom";

function AddOnsSection({ products, pPrice, showStikcyActionBar = true, addToCartFromModal, setSelectedAddOnsPrice }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { addons, product_deals, product_type } = products ?? {};
    const [{ key, type }, reRenderParant] = useState({ key: null, type: null });
    const [quantity, setQuantity] = useState(1);
    const [error, setError] = useState({});
    const [open, setOpen] = useState(0);
    const {
        // selectedAddOnIds,
        // multipleSelectedAddOnIds,
        selectedAddOns,
        multipleSelectedAddOns,
        cartData,
        storeFrontData,
        currentLayout,
    } = useSelector((state) => ({
        // selectedAddOnIds: state.addons.selectedAddOnIds,
        multipleSelectedAddOns: state.addons.multipleSelectedAddOns,
        // multipleSelectedAddOnIds: state.addons.multipleSelectedAddOnIds,
        selectedAddOns: state.addons.selectedAddOns,
        cartData: state.mall.cartData,
        storeFrontData: state.mall.storeFrontData,
        currentLayout: state.theme.current_layout,
    }));

    useEffect(() => {
      if (addToCartFromModal) addToCartFromModal(addToCart);
    }, [selectedAddOns, multipleSelectedAddOns, addToCartFromModal]); // eslint-disable-line react-hooks/exhaustive-deps

    const addOnsprice = useMemo(() => {
        let totalPrice = 0;
        if (!key || !type) return totalPrice;
        if (product_type === "deal") {
            totalPrice = product_deals.reduce((acc, { product_id, deal_quantity }, idx) => {
                if (!multipleSelectedAddOns[`${product_id}_${idx}`]?.length) return acc;
                return multipleSelectedAddOns[`${product_id}_${idx}`]?.reduce((subAcc, { price }) => {
                    return subAcc + parseFloat(price);
                }, acc);
            }, totalPrice);
        } else {
            totalPrice = selectedAddOns.reduce((acc, { price }) => acc + parseFloat(price), totalPrice);
        }
        setSelectedAddOnsPrice?.(totalPrice);
        return totalPrice;
    }, [multipleSelectedAddOns, selectedAddOns, product_deals, product_type, key, type]);

    const productPrice = useMemo(() => {
        let isDeal = product_type === "deal";
        if ((!products || products?.quantity_available <= 0 || products?.call_for_price === "Y") && !isDeal) return 0;
        if (products?.st_price && parseInt(products?.st_price) !== 0 && products?.st_price < products?.total_price) {
            return parseFloat(products?.st_price);
        }
        return parseFloat(products?.total_price);
    }, [products, product_type]);

    const seQuatintyAdd = (item) => {
        setQuantity(item === "sub" ? (quantity > 1 ? quantity - 1 : quantity) : quantity + 1);
    };

    function handleAddOns({ addOns, product_id = null, errors, isError, isDeal, product_name = "" }, idx = null) {
        const selected = product_id && isDeal ? multipleSelectedAddOns[`${product_id}_${idx}`] : selectedAddOns;
        return addOns?.map((element) => {
            let currentError = {};
            if (element?.is_required === "Y") {
                let errorMessage = product_id ? `${product_id}_addon_id_${element?.add_on_id}` : "";
                let dealErrorProductMessage = product_id ? `${product_id}_addon_id_${element?.add_on_id}` : "";
                let existedIndex = -1;
                if (isDeal) {
                    if (multipleSelectedAddOns[`${product_id}_${idx}`]?.length) {
                        existedIndex = multipleSelectedAddOns[`${product_id}_${idx}`]?.findIndex((obj) => {
                            return parseInt(obj.add_on_id) === parseInt(element?.add_on_id);
                        });
                    }
                    errorMessage = `${errorMessage}_${idx}`;
                } else {
                    existedIndex = selected?.findIndex((obj) => {
                        return parseInt(obj.add_on_id) === parseInt(element?.add_on_id);
                    });
                }
                if (existedIndex === -1) {
                    currentError["error_message_" + errorMessage] = element?.heading + " add on is required";
                    if (product_name && isDeal) {
                        currentError["error_message_" + dealErrorProductMessage] =
                            "Please select " + product_name + " add on";
                    }
                    isError = true;
                } else {
                    currentError["error_message_" + errorMessage] = "";
                    if (product_name && isDeal) currentError["error_message_" + dealErrorProductMessage] = "";
                }
            }

            if (element.type === "checkbox") {
                let existedAddons = [];
                let errorMessage = product_id ? `${product_id}_addon_id_${element?.add_on_id}` : "";
                if (isDeal) {
                    if (multipleSelectedAddOns[`${product_id}_${idx}`]?.length) {
                        existedAddons = multipleSelectedAddOns[`${product_id}_${idx}`]?.filter((obj) => {
                            return obj.add_on_id === element?.add_on_id;
                        });
                    }
                    errorMessage = `${errorMessage}_${idx}`;
                } else {
                    existedAddons = selected?.filter((obj) => {
                        return obj.add_on_id === element?.add_on_id;
                    });
                }
                if (existedAddons?.length) {
                    if (existedAddons?.length < parseInt(element?.min_option) && element?.is_required === "Y") {
                        currentError["error_message_" + errorMessage] =
                            "Please select minimum " + element?.min_option + " " + element?.heading + " add on";
                        isError = true;
                    } else if (existedAddons?.length > parseInt(element?.max_option)) {
                        currentError["error_message_" + errorMessage] =
                            "Please select maximum " + element?.max_option + " " + element?.heading + " add on";
                        isError = true;
                    } else if (currentError["error_message_" + errorMessage]) {
                        currentError["error_message_" + errorMessage] = "";
                    }
                }
            }

            if (isError) setError((prev) => ({ ...prev, ...currentError }));
            element.isError = isError;
            return element;
        });
    }

    const toggleOpen = (idx) => () => setOpen(idx);

    const compareNumbers = (a, b) => a - b;

    const calculateShippingCharges = () => {
        let uniqueArray = [...new Set(cartData.map((item) => item.store_id))];
        let newArray = [];

        uniqueArray.forEach((item) => {
            let delivery_charges1 = 0;
            let totalSum = 0;
            let printingCost = 0;
            let findArray = cartData.filter((items) => items.store_id === item);

            findArray.forEach((data) => {
                if (window.$isStoreFront && data.texteditor) {
                    if (data.texteditor[0]?.text || data.texteditor[1]?.text) {
                        printingCost = printingCost + data.quantity * data.text_print_price;
                    }
                    if (data.texteditor[0]?.src || data.texteditor[0]?.src) {
                        printingCost = printingCost + data.quantity * data.image_print_price;
                    }
                }

                totalSum = totalSum + data.quantity * data.price;
            });
            if (findArray[0].delivery_charges) {
                delivery_charges1 = delivery_charges1 + parseInt(findArray[0].delivery_charges);
            }
            if (totalSum >= findArray[0]?.delivery_charges_data?.minimum_order) {
                delivery_charges1 = 0;
            }
            newArray.push({
                store_id: item,
                data: findArray,
                total_sum: totalSum,
                delivery_charges: delivery_charges1,
                printingCost: printingCost,
            });
        });
        // localStorage.setItem('final_cart',JSON.stringify(newArray))

        dispatch(updateFinalCartData(newArray));
        dispatch(unSetStates());
        if (currentLayout?.theme === "foodTheme" || !currentLayout) {
            dispatch(AddOnsModal({ close: true }));
        } else history.push("/shop");
    };

    function addToCart(product, prevCartData) {
        let isDeal = product_type === "deal";
        if (product && !isDeal) products = structuredClone(product);
        else products = structuredClone(products)
        let isError = false;
        setError({});
        delete products?.customer_reviews;
        delete products?.slider_image;
        delete products?.description;
        let errors = {};
        let data = null;
        if (isDeal) {
            data = product_deals?.map(({ addons, product_id, name }, idx) => {
                data = handleAddOns({ addOns: addons, product_id, errors, isDeal, isError, product_name: name }, idx);
                return { product_id, data };
            });
            isError = data?.some(({ data }) => data?.some(({ isError }) => isError));
        } else {
            data = handleAddOns({ addOns: products?.addons ?? [], errors, isDeal, product_id: products?.product_id });
            isError = data?.some(({ isError }) => isError);
        }

        if (isError) return;
        // {
        //     // dispatch(AddAddOns("currentAddOns", data, isDeal ? product.product_id : null));
        // }

        let cartarray = prevCartData ? prevCartData : typeof cartData != "undefined" && cartData.length > 0 ? cartData : [];
        if (!isDeal && products.varient_id === 0 && products.varients.length > 0) {
            if (products.varients.length > 1) {
                products.varient_id = products.varients[1].value[0].id;
                products.slected_varients = `${products.varients[0].name}:${products.varients[0].value[0].name},${products.varients[1].name}:${products.varients[1].value[0].name}`;
            } else {
                products.varient_id = products.varients[0].value[0].id;
                products.slected_varients = `${products.varients[0].name}:${products.varients[0].value[0].name}`;
            }
        }
        var addonString = isDeal ? {} : [];
        if (isDeal) {
            products.addons = product_deals.map(({ product_id }, idx) => {
                if (!multipleSelectedAddOns[`${product_id}_${idx}`]) return [];
                return multipleSelectedAddOns[`${product_id}_${idx}`]?.map((addon) => {
                    if (addon.add_on_id === "201" || addon.add_on_id === "203") {
                        dispatch(AddAddOns(addon, "radio", `${product_id}_${idx}`));
                    } else {
                        if (!addonString[`${product_id}_${idx}`]?.length)
                            addonString[`${product_id}_${idx}`] = [addon.add_on_id];
                        else addonString[`${product_id}_${idx}`].push(addon.add_on_id);
                    }
                    return { addon, product_id };
                });
            });
        } else if (selectedAddOns?.length > 0 && !isDeal) {
            products.addons = selectedAddOns;
            selectedAddOns.map((addon) => {
                if (addon.id === "201" || addon.id === "203") {
                    dispatch(AddAddOns(addon, "radio", null));
                } else {
                    addonString.push(addon.id);
                }
                return null;
            });
        } else {
            products.addons = [];
        }

        products.quantity = quantity;

        let oldadddonString = [];

        if (cartarray.length > 0) {
            // const findCartItemIndex = cartarray.findIndex((item) => item.product_id === products.product_id);
            // if (findCartItemIndex !== -1) {
            //     const findCartItem = cartarray.at(findCartItemIndex);
            //     let current = "";
            //     let old = "";
            //     console.log("🚀 ~ addToCart ~ findCartItem:", findCartItem);
            //     if (findCartItem && findCartItem?.addons?.length) {
            //         if (isDeal) {
            //             findCartItem?.addons?.forEach((addon) => {
            //                 addon?.forEach(({ addon }) => {
            //                     oldadddonString.push(addon.add_on_id);
            //                 });
            //             });
            //             current = Object.values(addonString)?.flat()?.sort(compareNumbers)?.join("");
            //             old = oldadddonString?.sort(compareNumbers)?.join("");
            //         } else {
            //             findCartItem?.addons?.forEach((addon) => {
            //                 oldadddonString.push(addon.id);
            //             });
            //             current = addonString.sort(compareNumbers).join("");
            //             old = oldadddonString.sort(compareNumbers).join("");
            //         }
            //         if (current === old && findCartItem.product_id === products.product_id) {
            //             cartarray.splice(findCartItemIndex, 1);
            //         }
            //         console.log(current, old, findCartItem.product_id, products.product_id);
            //     }
            // }
            // return;[].for
            cartarray.forEach((item, index) => {
                if (isDeal) {
                    if (item.product_id === products.product_id && item?.addons?.length) {
                        item?.addons?.forEach((addon) => {
                            addon?.forEach(({ addon }) => {
                                oldadddonString.push(addon.add_on_id);
                            });
                        });
                        const current = Object.values(addonString)?.flat()?.sort(compareNumbers)?.join("");
                        const old = oldadddonString?.sort(compareNumbers)?.join("");
                        if (current === old) cartarray.splice(index, 1);
                    }
                } else {
                    if (item?.addons?.length > 0) {
                        item?.addons?.forEach((addon) => {
                            oldadddonString.push(addon.id);
                        });
                    }

                    if (
                        item.product_id === products.product_id &&
                        oldadddonString.sort(compareNumbers).join("") === addonString.sort(compareNumbers).join("")
                    ) {
                        cartarray.splice(index, 1);
                    }
                }
            });
        }

        cartarray = [...cartarray, products];

        dispatch(updateCartItems(cartarray));
        toast.success("item added to cart", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            theme: "dark",
            icon: "🛒",
        });

        dispatch(makeAnalyticsFunnel("addToCart", JSON.stringify(cartarray), products.product_id));
        setTimeout(() => calculateShippingCharges(), 1000);
        handleTag({ product: products, storeFrontData });
    }

    function handleErrorMessage({ product_id, addon, event, idx, product_name, isDeal }) {
        const { isError, add_on_id, is_required, heading } = addon ?? {};
        const {
            target: { checked },
        } = event;
        if (isError || is_required === "Y") {
            let errorMessageKey = `error_message_${product_id}_addon_id_${add_on_id}`;
            const errorMessageKeyDeal = errorMessageKey;
            if (Number.isSafeInteger(idx)) errorMessageKey = `${errorMessageKey}_${idx}`;
            const errorMessage = `${heading} add on is required`;
            const errorMessageDeal = `Please select ${product_name} add on`;
            setError((prev) => {
                if (!isDeal) {
                    return {
                        ...prev,
                        [errorMessageKey]: checked ? "" : errorMessage,
                    };
                }
                return {
                    ...prev,
                    [errorMessageKey]: checked ? "" : errorMessage,
                    [errorMessageKeyDeal]: prev[errorMessageKey] && checked ? "" : errorMessageDeal,
                };
            });
        }
    }

    if (product_type !== "deal") {
        return (
            <>
                <AddonsAccordian {...{ addons, products, pPrice, reRenderParant, error, handleErrorMessage }} />
                {showStikcyActionBar && (
                    <StikcyActionBar
                        {...{
                            addOnsprice,
                            addToCart,
                            storeFrontData,
                            pprice: productPrice,
                            quantity,
                            seQuatintyAdd,
                            selectedAdOns: selectedAddOns,
                            product: products,
                            allAddons: products?.addons,
                            key,
                            type,
                        }}
                    />
                )}
            </>
        );
    }
    return (
        <>
            <Each
                {...{
                    items: product_deals,
                    renderItem: ProductAccordians,
                    reRenderParant,
                    error,
                    toggleOpen,
                    open,
                    handleErrorMessage,
                }}
            />
            {showStikcyActionBar && (
                <StikcyActionBar
                    {...{
                        selectedAdOns: selectedAddOns,
                        storeFrontData,
                        multipleSelectedAddOns,
                        isDeal: true,
                        addOnsprice,
                        pprice: productPrice,
                        quantity,
                        seQuatintyAdd,
                        product_deals,
                        addToCart,
                        allAddons: products?.addons,
                        key,
                        type,
                    }}
                />
            )}
        </>
    );
}

function ProductAccordians(data, idx, { error, reRenderParant, open, toggleOpen, handleErrorMessage }) {
    const { name, addons } = data;
    const ToggleIcon = open === idx ? FaMinusCircle : FaPlusCircle;
    const ids = addons.map((addon) => {
        return `error_message_${data?.product_id}_addon_id_${addon?.add_on_id}`;
    });
    const errorMsg = ids.map((id) => error[id]).filter((msg) => msg);
    return (
        <>
            <Accordion activeKey={open + 1}>
                <Accordion.Toggle
                    as={() => (
                        <div
                            role="button"
                            onClick={toggleOpen(idx)}
                            className="d-flex align-items-center justify-content-between pt-3"
                        >
                            <h4 className="font-20 font-weight-bold">{name}</h4>
                            <ConditionalRender condition={open !== idx && errorMsg}>
                                <p className="text-danger">{errorMsg?.at(0)}</p>
                            </ConditionalRender>
                            <Button variant="link" className="p-0 pluscartbtn font-26">
                                <ToggleIcon />
                            </Button>
                        </div>
                    )}
                    eventKey={idx + 1}
                ></Accordion.Toggle>
                <Accordion.Collapse eventKey={idx + 1}>
                    <AddonsAccordian
                        {...{
                            addons,
                            products: data,
                            isDeal: true,
                            reRenderParant,
                            error,
                            idx,
                            handleErrorMessage,
                        }}
                    />
                </Accordion.Collapse>
            </Accordion>
        </>
    );
}

export { AddOnsSection };

function handleTag({ product, storeFrontData }) {
    let cName = product?.bazar_type_name?.split(" & ").join("_");
    if (window.pixelArray[cName]) {
        ReactPixel.init(window.pixelArray[cName]);
        let tdata = {
            store_name: storeFrontData?.store?.data?.name ?? "",
            event: "add_to_cart",
            ecommerce: {
                currencyCode: "PKR",
                add: {
                    // 'add' actionFieldObject measures.
                    actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
                    products: [
                        {
                            //  adding a product to a shopping cart.
                            name: product.product_name, // Name or ID is required.
                            id: product.product_id,
                            price: "",
                            brand: storeFrontData?.store?.data?.name ?? "",
                            category: product.bazar_type_name,
                            sub_category: "", // If sub-category not available then skip this parameter
                            quantity: product.quantity,
                        },
                    ],
                },
            },
        };
        ReactPixel.track("PageView", tdata);
    }
    TagManager.dataLayer({
        dataLayer: {
            store_name: storeFrontData?.store?.data?.name ?? "",
            event: "add_to_cart",
            ecommerce: {
                currencyCode: "PKR",
                add: {
                    // 'add' actionFieldObject measures.
                    actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
                    products: [
                        {
                            //  adding a product to a shopping cart.
                            name: product.product_name, // Name or ID is required.
                            id: product.product_id,
                            price: "",
                            brand: storeFrontData?.store?.data?.name ?? "",
                            category: "",
                            sub_category: "", // If sub-category not available then skip this parameter
                            quantity: product.quantity,
                        },
                    ],
                },
            },
        },
    });
    ReactPixel.track("AddToCart", {
        store_name: storeFrontData?.store?.data?.name ?? "",
        event: "add_to_cart",
        ecommerce: {
            currencyCode: "PKR",
            add: {
                // 'add' actionFieldObject measures.
                actionField: { button: "add to cart" }, // 'add to cart' or 'buy it now'
                products: [
                    {
                        //  adding a product to a shopping cart.
                        name: product.product_name, // Name or ID is required.
                        id: product.product_id,
                        price: product.price,
                        brand: storeFrontData?.store?.data?.name ?? "",
                        category: !window.$isStoreFront ? product.bazar_category : product.category_id,
                        sub_category: !window.$isStoreFront ? product.sub_bazar_category : product.sub_category_id, // If sub-category not available then skip this parameter
                        quantity: product.quantity,
                    },
                ],
            },
        },
    });
}