import React, { useEffect, useState } from "react";
import favicon from "../../../../assets/i/favicon.png";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "react-helmet";
import defaultOG from "../../../../assets/i/dp_logo.svg";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { updateCartItems, updateFinalCartData } from "../../../../store/actions/mallActions";
import { makeAnalyticsFunnel } from "../../../../store/actions/CommonActions";

function MetaDataComponent(props) {
	const storeData = useSelector((state) => {
		return { ...state.mall.storeFrontData?.store?.data };
	});
	const storeFrontData = useSelector((state) => {
		return { ...state.mall.storeFrontData };
	});
	const reducerData = useSelector((state) => {
		return { ...state.mall };
	});
	const cartData = useSelector((state) => {
		return { ...state.mall.cartData };
	});

	const loadGTM = (gtmId) => {
		const script = document.createElement("script");
		script.async = true;
		script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
		document.head.appendChild(script);

		const noscript = document.createElement("noscript");
		const iframe = document.createElement("iframe");
		iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
		iframe.height = 0;
		iframe.width = 0;
		iframe.style.display = "none";
		iframe.style.visibility = "hidden";
		noscript.appendChild(iframe);
		document.body.appendChild(noscript);
	};
	const metasJSON = {
		"home": {
			mtitle: "Boost Career with Top IT Courses: Data Science, Cyber Security & More",
			mdescription:
				"Gain in-demand skills and advance your career with our online IT courses in data science, cyber security, web development, and soft skills. Enroll now!",
		},
		"/": {
			mtitle: "Boost Career with Top IT Courses: Data Science, Cyber Security & More",
			mdescription:
				"Gain in-demand skills and advance your career with our online IT courses in data science, cyber security, web development, and soft skills. Enroll now!",
		},
		"home2": {
			mtitle: "IT Courses: Data Science, Cyber Security, Web Development & More",
			mdescription:
				"Enhance your job prospects with our IT courses covering data science, cyber security, web development, soft skills and More. Get the skills you need to excel in the IT industry.",
		},
		"getting-started": {
			mtitle: "Getting Started: Discover IT Courses Overview, Details, Fee Structure",
			mdescription:
				"Explore IT courses details and fee structure with Learn Now, Pay Later option. Start your IT journey today with our flexible payment options. Sign up now!",
		},
		"faq": {
			mtitle: "",
			mdescription: "",
		},
		"Privacy-Policy": {
			mtitle: "",
			mdescription: "",
		},
		"executive-team": {
			mtitle: "",
			mdescription: "",
		},
		"knowledge-partner": {
			mtitle: "Knowledge Partners | Transform Your Career with Comprehensive IT Courses",
			mdescription:
				"Transform your career with comprehensive IT courses, data science, and cyber security training from our expert Knowledge Partners to stand out in the fast-paced digital world.",
		},
		"university": {
			mtitle: "University Alliances | Boost Your Career with Comprehensive IT Courses",
			mdescription:
				"Learn from top universities and gain valuable skills to excel in the ever-evolving field of technology. Explore our courses now and unlock your potential in the world of IT.",
		},
		"knowledge-testimonials": {
			mtitle: "Testimonials | Transform Your Career with IT Courses",
			mdescription:
				"Discover how comprehensive IT courses can elevate your career with real Testimonials from satisfied students. Start your journey to success today!",
		},
		"programmes/Tech-Launchpad/7": {
			mtitle: "Tech Launchpad: Learn Data Analysis, Storytelling & Programming Basics",
			mdescription:
				"Transform your career with our Tech Launchpad course, covering data analysis, storytelling, and programming fundamentals. Join today and boost your skillset for success.",
		},
		"programmes/Data-Sciences/3": {
			mtitle: "Data Science Course | Boost Your Career with Comprehensive IT Courses",
			mdescription:
				"Explore advanced data science concepts with practical machine learning applications. Learn key methodologies and tools for mastering data science and boosting your career.",
		},
		"programmes/Cyber-Security/5": {
			mtitle: "Cyber Security Course for Web Application Security | Learn Online",
			mdescription:
				"Discover the best cyber security course for understanding web application security. Enhance your skills with expert-led online training to protect websites and data.",
		},
		"programmes/Web-Development/1": {
			mtitle: "Best Web Development Course: Transform Your Career Today",
			mdescription:
				"Discover the essentials of web development with our comprehensive Web Development Course. Start your journey today and master key concepts and skills.",
		},
		"sap": {
			url: "sap",
			mtitle: "Enroll in SAP Course | Start Your Successful Career Journey Today",
			mdescription:
				"Discover the high-potential SAP Program designed to propel your career. Take the next step towards success by enrolling now and unlocking new opportunities!",
		},
		"cdp": {
			mtitle: "Chip Design Program | Unlock Your Potential in Chip Design",
			mdescription:
				"Join Our Chip Design Professionals Development Program to enhance your skills. Accelerate your career with hands-on training and expert guidance.",
		},
		"contact-us": {
			mtitle: "Contact Us | Knowledge.tech",
			mdescription: "",
		},
		"FOR-COMPANIES": {
			mtitle: "Simplify Your Hiring Process | Improve Your Talent Acquisition",
			mdescription:
				"Streamline your hiring process and build an exceptional team of diverse IT professionals with knowledge streams. Find skilled candidates easily for your company.",
		},
		"applynow": {
			mtitle: "Boost Your Career with Comprehensive IT Courses | Apply Now",
			mdescription:
				"Unlock new career opportunities with our comprehensive IT courses. Apply now to enhance your skills and accelerate your professional growth.",
		},
		"soft-skill": {
			mtitle: "Boost Your Career: Mastering Essential Soft Skills for Success",
			mdescription:
				"Develop crucial soft skills with our comprehensive Course, designed to help you excel in any job. Explore effective communication, time management, teamwork, and more to enhance your employability and advance your career.",
		},
	};
	const isProductPage = window.location.href.indexOf("products") > -1 ? true : false;
	const [metaTitle, setMetaTitle] = useState(
		isProductPage && reducerData.products?.name
			? reducerData.products?.name + " - " + storeData.name
			: window.$isStoreFront && storeFrontData?.config[0]?.meta_title !== ""
			? storeFrontData?.config[0]?.meta_title
			: storeData.name
	);
	const [metaDescription, setMetaDescription] = useState(
		window.$isStoreFront && storeFrontData?.config[0]?.meta_description !== ""
			? storeFrontData?.config[0]?.meta_description
			: "Buy/Order from " +
					storeData.name +
					" at discounted rates. Dukan.pk empowering small to medium businesses to sell online with integrated payment methods & delivery."
	);
	const getMetaData = (url) => {
		let urlPath = new URL(url).pathname.replace(/^\//, "");
		if(urlPath === '') urlPath = '/';
		const meta = metasJSON[urlPath] || {};
		return {
			title: meta.mtitle || "",
			description: meta.mdescription || "",
		};
	};
	const loadAnalyticsScripts = (gaid) => {
		// Load Universal Analytics
		const loadUAScript = () => {
			return new Promise((resolve) => {
				const script = document.createElement("script");
				script.async = true;
				script.src = "https://www.google-analytics.com/analytics.js";
				script.onload = resolve;
				document.head.appendChild(script);
			});
		};

		// Load GA4
		const loadGA4Script = () => {
			return new Promise((resolve) => {
				const script = document.createElement("script");
				script.async = true;
				script.src = "https://www.googletagmanager.com/gtag/js?id=" + gaid; // Replace with your GA4 ID
				script.onload = resolve;
				document.head.appendChild(script);
			});
		};

		return Promise.all([loadUAScript(), loadGA4Script()]);
	};

	const initializeAnalytics = (analyTicsId) => {
		// Initialize Universal Analytics

		window.ga("create", analyTicsId, "auto"); // Replace with your UA ID
		window.ga("send", "pageview");

		// Initialize GA4
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			window.dataLayer.push(arguments);
		}
		gtag("js", new Date());
		gtag("config", analyTicsId); // Replace with your GA4 ID
	};

	// const headerContent = useSelector(state => state.theme.header_content);
	let actualfavicon = window.$isStoreFront ? storeFrontData?.config[0]?.store_favicon : "";
	let fIcon = actualfavicon ? actualfavicon : storeData.store_logo ? storeData.store_logo : favicon;
	let bazarmetaDesc =
		"Dukan.pk is home to hundreds of thousands of products in over 40 categories, enabling economic opportunity for individuals, entrepreneurs, businesses, and organizations of all sizes.";

	const dispatch = useDispatch();
	const currentLayout = useSelector((state) => state.theme.current_layout);
	const generateUUID = () => {
		// Public Domain/MIT
		let d = new Date().getTime(); //Timestamp
		let d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
			let r = Math.random() * 16; //random number between 0 and 16
			if (d > 0) {
				//Use timestamp until depleted
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {
				//Use microseconds since page-load if supported
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
		});
	};
	useEffect(() => {
		if (!window.$isStoreFront) {
			ReactPixel.init("4715320778561949");
			ReactPixel.pageView();

			ReactGA.initialize("UA-174647269-1");
			ReactGA.pageview(window.location.href);
			TagManager.dataLayer({
				dataLayer: {
					event: "pageview",
					pagePath: window.location.href,
					pageTitle: `Dukan - ${metaTitle}`,
				},
			});
			if (window.$orderConfirmedId) {
				//let finalCartItems =
				let lastProd = cartData[0];
				let cName = lastProd.bazar_type_name.split(" & ").join("_");
				//For Dukan Bazar
				if (window.pixelArray[cName]) {
					let trackingObj = {
						store_name: props.storeFrontData?.store?.data?.name ?? "Dukan",
						event: "Purchase",
						name: lastProd.product_name, // Name or ID is required.
						content_ids: [lastProd.product_id],
						value: lastProd.price,
						currency: "PKR",
					};
					ReactPixel.init(window.pixelArray[cName]);
					ReactPixel.track("Purchase", trackingObj);
				}
				//For Storefront
				ReactPixel.init("321561126063458");
				dispatch(updateFinalCartData([]));
				dispatch(updateCartItems([]));
				dispatch(makeAnalyticsFunnel("purchase", reducerData?.finalCartData[0], window.$orderConfirmedId));
				// window.localStorage.setItem('tempSession', generateUUID())
				ReactPixel.track("Purchase", {
					store_name: "Dukan",
					event: "confirmed_payment",
					value: reducerData.finalCartData[0]?.total_sum,
					currency: "PKR",
					ecommerce: {
						currencyCode: "PKR",
						checkout: {
							actionField: { step: 1 },
							purchase: {
								actionField: {
									id: window.$orderConfirmedId,
									affiliation: "Dukan",
									revenue: reducerData.finalCartData[0]?.total_sum,
								},
								products: reducerData.finalCartData,
							},
						},
					},
				});
				TagManager.dataLayer({
					dataLayer: {
						store_name: "Dukan",
						event: "payment_complete",
						ecommerce: {
							currencyCode: "PKR",
							add: {
								// 'add' actionFieldObject measures.
								actionField: { button: "payment Complete" }, // 'add to cart' or 'buy it now'
							},
						},
					},
				});
				window.$orderConfirmedId = "";
			}
		} else {
			if (window.$storeId === "783511") {
				ReactPixel.init("1391842954889319");
				ReactPixel.pageView();

				ReactGA.initialize("UA-174647269-1");
				ReactGA.pageview(window.location.href);
				TagManager.initialize({
					gtmId: "GTM-K8P47TL",
				});
				TagManager.dataLayer({
					dataLayer: {
						event: "pageview",
						pagePath: window.location.href,
						pageTitle: `SetosaSkin - ${metaTitle}`,
					},
				});
				ReactGA.initialize("G-X1DMT26HBZ");
				ReactGA.pageview(window.location.href);
			} else if (window.$storeId === "841705") {
				TagManager.initialize({
					gtmId: "G-19BSFG4RHZ",
				});
				TagManager.dataLayer({
					dataLayer: {
						event: "pageview",
						pagePath: window.location.href,
						pageTitle: metaTitle,
					},
				});

				TagManager.initialize({
					gtmId: "AW-11141529227",
				});
				TagManager.dataLayer({
					dataLayer: {
						event: "pageview",
						pagePath: window.location.href,
						pageTitle: metaTitle,
					},
				});

				// Here we will go for the meta Title and Meta Description for KS
				let mets = getMetaData(window.location.href);
				if (mets.title !== "") {
					setMetaTitle(mets.title);
				}
				if (mets.description !== "") {
					setMetaDescription(mets.description);
				}
			}
			ReactPixel.init("321561126063458");
			ReactPixel.pageView();
			if (storeData.facebook_pixel) {
				ReactPixel.init(storeData.facebook_pixel);
				ReactPixel.pageView();
			}
			if (storeData.google_pixel) {
				ReactGA.initialize(storeData.google_pixel);
				ReactGA.pageview(window.location.href);
				loadGTM(storeData.google_pixel);
				loadAnalyticsScripts().then(() => {
					initializeAnalytics(storeData.google_pixel);
				});
				TagManager.initialize({
					gtmId: storeData.google_pixel,
				});
				TagManager.dataLayer({
					dataLayer: {
						event: "pageview",
						pagePath: window.location.href,
						pageTitle: "Home page",
					},
				});
			}
			// ReactPixel.pageView()
			if (window.$orderConfirmedId) {
				dispatch(updateFinalCartData([]));
				dispatch(updateCartItems([]));
				dispatch(makeAnalyticsFunnel("purchase", reducerData?.finalCartData[0], window.$orderConfirmedId));
				ReactPixel.track("Purchase", {
					store_name: storeData.name ?? "",
					event: "confirmed_payment",
					value: reducerData.finalCartData[0]?.total_sum,
					currency: "PKR",
					ecommerce: {
						currencyCode: "PKR",
						checkout: {
							actionField: { step: 1 },
							purchase: {
								actionField: {
									id: window.$orderConfirmedId,
									affiliation: storeData?.name ?? "",
									revenue: reducerData.finalCartData[0]?.total_sum,
								},
								products: reducerData.finalCartData,
							},
						},
					},
				});
				TagManager.dataLayer({
					dataLayer: {
						store_name: storeData.name ?? "Dukan",
						event: "purchase",
						ecommerce: {
							currencyCode: "PKR",
							add: {
								// 'add' actionFieldObject measures.
								actionField: { button: "purchase" }, // 'add to cart' or 'buy it now'
								products: [
									{
										//  adding a product to a shopping cart.
										id: window.$orderConfirmedId,
										value: reducerData.finalCartData[0]?.total_sum,
										currency: "PKR",
									},
								],
							},
						},
					},
				});

				// GA
				if (storeData.google_pixel) {
					ReactGA.initialize(storeData.google_pixel);
					ReactGA.event({
						category: "purchase",
						action: "purchase",
						store_name: storeData.name ?? "Dukan",
						event: "purchase",
						label: "purchase",
						ecommerce: {
							currencyCode: "PKR",
							add: {
								// 'add' actionFieldObject measures.
								actionField: { button: "purchase" }, // 'add to cart' or 'buy it now'
								products: [
									{
										//  adding a product to a shopping cart.
										id: window.$orderConfirmedId,
										value: reducerData.finalCartData[0]?.total_sum,
										currency: "PKR",
									},
								],
							},
						},
					});
				}

				window.$orderConfirmedId = "";
			}
		}
		// Dukan All website Google Pixel
		loadGTM("G-P5T1NXKYFT");
		loadGTM("GT-WF4ZF6G");
		loadAnalyticsScripts().then(() => {
			initializeAnalytics("G-P5T1NXKYFT");
			initializeAnalytics("GT-WF4ZF6G");
		});
		// End of Dukan All website Google Pixel
	}, []);
	return (
		<>
			{window.$isStoreFront ? (
				<Helmet>
					<title>{metaTitle}</title>
					<link rel="icon" type="image/png" sizes="32x32" href={fIcon} />
					<link rel="icon" type="image/png" sizes="16x16" href={fIcon} />
					<link rel="icon" type="image/png" href={fIcon} />
					<meta property="og:type" content="website" />
					<meta property="og:site_name" content={window.location.href} />
					<meta
						property="og:title"
						content={
							isProductPage && reducerData.products?.name
								? reducerData.products?.name + " - " + storeData.name
								: metaTitle
						}
					/>
					<meta property="og:url" content={window.location.href} />
					<meta property="og:description" content={metaDescription} />
					<meta name="description" content={metaDescription} />
					<meta
						property="og:image"
						content={
							isProductPage && reducerData.products?.product_image
								? reducerData.products?.product_image
								: storeData.store_logo ?? defaultOG
						}
					/>
				</Helmet>
			) : (
				<Helmet>
					<title>
						Dukan {isProductPage && reducerData.products?.name ? "- " + reducerData.products?.name : ""}
					</title>
					<link rel="icon" type="image/png" sizes="32x32" href={fIcon} />
					<link rel="icon" type="image/png" sizes="16x16" href={fIcon} />
					<link rel="icon" type="image/png" href={favicon} />
					<meta property="og:type" content="website" />
					<meta property="og:site_name" content="Dukan.pk" />
					<meta
						property="og:title"
						content={
							isProductPage && reducerData.products?.name ? reducerData.products?.name : storeData.name
						}
					/>
					<meta
						property="og:image"
						content={
							isProductPage && reducerData.products?.product_image
								? reducerData.products?.product_image
								: "https://assets.dukan.pk/assets/wnassets/images/logo.svg" ?? defaultOG
						}
					/>
					{/* <meta property="og:title" content="Dukan" /> */}
					<meta property="og:url" content={window.location.href} />
					<meta property="og:description" content={bazarmetaDesc} />
					<meta name="description" content={bazarmetaDesc} />
					{window.$isStoreFront && currentLayout?.name == "ThemeLayout" ? (
						<link
							rel="stylesheet"
							type="text/css"
							href={process.env.PUBLIC_URL + "/" + currentLayout?.css}
						/>
					) : (
						""
					)}
				</Helmet>
			)}
		</>
	);
}

export default MetaDataComponent;
