import {
    GET_HOME_PAGE_REQUEST,
    GET_HOME_PAGE_SUCCESS,
    GET_HOME_PAGE_FAILURE,

    GET_GETTING_STARTED_REQUEST,
    GET_GETTING_STARTED_SUCCESS,
    GET_GETTING_STARTED_FAILURE,

    GET_EXECUTIVE_TEAM_REQUEST,
    GET_EXECUTIVE_TEAM_SUCCESS,
    GET_EXECUTIVE_TEAM_FAILURE,

    GET_FAQ_REQUEST,
    GET_FAQ_SUCCESS,
    GET_FAQ_FAILURE,

    GET_TESTIMONIALS_REQUEST,
    GET_TESTIMONIALS_SUCCESS,
    GET_TESTIMONIALS_FAILURE,
} from "../actions/KsActions"

const initialState = {
    loading: false,
    error: false,
    message: null,
    data: null
};

const handleSwitch = (state, { type, handler, payload }) => {
    switch (type) {
        case handler[0]:
            return {
                ...state,
                loading: true,
                error: false,
                message: null,
            };
        case handler[1]:
            return {
                ...state,
                loading: false,
                error: false,
                data: payload,
                message: null
            };
        case handler[2]:
            return {
                ...state,
                error: true,
                loading: false,
                message: payload || "Something went wrong"
            };
        default:
            return state;
    }
}

const ksHomePageReducer = (state = initialState, { type, payload }) => {
    return handleSwitch(state, {
        type, payload, handler: [
            GET_HOME_PAGE_REQUEST,
            GET_HOME_PAGE_SUCCESS,
            GET_HOME_PAGE_FAILURE
        ]
    });
};

const ksGettingStartedReducer = (state = initialState, { type, payload }) => {
    return handleSwitch(state, {
        type, payload, handler: [
            GET_GETTING_STARTED_REQUEST,
            GET_GETTING_STARTED_SUCCESS,
            GET_GETTING_STARTED_FAILURE
        ]
    });
};

const ksExecutiveTeamReducer = (state = initialState, { type, payload }) => {
    return handleSwitch(state, {
        type, payload, handler: [
            GET_EXECUTIVE_TEAM_REQUEST,
            GET_EXECUTIVE_TEAM_SUCCESS,
            GET_EXECUTIVE_TEAM_FAILURE
        ]
    });
};

const ksFaqReducer = (state = initialState, { type, payload }) => {
    return handleSwitch(state, {
        type, payload, handler: [
            GET_FAQ_REQUEST,
            GET_FAQ_SUCCESS,
            GET_FAQ_FAILURE,
        ]
    });
}

const kstestimonialsReducer = (state = initialState, { type, payload }) => {
    return handleSwitch(state, {
        type, payload, handler: [
            GET_TESTIMONIALS_REQUEST,
            GET_TESTIMONIALS_SUCCESS,
            GET_TESTIMONIALS_FAILURE
        ]
    });
}

export { ksHomePageReducer, ksGettingStartedReducer, ksExecutiveTeamReducer, kstestimonialsReducer, ksFaqReducer };
