export let AssetsUrl ='';
export let BASE_URL ='';
export let IS_EXTERNAL ='N';
export let retail_id ='';
export let applyTaxPlugin ='3';
export let bookAppointmentPlugin ='5';
export let productAddonsPlugin ='13';
export let storeBranchesPlugin ='21';
export let customPrintingPlugin ='23';
export let whatsAppChatPlugin ='31';
export let onlinePaymentsPlugin ='35';
export let analyticsFunnel ='30500';