import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios_purchase from "../../../axios_purchase";
import { updateUserLoggedInData } from "../../../store/actions/mallActions";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import PENICON from "../../../assets/i/editpen.svg";
import { toast } from "react-toastify";

function ForgetModal(props) {
	const [showError, setshowError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setsuccessMsg] = useState("");
	const [phoneError, setphoneError] = useState("");
	const [cellNo, setCellNo] = useState("");
	const [otpSent, setOtpSent] = useState(false);
	const [otp, setOtp] = useState(false);
	const [showresetPassword, setshowresetPassword] = useState(false);
	const [newpassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const storeData = useSelector((state) => {
		return { ...state.mall.storeFrontData };
	});

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			resetPassword();
		}
	};

	const resetPassword = () => {
		if (validateForm()) {
			let queryString = require("querystring");
			let loginData = {
				[storeData?.config[0]?.customer_identifier]: cellNo,
				isPasswordEnabled:
					window.$isStoreFront && storeData?.config[0]?.checkout_without_login
						? storeData?.config[0]?.checkout_without_login
						: "N",
				store_id: window.$storeId,
			};
			axios_purchase
				.post(window.$storeUrl + window.$apiRoot + "/customer/userPwdReset", queryString.stringify(loginData))
				.then((res) => {
					setshowError(true);
					setErrorMsg("");
					setsuccessMsg("");
					if (!res.data.status) {
						setErrorMsg(res.data.message);
					} else {
						setOtpSent(true);
						setsuccessMsg(res.data.message);
					}
					setTimeout(() => {
						setshowError(false);
					}, 4000);
				})
				.catch();
		}
	};
	const validateForm = () => {
		let phoneError = "";

		if (cellNo.length === 0) {
			storeData?.config[0]?.customer_identifier === "mobile"
				? (phoneError = "Mobile Number is Required")
				: (phoneError = "Email is Required");
			setphoneError(phoneError);
		}

		if (phoneError) {
			return false;
		}

		return true;
	};

	const handleOtpChange = (otp) => {
		setOtp(otp);
		if (otp.length > 3) {
			otpHandler(otp);
		}
	};

	const otpHandler = (otp) => {
		const postData = {
			[storeData?.config[0]?.customer_identifier]: cellNo,
			code: otp,
			isPasswordEnabled: storeData?.config[0]?.checkout_without_login,
			store_id: window.$storeId,
			requestFrom: "resetPassword",
		};

		axios_purchase
			.post(window.$storeUrl + window.$apiRoot + "/customer/verifyLoginOTP", postData)
			.then((res) => {
				if (res.data.success === "Y") {
					let successData = res.data.response.data;
					setsuccessMsg("OTP Verified. Please enter new password");
					setshowresetPassword(true);
				} else {
					setshowError(true);
					setErrorMsg("Invalid OtpInput.");
					setTimeout(() => {
						setshowError(false);
					}, 4000);
				}
			})
			.catch((err) => {});
	};

	const validateAndSubmitNewPassword = () => {
		const postData = {
			[storeData?.config[0]?.customer_identifier]: cellNo,
			code: otp,
			newPassword: newpassword,
			isPasswordEnabled: storeData?.config[0]?.checkout_without_login,
			store_id: window.$storeId,
		};
		if (newpassword === confirmNewPassword) {
			axios_purchase
				.post(window.$storeUrl + window.$apiRoot + "/customer/resetPassword", postData)
				.then((res) => {
					if (res.data.code === "11") {
						toast.success("Password updated successfully.", {
							position: toast.POSITION.BOTTOM_RIGHT,
							hideProgressBar: true,
							theme: "dark",
						});
						props.hideModal("forget");
						setNewPassword("");
						setConfirmNewPassword("");
						setshowresetPassword(false);
						setOtpSent(false);
						props.showModal("");
					} else {
						setErrorMsg("Invalid OtpInput.");
					}
				})
				.catch((err) => {});
		} else {
			setshowError(true);
			setErrorMsg("Password and confirm password not match.");
			setTimeout(() => {
				setshowError(false);
			}, 4000);
		}
	};

	return (
		<>
			<Modal
				className="favouriteModal loginModal"
				show={props.showForget}
				onHide={() => {
					props.hideModal("forget");
				}}
			>
				<Modal.Header className="p-0 border-0" closeButton>
					<Modal.Title className="font-18 font-weight-bold">
						{window.$isStoreFront &&
						storeData?.config[0]?.checkout_without_login === "Y" &&
						otpSent &&
						!showresetPassword
							? "Verify OTP"
							: window.$isStoreFront &&
							  storeData?.config[0]?.checkout_without_login === "Y" &&
							  otpSent &&
							  showresetPassword
							? "Reset Password"
							: "Forgot Password"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-0">
					<Alert show={showError} variant={errorMsg ? "danger" : "success"}>
						{errorMsg ? errorMsg : successMsg}
					</Alert>

					{window.$isStoreFront &&
					storeData?.config[0]?.checkout_without_login === "Y" &&
					otpSent &&
					!showresetPassword ? (
						<div className="otp-form">
							<div className=" form-group">
								<div className="verifaid-box d-flex justify-content-between align-items-center w-100">
									<span className="font14 reg_mobile">{cellNo}</span>
									<button
										type="button"
										onClick={() => setOtpSent(false)}
										className="p-0 btn btn-link"
									>
										<img src={PENICON} className="" />
									</button>
								</div>
							</div>
							<p className="font-14  mb-2 ">Enter 4 digit OTP sent on your number. </p>

							<OtpInput
								value={otp}
								onChange={handleOtpChange}
								numInputs={4}
								isInputSecure={true}
								className="otpinput"
								containerStyle="otpcontainer"
								shouldAutoFocus={true}
							/>
						</div>
					) : window.$isStoreFront &&
					  storeData?.config[0]?.checkout_without_login === "Y" &&
					  otpSent &&
					  showresetPassword ? (
						<>
							<>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label className="font-14 mb-1">New Password</Form.Label>
									<Form.Control
										type="password"
										className="h-44"
										onChange={(e) => {
											setNewPassword(e.target.value);
										}}
									/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label className="font-14 mb-1 d-flex justify-content-between align-items-center">
										Confirm New Password
									</Form.Label>
									<Form.Control
										type="password"
										className="h-44"
										onChange={(e) => {
											setConfirmNewPassword(e.target.value);
										}}
									/>
								</Form.Group>

								<Button
									onClick={() => {
										validateAndSubmitNewPassword();
									}}
									className={`${
										newpassword === "" || confirmNewPassword === "" ? "in-active" : ""
									} d-flex justify-content-center align-items-center w-100 my-btn btn border-0 mt-3 mb-0 create-btn h-44`}
								>
									Save
								</Button>
							</>
						</>
					) : (
						<>
							<Row>
								<Col md="12">
									<Form.Group>
										{window.$isStoreFront && storeData?.config[0]?.customer_identifier === "mobile" ? (
											<>
												<Form.Label className="font-14">Cell Number</Form.Label>
												<Form.Control
													className="font-14"
													type="tel"
													onKeyPress={handleKeyPress}
													value={cellNo}
													onChange={(e) => {
														setCellNo(e.target.value);
													}}
													required
												/>
											</>
										) : (
											<>
												<Form.Label className="font-14">Email</Form.Label>
												<Form.Control
													className="font-14"
													type="email"
													value={cellNo}
													onKeyPress={handleKeyPress}
													onChange={(e) => {
														setCellNo(e.target.value);
													}}
													required
												/>
											</>
										)}
										{phoneError.length > 0 && (
											<span className="text-danger font-12">{phoneError}</span>
										)}
									</Form.Group>
								</Col>
							</Row>

							<Button
								className="w-100 font-16 font-weight-bold text-white text-uppercase  create-btn d-flex align-items-center justify-content-center border-0"
								onClick={(e) => {
									resetPassword();
								}}
							>
								{window.$isStoreFront && storeData?.config[0]?.checkout_without_login === "Y"
									? "Send OTP"
									: "Reset Password"}
							</Button>
							<div className="member-box mb-0 mt-3 text-center font-weight-normal">
								Already a member?{" "}
								<a
									onClick={() => {
										props.showModal("");
									}}
								>
									Sign in
								</a>
							</div>
						</>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}

export default ForgetModal;
