import React from "react";
import KnowledgeHeader from "../knowledgeComponents/KnowledgeHeader";
import SmashBurgerFooter from "../knowledgeComponents/KsFooter";
import SmashBurgerMobFooter from "../knowledgeComponents/KsMobFooter";
import CDP from "../knowledgeComponents/CDP";
import { useSelector } from "react-redux";
import MetaDataComponent from "../../../storefront/Subcomponents/Home/MetaDataComponent";

const CDPpage = () => {
	const mallSelect = useSelector((state) => {
		return { ...state.mall };
	});
	return (
		<>
			<div className="body_springtide">
				{/* <KnowledgeHeader userData={mallSelect.userLoggedInData} /> */}
				<MetaDataComponent />
				<CDP />
				<SmashBurgerFooter />
				<SmashBurgerMobFooter />
			</div>
		</>
	);
};

export default CDPpage;
