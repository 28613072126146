import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function WebSkeletonHome() {
  return (
    <>
      <div className="webheaderSkeleton">
        <Skeleton />
      </div>

      <Row className="bannersliderSkeleton">
        <Col lg="4">
          <Skeleton />
        </Col>
        <Col lg="4" className="d-none d-md-block">
          <Skeleton />
        </Col>
        <Col lg="4" className="d-none d-md-block">
          <Skeleton />
        </Col>
      </Row>

      <Container>
        <div className="headingSkeleton d-flex justify-content-between">
          <Skeleton />
          <Skeleton />
        </div>
        <Row className="productSkeleton">
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
        </Row>
        <Row className="productSkeleton">
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
        </Row>
        <Row className="productSkeleton">
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="bannerinnerSkeleton">
          <Col>
            <Skeleton />
          </Col>
          <Col>
            <Skeleton />
          </Col>
        </Row>
      </Container>

      <Container>
        <div className="headingSkeleton d-flex justify-content-between">
          <Skeleton />
          <Skeleton />
        </div>
        <Row className="productSkeleton">
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
        </Row>
        <Row className="productSkeleton">
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
        </Row>
        <Row className="productSkeleton">
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-2 col-6">
            <Skeleton />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="valueSkeleton">
          <Col className="col-lg-3 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-3 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-3 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-3 col-6">
            <Skeleton />
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="valueSkeleton">
          <Col className="col-lg-4 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-4 col-6">
            <Skeleton />
          </Col>
          <Col className="col-lg-4 col-6">
            <Skeleton />
          </Col>
        </Row>
        <div className="headingSkeleton d-flex justify-content-between container">
          <Skeleton />
          <Skeleton />
        </div>
      </Container>
    </>
  );
}

export default WebSkeletonHome;
