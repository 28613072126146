

const initialState = {
    products: [],
    services: [],
    wishlistItems: [],
    categories: [],
    perPage: 10,
    priceRange: [0, 200000],
    flag: 'featured',
    category: '',
    start:0
}



const ShopReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_PRODUCTS':
            let newProducts = state.products;
            action.payload?.forEach(element => {
                const existedIndex = newProducts.findIndex(obj => {
                    return element.product_id === obj.product_id
                })
                if (existedIndex === -1) {
                    newProducts.push(element)
                }
            });

            return {
                ...state,
                products: newProducts

            };
        case 'SET_SERVICES':
                let newServices = state.services;
                action.payload?.forEach(element => {
                    const existedIndex = newServices.findIndex(obj => {
                        return element.id === obj.id
                    })
                    if (existedIndex === -1) {
                        newServices.push(element)
                    }
                });
    
                return {
                    ...state,
                    services: newServices
    
                };
        case 'SET_WISHLISTITEMS':
            return {
                ...state,
                wishlistItems: action.payload
            };
        case 'SET_PER_PAGE':
            return {
                ...state,
                perPage: action.payload

            };
        case 'SET_PRICE_RANGE':
            return {
                ...state,
                priceRange: action.payload

            };
        case 'SET_FLAG':
            return {
                ...state,
                flag: action.payload
            }
        case 'SET_CATEGORY':
            return {
                ...state,
                category: action.payload
            }
        case 'SET_START_PAGE':
            return {
                ...state,
                start: action.payload
            }
        case 'UN_SET_PRODUCTS':
            return {
                products: [],
                start:0
            }
        case 'UN_SET_SERVICES':
                return {
                    services: [],
                    start:0
                };

        default:
            return state;
    }
}

export default ShopReducer;
