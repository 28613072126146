import React, {Component} from "react";
import {Form, Row, Col} from "react-bootstrap";
import OtpForm from "./OtpForm";
import OtpCode from "./OtpCode";
import OtpInput from "react-otp-input";
import {updateCartItems, updateFinalCartData, updateUserLoggedInData} from "../../../store/actions/mallActions";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../axios_purchase";
import { toast } from "react-toastify";

class CheckoutLogin extends Component {
    constructor(props) {
        super(props)
        this.mobile = React.createRef();
    }

    state = {
        otpSent: false,
        mobile: '',
        isValidMobile: false,
        isValidOtp: false,
        otp: "",
        showSpinner: false,
        seconds: 59,
        resendText: 'Request Code Again',
        resendClass: '',
        showError: false,
        showLoginError: false,
        loginError: '',
        otpError: '',
        otpChannel: 'message'
    }
    componentDidMount() {
        setTimeout(()=>{
            this.mobile?.current?.focus();
        },500)
    }

    startSMSTimer = () => {
        var interval = setInterval(() => {
            if (this.state.seconds > 1) {
                this.setState({
                    resendText: 'Resend code in 00:' + ((this.state.seconds < 10) ? '0' : '') + (this.state.seconds),
                    resendClass: '',
                    seconds: this.state.seconds - 1
                });
            } else {
                clearInterval(interval);
                this.setState({
                    resendText: 'Resend Code',
                    resendClass: 'req-code',
                });
            }
        }, 1000);
    }
    handleOtpChange = otp => {
        this.setState({otp})
        if (otp.length > 3) {
            this.setState({isValidOtp: true})
            this.otpHandler(otp)
        }

    };
    handleMobileChange = (e) => {
        e.target.value = e.target.value.replace(/\D/g, '');
        if (e.target.value !== '' && e.target.value.length == 11) {
            this.setState(() => ({
                isValidMobile: true,
                mobile: e.target.value
            }), () => {
                // if(window.$storeId === '841705'){
                //     this.otpHandler('1234')
                // }else{
                    this.signInHandler()
                // }
            })
        } else {
            this.setState({
                isValidMobile: false
            })
        }
    }

    handleMobileKeyDown = (event) => {

        if (event.keyCode === 13) {
            // this.signInHandler()
        }
    }

    signInHandler() {


        const postData = {
            mobile: this.state.mobile,
            store_id: window.$storeId,
            guest_user_register:  this.props.guestLogin,
        }

        axios.post(window.$storeUrl + window.$apiRoot + '/customer/Otp', postData)

            .then(res => {
                if (res.data.response.otpSent === 'Y') {
                    toast.success(res.data.response.msg, {
                       position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                        theme: 'dark'
                      });

                    this.setState(() => ({
                        otpSent: true
                    }), () => {
                        this.startSMSTimer();

                    })

                } else if (res.data.response.otpSent === 'N') {
                    this.mobile.current.select();
                    toast.error(res.data.response.msg, {
                       position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                        theme: 'dark'
                      });
                    // this.setState({
                    //     showLoginError: true,
                    //     loginError: res.data.response.msg
                    // })
                    // setTimeout(() => {
                    //     this.setState({
                    //         showLoginError: false
                    //     })
                    // }, 3000);
                }else{
                    toast.error(res.data.response.msg, {
                       position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                        theme: 'dark'
                      });
                }


            })
            .catch(err => {
                toast.error('Please check your internet connection and try again', {
                   position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                    theme: 'dark'
                  });
                this.setState({
                    showSpinner: false
                })
            })


    }

    resendOtpHandler() {
        if (this.state.seconds <= 1) {

            this.setState({
                showSpinner: true
            })
            const postData = {
                mobile: this.state.mobile,
                send_from: this.state.otpChannel,
            }
           
            axios.post(window.$storeUrl + window.$apiRoot + '/customer/ResendOtp', postData)

                .then(res => {
                    this.setState({
                        showSpinner: false
                    })
                    if (res.data.response.code === '11') {
                        this.setState(() => ({
                            otpSent: true,
                            seconds: 59,
                        }), () => {
                            this.startSMSTimer()
                        })
                    }else{
                        toast.error(res.data.response.msg, {
                           position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                            theme: 'dark'
                          });
                    }
                })
                .catch(err => {
                    this.setState({
                        showSpinner: false
                    })
                })
            // this.setState({
            //     otpSent: true,
            //     showSpinner: false
            // })
            // this.startSMSTimer()
        }
    }

    otpHandler = (otp) => {

        this.setState({
            showSpinner: true
        })
        const postData = {
            mobile: this.state.mobile,
            store_id: window.$storeId,
            code: otp
        }

        axios.post(window.$storeUrl + window.$apiRoot + '/customer/verifyLoginOTP', postData)

            .then(res => {
                this.setState({
                    showSpinner: false
                })
                if (res.data.success === 'Y') {

                    let successData = res.data.response.data;

                    let userData = this.props.userLoggedInData;
                    userData.is_loggedIn = true;
                    userData.mobile_no = successData.customer_mobile;
                    userData.token = successData.token;
                    userData.user_id = successData.customer_id;
                    userData.user_name = successData.customer_name;
                    userData.email = successData.customer_email;
                    if(window.$storeId === '1791' || window.$storeId === '841705'){
                        userData.is_applied = successData?.is_applied;
                        userData.is_inquired = successData?.is_inquire;
                    }
                    let event = new CustomEvent('clospopup', {close: true})
                    
                   
                    localStorage.setItem('user_data', JSON.stringify(userData))
                    this.props.updateUserLoggedInData(userData);
                    if(window.$isWebView){
                        window.ReactNativeWebView.postMessage(JSON.stringify(userData))
                    }

                    this.setState({
                        showLoginError: true,
                        otp: "",
                        loginError: 'Not Authorized',
                        otpSent: false
                    })
                    setTimeout(() => {
                        this.setState({
                            showLoginError: false,
                        })
                        if (this.props.hideText) {
                            this.props.changeReloadModal('');
                        } else {
                            this.props.changeReload();
                        }

                    }, 500);
                    toast.success(res.data.response.message, {
                       position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                        theme: 'dark'
                      });

                } else {

                    toast.error(res.data.response.msg, {
                       position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                        theme: 'dark'
                      });

                    this.setState({
                        showLoginError: true,
                        otp: "",
                        loginError: 'Otp Expired or Invalid',
                        otpSent: true
                    })
                    setTimeout(() => {
                        this.setState({
                            showLoginError: false,
                        })
                    }, 3000);

                }
            })
            .catch(err => {
                toast.success('Something went wrong please check your internet connection and try again.', {
                   position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                    theme: 'dark'
                  });
                this.setState({
                    showLoginError: true,
                    loginError: 'Otp Expired or Invalid',
                    showSpinner: false,
                    otp: ""
                })
                setTimeout(() => {
                    this.setState({
                        showLoginError: false
                    })
                }, 3000);
            })


    }
    handleOtpChange = otp => {
        this.setState({otp})
        if (otp.length > 3) {
            this.setState({isValidOtp: true})
            this.otpHandler(otp)
        }

    };
    changeValue = (event) => {
        this.setState({
            otpChannel : event.target.value
        })
    }
    render() {
        return (
            <>
                <div className={`checkout-form  ${(this.props.hideText) ? 'p-0' : ''}`}>

                    {/* {
                        this.props.hideText ? '' :
                            <div className="checkout-head d-flex justify-content-between align-items-center"><h3
                                className="font-14 font-weight-bold"> {
                                this.props.title && this.props.title.length > 0 ? this.props.title : 'Contact Information'
                            } </h3></div>
                    } */}


                    <Form className="modal-form" onSubmit={(e) => e.preventDefault()}>
                        <Row className="hidespace">

                            <Col md="12">
                                <Form.Group id="checkoutlogin">
                                <Form.Label className="font-14">Mobile Number</Form.Label>
                                    <input  className="font-14 form-control"
                                                  type="tel" required
                                                  maxLength="11"
                                                  onChange={(e) => this.handleMobileChange(e)}
                                                  ref={this.mobile}
                                    />
                                   
                                </Form.Group>
                            </Col>

                        </Row>
                    </Form>
                    <div className="login-form  mx-auto w-100">

                        {this.state.otpSent ?
                            <div className="otp-form">
                                <p className="font-14  mb-2 ">
                                    Enter 4 digit OTP sent on your number.

                                </p>

                                <OtpInput
                                    value={this.state.otp}
                                    onChange={this.handleOtpChange}
                                    numInputs={4}
                                    isInputSecure={true}
                                    className="otpinput"
                                    containerStyle="otpcontainer"
                                    shouldAutoFocus={true}
                                    isInputNum={true}
                                    type='password'
                                />
                                {
                            
                            this.state.resendClass != '' ?
                                <div className="label-address my-2">
                                
                                <p className="font-14">Not receiving code? Get code via: </p>
                                <div className="radioBtn d-flex justify-content-md-start justify-content-center mb-0">
                                    <label className="radioBtn-container position-relative">
                                    <span className="labeltext position-relative">
                                        Sms
                                    </span>
                                    <input
                                        type="radio"
                                        defaultChecked={true}
                                        value="message"
                                        name="radio"
                                        onChange={this.changeValue}
                                    />
                                    <span className="checkmark"></span>
                                    </label>
                                    <label className="radioBtn-container position-relative">
                                    <span className="labeltext position-relative">
                                        Call
                                    </span>
                                    <input
                                        type="radio"
                                        defaultChecked={false}
                                        value="call"
                                        onChange={this.changeValue}
                                        name="radio"
                                    />
                                    <span className="checkmark"></span>
                                    </label>
                                </div>  
                                  </div> : ''
                        }
                                <p className={`font-14  my-2 ${this.state.resendClass}`} style={{cursor: 'pointer'}} onClick={() => {
                                    this.resendOtpHandler()
                                }}>{this.state.resendText}</p>

                            </div> : ""}


                    </div>
                </div>

            </>
        );
    }

}

const mapStateToProps = (state) => {
    return {
...
    state
.
    mall
}
}
const mapDispatchToProps = (dispatch) =>
{
    return {
        updateUserLoggedInData: (data) => dispatch(updateUserLoggedInData(data))
    }
}


export default compose(
withRouter, connect(mapStateToProps, mapDispatchToProps))(withErrorHandler(CheckoutLogin, axios));