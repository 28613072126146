import axios from 'axios';



let url = window.location.href.split('/mall');

var storeUrl = url[0];


let instance = axios.create({
    baseURL: storeUrl,
    headers:{
    },

})
instance.interceptors.request.use((config)=>{
    config.headers.token= window.$token
    return config
})
export default instance;