import axios from "../../axios_purchase";
import { toast } from "react-toastify";

export const SetCurrentTheme = (payload) => {
    return { type: 'CURRENT_THEME', payload }
}

export const SetCurrentTestimonials = (payload) => {
    return { type: 'CURRENT_TESTIMONIALS', payload }
}

export const SetCurrentFAQs = (payload) => {
    return { type: 'CURRENT_FAQS', payload }
}

export const UNSetCurrentTheme = () => {
    return { type: 'UN_CURRENT_THEME' }
}

export const toggleLoader = (payload) => {
    return { type: 'TOGGLE_LOADER', payload }
}

export const SetHeaderContent = (payload) => {
    return { type: 'HEADER_CONTENT', payload }
}

export const setCurrentMenu = (payload) => {
    return { type: 'CURRENT_MENU', payload }
}

export const SetThemeContent = (current_theme, content, component) => {


    current_theme?.current_theme?.filter(element => {
        if (element.name === component)
            element.content = content;
        return element
    })
    return { type: 'THEME_CONTENT', payload: current_theme.current_theme }
}



export const getCurrentTheme = (payload) => (dispatch) =>
    new Promise(function (resolve, reject) {
        let url = window.$storeUrl + window.$apiRoot + '/HomeSectionBuilder/getcurrentTheme';
        // let url = 'https://dbservices.dukan.pk/beta/services/HomeSectionBuilder/getcurrentTheme';
        dispatch(UNSetCurrentTheme())
        axios.post(url, { store_id: window.$storeId }).then(
            (res) => {
                if (res.data.status) {
                    let response = JSON.parse(JSON.parse(res.data.data.data));
                    if (response != null && response !== '' && response !== undefined) {
                        let content = response.layout.components.filter(Item => Item?.name === 'ThemeHeader');
                        if (content != null && content !== '' && content !== undefined) {
                            dispatch(SetHeaderContent(content[0].content))
                        }
                        dispatch(SetCurrentTheme(response))
                        resolve(response);
                    }

                }
            }
        ).catch((error) => {
            reject(error);
        })
    });


export const updateCurrentTheme = (current_theme, content, component, type) => {
    if (type === 'current_theme') {
        current_theme[type]?.filter(element => {
            if(element.component === component)
                element.content = content;
            return element
        })
    } else {
        current_theme[type]?.components?.filter(element => {
            if (element.component === component)
                element.content = content;
            return element
        })
    }
    const updatedTheme = {
        layout: current_theme.current_layout,
        data: current_theme.current_theme

    }
    updateTheme(updatedTheme)
}


export const updateCurrentThemeData = (current_theme) => {

    const updatedTheme = {
        layout: current_theme.current_layout,
        data: current_theme.current_theme

    }
    updateTheme(updatedTheme)
}

const updateTheme = (updatedTheme) => {
    let url = 'https://dbservices.dukan.pk/beta/services/HomeSectionBuilder/addTheme';
    let postData = {
        data: (JSON.stringify(updatedTheme).replace(/\\n/g, ' ')).replace(/'/g, ','),
        store_id: window.$storeId,
        theme_id: '1',
        is_active: '1',
    }
    axios.post(url, postData).then(
        (res) => {
            if (res.data.status) {
            } else {
            }
        }
    ).catch(() => {
        if (!navigator.online) {
            toast.error('You are offline', {
               position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                theme: 'dark'
            });
        }
    })
}





