import React from "react";
import KsAccordian from "./KsAccordian";
import WhatsappContact  from "../../../../components/storefront/UI/WhatsappChat";
import { whatsAppChatPlugin } from "../../../../constants";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsYoutube,
  BsTwitter,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
const KsMobFooter = () => {
  const storeFrontData = useSelector((state) => {
    return { ...state.mall.storeFrontData };
  });
  return (
    <>
      <div className="d-block d-md-none springMobilefooter">
      <div
        className={`p-0  fixed-bottom whatsapp_btn`}
      >
        {(storeFrontData.store.show_contact_whatsapp === "Y" ||
          storeFrontData.store.show_contact_mobile === "Y") &&
          storeFrontData?.activePluginsIds.includes(
            whatsAppChatPlugin
          ) ? (
            <WhatsappContact
                whatsapp={storeFrontData.whatsapp}
              />
          ) : (
            ""
          )}
      </div>
        <KsAccordian />

        <div className="footer_box d-flex justify-content-between">
          <span className="text-white font-14 font-weight-bold">Follow Us</span>
          <div className="d-flex ">
            <a
              target="_blank"
              href="https://www.facebook.com/knowledgestreams"
              className="d-flex align-items-center text-white mx-2"
            >
              <i>
                <BsFacebook />
              </i>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/knowstreamstech/"
              className="d-flex align-items-center text-white mx-2"
            >
              <i>
                <BsInstagram />
              </i>
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/knowledgestreams/"
              className="d-flex align-items-center text-white mx-2"
            >
              <i>
                <BsLinkedin />
              </i>
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@Knowledgestreams"
              className="d-flex align-items-center text-white mx-2"
            >
              <i>
                <BsYoutube />
              </i>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/knowstreamstech"
              className="d-flex align-items-center text-white ml-2"
            >
              <i>
                <BsTwitter />
              </i>
            </a>
          </div>
        </div>
        <div className="footer_box text-center copyright_Box">
          <p className="text-white">
            Knowledge Streams © 2023 All Rights Reserved.
          </p>
          <p className="mt-2 text-white">Powered by dukan</p>
        </div>
      </div>
    </>
  );
};

export default KsMobFooter;
