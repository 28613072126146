const initialState = {
    current_theme: [],
    current_layout: {},
    loader: false,
    header_content: {},
    current_menu:[],
    current_testimonials:[],
    current_faqs:[]
}

const ThemeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CURRENT_THEME':
            return {
                ...state,
                current_theme: action.payload.data,
                current_layout: action.payload.layout
            };
        case 'CURRENT_TESTIMONIALS':
            return {
                ...state,
                current_testimonials: action.payload
            };
        case 'CURRENT_FAQS':
            return {
                ...state,
                current_faqs: action.payload
            };
        case 'UN_CURRENT_THEME':
            return {
                ...state,
                current_theme: [],
                current_layout: {},
                header_content: {},
                current_menu:[]
            };
        case 'HEADER_CONTENT':
            return {
                ...state,
                header_content: action.payload
            };
        case 'THEME_CONTENT':
            return {
                ...state,
                current_theme: action.payload
            };
            
        case 'TOGGLE_LOADER':
            return {
                ...state,
                loader: action.payload
            };
        case 'CURRENT_MENU':
            return {
                ...state,
                current_menu: action.payload
            };
        default:
            return state;
    }
};

export default ThemeReducer;