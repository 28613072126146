import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { FaPlus, FaMinus } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCartItems, updateFinalCartData } from "../../../../store/actions/mallActions";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { applyTaxPlugin } from "../../../../constants";
import { makeAnalyticsFunnel } from "../../../../store/actions/CommonActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NoData from "../../../mall/sub-pages/NoData";

const CartSidebar = (props) => {
    const cartData = useSelector((state) => state.mall.cartData);
    const finalCartData = useSelector((state) => state.mall.finalCartData);
    const storeFrontData = useSelector((state) => state.mall.storeFrontData);
    const [shippingAmount, setShippingAmount] = useState(0);
    const [printingCost, setPringtingCost] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [addOnsAdjustedPrice, setAddOnsAdjustedPrice] = useState(0);
    const [addOnsPrice, setAddOnsPrice] = useState(0);
    const [key, setKey] = useState(0);
    const [key2, setKey2] = useState(0);
    const [disabledBtn, setDisabledBtn] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        if (props.active) {
            defaultDataCheck();
            calculateShippingCharges();
            dispatch(makeAnalyticsFunnel("viewCart", JSON.stringify(cartData), window.$storeId));
        }
    }, [props.active]);
    const getPrice = (price) => {
        if (storeFrontData?.config["0"].currency_position === "left") {
            return storeFrontData?.config["0"].currency + " " + parseInt(price).toLocaleString();
        } else {
            return (
                <>
                    {parseInt(price).toLocaleString() + " "}
                    <small>{storeFrontData?.config["0"].currency} </small>
                </>
            );
        }
    };
    const getAdsonPrice = ({ addons = [], product_type }, quantity = 1) => {
        let price = 0;
        price = addons?.reduce((acc, addon) => {
            if (product_type !== "deal") return acc + parseInt(addon.price);
            return addon?.reduce((subAcc, { addon: { price } }) => {
                return subAcc + parseInt(price);
            }, acc);
        }, price);
        const total = price * quantity;
        // setAddOnsPrice(total);
        return getPrice(total);
    };

    const subTotal = useMemo(() => {
        return finalCartData.reduce((acc, { data, total_sum }) => {
            let addsons = data.reduce((itemAcc, { addons, product_type, quantity }) => {
                return addons?.reduce((addonAcc, addon) => {
                    if (product_type !== "deal") return addonAcc + parseInt(addon.price) * quantity;
                    return addon?.reduce((subAcc, { addon: { price } }) => {
                        return subAcc + parseInt(price) * quantity;
                    }, addonAcc);
                }, itemAcc);
            }, acc);
            return addsons + total_sum + acc;
        }, 0);
    }, [finalCartData]);

    const totalAmount = useMemo(() => {
        return finalCartData.reduce((acc, { total_sum }) => {
            return acc + total_sum;
        }, 0);
    }, [finalCartData]);

    const calculateShippingCharges = () => {
        let uniqueArray = [...new Set(cartData.map((item) => item.store_id))];
        let newArray = [];

        uniqueArray.forEach((item) => {
            let delivery_charges1 = 0;
            let totalSum = 0;
            let printingCost = 0;
            let findArray = cartData.filter((items) => items.store_id === item);

            findArray.forEach((data) => {
                if (window.$isStoreFront && data.texteditor) {
                    if (data.texteditor[0]?.text || data.texteditor[1]?.text) {
                        printingCost = printingCost + data.quantity * data.text_print_price;
                    }
                    if (data.texteditor[0]?.src || data.texteditor[0]?.src) {
                        printingCost = printingCost + data.quantity * data.image_print_price;
                    }
                }

                totalSum = totalSum + data.quantity * data.price;
            });
            if (findArray[0].delivery_charges) {
                delivery_charges1 = delivery_charges1 + parseInt(findArray[0].delivery_charges);
            }
            if (
                totalSum >= findArray[0]?.delivery_charges_data?.minimum_order &&
                findArray[0].free_delivery_applied === "Y"
            ) {
                delivery_charges1 = 0;
            }
            newArray.push({
                store_id: item,
                data: findArray,
                total_sum: totalSum,
                delivery_charges: delivery_charges1,
                printingCost: printingCost,
            });
            let shippingAmount = 0;

            newArray.forEach((item) => {
                shippingAmount = shippingAmount + item.delivery_charges;
            });
            if (window.$isStoreFront && storeFrontData?.store?.custom_delivery_charges === "Y") {
                shippingAmount = 0;
            }
            setShippingAmount(shippingAmount);
            setPringtingCost(printingCost);
        });
        // localStorage.setItem('final_cart',JSON.stringify(newArray))

        dispatch(updateFinalCartData(newArray));
    };
    const checkDeliveryCharges = (item) => {
        let findArray = finalCartData.filter((items) => items.store_id === item);

        return findArray[0].delivery_charges;
    };
    const defaultDataCheck = () => {
        var taxAmount = 0;
        var totalProducts = 0;
        cartData?.forEach((item) => {
            totalProducts = totalProducts + 1;
        });
        finalCartData.forEach((item) => {
            item.data.forEach((product) => {
                if (product.tax_applied === "Y") {
                    let tax_rate = product.tax_value !== "" ? parseFloat(product.tax_value) : 0;
                    taxAmount =
                        taxAmount + parseFloat(product.unit_price) * (tax_rate / 100) * parseInt(product.quantity);
                }
            });
        });
        if (cartData.length === 0) {
            setDisabledBtn("disable-link");
        } else {
            setDisabledBtn("");
        }

        setTotalProducts(totalProducts);

        if (window.$isStoreFront && storeFrontData?.activePluginsIds.includes(applyTaxPlugin)) {
            setTaxAmount(taxAmount);
        }
    };
    const refreshContent = () => {
        setKey(Math.random());
        setKey2(Math.random());
        defaultDataCheck();
    };
    const addUpdateCartItem = (con, index, cindex = -1) => {
        let cartItems = cartData;

        let indexData = cindex;
        if (cindex === -1) {
            indexData = cartItems.findIndex((item) => item.product_id === index);
        }
        if (con === "minus") {
            if (cartItems[indexData].quantity === 1) {
                return false;
            }
            cartItems[indexData].quantity = cartItems[indexData].quantity - 1;
        } else {
            if (
                cartItems[indexData].quantity >= cartItems[indexData].total_products &&
                cartItems[indexData].allow_negative_stock !== "Y"
            ) {
                return false;
            }
            cartItems[indexData].quantity = cartItems[indexData].quantity + 1;
        }
        let price = 0;
        cartItems.map((item, index) => {
            const { addons, product_type } = item;
            if (addons?.length > 0) {
                addons?.forEach((element) => {
                    if (product_type === "deal") {
                        price = element.reduce((acc, { addon }) => {
                            return acc + parseInt(addon.price) * item.quantity;
                        }, price);
                    } else {
                        if (element?.price !== undefined) {
                            if (index === indexData) {
                                price = price + parseInt(element?.price) * item.quantity;
                            } else {
                                price = price + parseInt(element?.price);
                            }
                        }
                    }
                });
            }
        });
        setKey(Math.random());
        setKey2(Math.random());
        setAddOnsAdjustedPrice(price);
        dispatch(updateCartItems(cartItems));

        calculateShippingCharges();
        refreshContent();
        if (con === "minus") {
            dispatch(makeAnalyticsFunnel("addToCart_minus_quantity", JSON.stringify(cartItems), window.$storeId));
        } else {
            dispatch(makeAnalyticsFunnel("addToCart_plus_quantity", JSON.stringify(cartItems), window.$storeId));
        }
    };
    const deleteCartItems = (index, itemIndex = 0) => {
        let cartItems = cartData;
        cartItems.splice(itemIndex, 1); //= cartItems.filter((item) => item.product_id !== index)

        let price = 0;
        cartItems.map((item, index) => {
            const { addons, product_type } = item;
            if (addons?.length > 0) {
                addons?.forEach((element) => {
                    if (product_type === "deal") {
                        price = element.reduce((acc, { addon: { price } }) => {
                            return acc + parseInt(price) * item.quantity;
                        }, price);
                    } else if (element?.price !== undefined) {
                        price = price + parseInt(element?.price) * item.quantity;
                    }
                });
            }
        });
        setKey(Math.random());
        setKey2(Math.random());

        updateCartItems(cartItems);
        setTimeout(() => {
            calculateShippingCharges();
            refreshContent();
        }, 500);
    };
    const getaddonPriceFromItem = (items) => {
        let price = 0;
        items.addons?.map((addon, key) => {
            if (addon?.addon) {
                price = addon?.addon.reduce((acc, { price }) => {
                    return acc + parseInt(price);
                }, price);
            } else {
                price = price + parseInt(addon?.price);
            }
        });
        return price;
    };
    const goToCheckout = () => {
        let tkn = localStorage.getItem("tempCartToken");
        dispatch(makeAnalyticsFunnel("initiate_checkout", JSON.stringify(finalCartData), window.$storeId));
        if (tkn && tkn !== "") {
            history.push("/checkout");
        } else {
            let lastProd =
                finalCartData[finalCartData.length - 1].data[finalCartData[finalCartData.length - 1].data.length - 1];
            let cName = lastProd?.bazar_type_name ? lastProd?.bazar_type_name?.split(" & ").join("_") : "";
            if (cName && window.pixelArray[cName]) {
                let trackingObj = {
                    store_name: storeFrontData?.store?.data?.name ?? "",
                    event: "InitiateCheckout",
                    ecommerce: {
                        currencyCode: "PKR",
                        add: {
                            // 'add' actionFieldObject measures.
                            actionField: { button: "Initiate Checkout" }, // 'add to cart' or 'buy it now'
                            products: [
                                {
                                    //  adding a product to a shopping cart.
                                    name: lastProd.product_name, // Name or ID is required.
                                    id: lastProd.product_id,
                                    price: lastProd.price,
                                    brand: storeFrontData?.store?.data?.name ?? "",
                                    category: window.$isStoreFront ? lastProd.bazar_category : lastProd.category_id,
                                    sub_category: window.$isStoreFront
                                        ? lastProd.sub_bazar_category
                                        : lastProd.sub_category_id, // If sub-category not available then skip this parameter
                                },
                            ],
                        },
                    },
                };
                ReactPixel.init(window.pixelArray[cName]);
                ReactPixel.track("CheckOut", trackingObj);
            }

            ReactPixel.track("InitiateCheckout", {
                store_name: storeFrontData?.store?.data?.name ?? "",
                event: "InitiateCheckout",
                price: finalCartData[0]?.total_sum + finalCartData[0]?.delivery_charges,
                ecommerce: {
                    currencyCode: "PKR",
                    add: {
                        // 'add' actionFieldObject measures.
                        actionField: { button: "Initiate Checkout" }, // 'add to cart' or 'buy it now'
                        products: [finalCartData],
                    },
                },
            });
            TagManager.dataLayer({
                dataLayer: {
                    store_name: storeFrontData?.store?.data?.name ?? "Dukan",
                    event: "initiate_checkout",
                    ecommerce: {
                        currencyCode: "PKR",
                    },
                },
            });
            history.push("/checkout");
        }
    };
    return (
        <>
            <div className={`cartsidebar ${props.active ? "active" : ""} `}>
                <Modal.Header className="border-0 p-0 mb-2">
                    <Modal.Title className=" w-100 d-flex justify-content-between align-items-center">
                        <span className="font-26 font-weight-bold">Your Cart</span>
                        <Image onClick={props.handleInactiveCartSidebar} src="/assets/i/close.svg" className="crs" />
                    </Modal.Title>
                </Modal.Header>

                <div className="outercartDetails">
                    {cartData?.length === 0 && (
                        <>
                            <NoData page="cart" />
                        </>
                    )}
                    {finalCartData &&
                        finalCartData.map((item, index) => (
                            <Fragment key={index}>
                                {item.data.map((items, index1) => (
                                    <div key={index1} className="cartDetails pt-3">
                                        <div className="cartBrandimg position-relative d-inline-block">
                                            <img
                                                src={items.product_picture_thumbnail}
                                                onError={(e) => {
                                                    e.target.src = "/assets/i/FoodthemeImages/foodbanner.png";
                                                }}
                                                alt="cart"
                                            />
                                            <div className="cartcounter d-flex justify-content-center align-items-center">
                                                {items.quantity}
                                            </div>
                                        </div>
                                        <div className="cartaddons_items pt-2">
                                            <h5 className="font-16 font-weight-bold">{items.name}</h5>
                                            {items.addons && items.addons.length > 0 ? (
                                                <li className="d-flex justify-content-between font-11 addons-items">
                                                    <p className="w-75 addobs_listclr">
                                                        <AddOnDetails
                                                            {...{
                                                                items,
                                                                getPrice,
                                                            }}
                                                        />
                                                        {/* {items.addons.map((adon, i) => {
                                                            return (
                                                                <>
                                                                    <div className="deal-one">
                                                                        <h6 className="font-16">
                                                                            {adon.name}
                                                                        </h6>
                                                                        <p className="font-16">
                                                                            <b>{item?.quantity}x</b>{" "}
                                                                            {adon.name}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            );
                                                        })} */}
                                                    </p>
                                                    <span className="font-weight-bold">
                                                        {" "}
                                                        {getAdsonPrice(items, items.quantity)}{" "}
                                                    </span>
                                                </li>
                                            ) : (
                                                ""
                                            )}

                                            <div className="cart-right d-flex align-items-center justify-content-between cartsideqty">
                                                <div className="qty-box d-flex align-items-center">
                                                    <span className="font-14 d-block mr-2 qu">Quantity</span>

                                                    <div className="quantity-box d-flex align-items-center justify-content-between">
                                                        {items.quantity > 1 && (
                                                            <Button
                                                                variant="link"
                                                                className="p-0"
                                                                onClick={(e) => {
                                                                    addUpdateCartItem(
                                                                        "minus",
                                                                        items.product_id,
                                                                        index1
                                                                    );
                                                                }}
                                                            >
                                                                <i>
                                                                    <FaMinus />
                                                                </i>
                                                            </Button>
                                                        )}
                                                        {items.quantity == 1 && (
                                                            <Button
                                                                variant="link"
                                                                className="p-0"
                                                                onClick={(e) => {
                                                                    deleteCartItems(items.product_id, index1);
                                                                }}
                                                            >
                                                                <i>
                                                                    <AiOutlineDelete />
                                                                </i>
                                                            </Button>
                                                        )}
                                                        <span>{items.quantity}</span>
                                                        <Button
                                                            variant="link"
                                                            className="p-0"
                                                            onClick={(e) => {
                                                                addUpdateCartItem("add", items.product_id, index1);
                                                            }}
                                                        >
                                                            <i>
                                                                <FaPlus />
                                                            </i>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="price-cart text-right">
                                                    <h4 className="font-20 font-weight-bold">
                                                        {window.$isStoreFront &&
                                                        storeFrontData.config["0"].separate_addon_price === "Y"
                                                            ? getPrice(
                                                                  items.quantity *
                                                                      (parseInt(items.price) +
                                                                          getaddonPriceFromItem(items))
                                                              )
                                                            : getPrice(items.quantity * items.price)}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                </div>

                <div className="summary-main" key={key2}>
                    <div className="option-one bg-white">
                        <div className="option-head d-flex justify-content-between px-0">
                            <h4 className="font-16 font-weight-bold">
                                {localStorage.getItem("tempCartToken") && localStorage.getItem("tempCartToken") !== ""
                                    ? "Booking"
                                    : "Order"}{" "}
                                Summary
                            </h4>
                        </div>
                        <div className="summary-data px-0">
                            <ul>
                                <li className="d-flex justify-content-between font-12 align-items-center">
                                    {localStorage.getItem("tempCartToken") &&
                                    localStorage.getItem("tempCartToken") !== ""
                                        ? "Services"
                                        : "Items"}{" "}
                                    ({finalCartData[0]?.data.length})
                                    <span>
                                        {" "}
                                        {window.$isStoreFront && storeFrontData.config["0"].separate_addon_price === "Y"
                                            ? getPrice(totalAmount + addOnsPrice)
                                            : getPrice(totalAmount)}{" "}
                                    </span>
                                </li>
                                {localStorage.getItem("tempCartToken") &&
                                localStorage.getItem("tempCartToken") !== "" ? (
                                    ""
                                ) : window.$isStoreFront && storeFrontData?.store?.custom_delivery_charges === "Y" ? (
                                    ""
                                ) : (
                                    <li className="d-flex justify-content-between font-12 align-items-center">
                                        Delivery Fee
                                        <span className="font-weight-bold">{getPrice(shippingAmount)} </span>
                                    </li>
                                )}
                                {window.$isStoreFront && !printingCost ? (
                                    ""
                                ) : (
                                    <li className="d-flex justify-content-between font-12 align-items-center">
                                        Printing Cost
                                        <span className="font-weight-bold">{getPrice(printingCost)} </span>
                                    </li>
                                )}
                                {window.$isStoreFront && storeFrontData?.activePluginsIds.includes(applyTaxPlugin) ? (
                                    <li className="d-flex justify-content-between font-12 align-items-center">
                                        {parseInt(taxAmount) > 0 ? (
                                            <>
                                                Tax
                                                <span className="font-weight-bold">{getPrice(taxAmount)}</span>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </li>
                                ) : (
                                    ""
                                )}
                                {window.$isStoreFront && storeFrontData.config["0"].separate_addon_price !== "Y" ? (
                                    <li className="d-flex justify-content-between font-12 align-items-center">
                                        {parseInt(addOnsPrice) > 0 ? (
                                            <>
                                                Add Ons Price
                                                <span className="font-weight-bold">
                                                    {addOnsPrice > addOnsAdjustedPrice
                                                        ? getPrice(addOnsPrice)
                                                        : getPrice(addOnsAdjustedPrice)}
                                                </span>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </li>
                                ) : (
                                    ""
                                )}
                            </ul>
                            <ul className="border-0 total">
                                <li className="d-flex justify-content-between font-16 font-weight-bold p-0 align-items-center">
                                    Subtotal
                                    <span className="font-18">
                                        {getPrice(
                                            subTotal +
                                                parseFloat(shippingAmount) +
                                                parseFloat(printingCost) +
                                                parseFloat(taxAmount)
                                        )}
                                    </span>
                                </li>
                            </ul>
                            <Link
                                className={`my-btn d-flex justify-content-center align-items-center w-100 text-uppercase font-weight-bold text-white ${disabledBtn}`}
                                onClick={goToCheckout}
                            >
                                Checkout
                            </Link>
                            {/* <div className="w-100 text-center mt-2">
								<Link className="m-auto continue_shopping font-14">Continue Shopping</Link>
							</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div
                onClick={props.handleInactiveCartSidebar}
                className={`overlaysidebar ${props.active ? "active" : ""} `}
            ></div>
        </>
    );
};
export default CartSidebar;

function AddOnDetails({ items, getPrice }) {
    const { addons, quantity } = items ?? {};
    if (!addons?.length) return null;
    return addons?.map((addon, idx) => {
        if (Array.isArray(addon)) {
            const byProduct = Object.groupBy(addon, ({ addon: { product_name } }) => product_name);
            if (!byProduct) return null;
            return Object.entries(byProduct).map(([product_name, addons], idx) => {
                return (
                    <p key={idx} className="font-13 mb-1 gray">
                        <b>
                            {quantity} X {product_name}
                        </b>
                        :{" "}
                        {addons
                            .map(({ addon: { name, price } }) => {
                                return `${name} (${getPrice(price)})`;
                            })
                            .join(", ")}
                    </p>
                );
            });
        } else {
            return (
                <p key={idx} className="font-13 mb-1 gray">
                    {addon?.price ? (
                        <b>
                            {quantity} X {addon.name}
                        </b>
                    ) : (
                        ""
                    )}{" "}
                    ( {addon?.price ? getPrice(addon?.price) : ""} )
                </p>
            );
        }
    });
}
