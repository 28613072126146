import React, { Component } from "react";
import NoCart from "../../../assets/i/nocart.svg";
import NoProducts from "../../../assets/i/NoProducts.svg";
import NoDataImg from "../../../assets/i/zero-illustration.svg";
import NoPageImg from "../../../assets/i/404.svg";
import { Image } from "react-bootstrap";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../axios_purchase";
class NoData extends Component {
	render() {
		let image = NoDataImg;
		let text = "No Data Found";
		let showButton = false;
		switch (this.props.page) {
			case "cart":
				image = NoCart;
				showButton = true;
				text = this.props.text ?? "Your cart is empty";
				break;
			case "products":
				image = NoProducts;
				text = this.props.text ?? "No Products Found";
				break;
			case "order":
				text = this.props.text ?? "No orders available";
				break;
			case "wishlist":
			case "services":
				text = this.props.text ?? "No bookings available";
				break;
				image = NoProducts;
				text = this.props.text ?? "No Products Found";
				break;
			case "cartfetch":
				image = "";
				text = this.props.text ?? "Fetching Data...";
			case "page":
				showButton = "showPageButton";
				image = NoPageImg;
				text = this.props.text ?? "Page Not Found";
			default:
				showButton = "showPageButton";
				image = NoPageImg;
				text = this.props.text ?? "Page Not Found";
				break;
		}
		return (
			<>
				<div className="text-center no-data ">
					<Image src={image} alt="IMG" className="d-block m-auto"/>
						
					{showButton === true ? (
						<>
							<p className="mt-2 ">{text}</p>
							<button
								type="button"
								className="h-auto mt-3 search-ar Checkout-btn btn text-white border-0  font-14 font-weight-bold d-inline-flex justify-content-center align-items-center btn btn-primary add_Btn"
								onClick={(e) => {
									this.props?.current_layout?.name == "ThemeLayout" &&
									this.props.previousPage &&
									this.props.previousPage === "services"
										? this.props.history.push("/services")
										: this.props?.current_layout?.name == "ThemeLayout"
										? this.props.history.push("/shop")
										: this.props.history.push("/");
								}}
							>
								Browse{" "}
								{this.props.previousPage && this.props.previousPage === "services"
									? "Services"
									: "Products"}
							</button>
						</>
					) : showButton === "showPageButton" ? (
						<button
							type="button"
							className="h-auto mt-3 search-ar Checkout-btn btn text-white border-0  font-14 font-weight-bold d-inline-flex justify-content-center align-items-center btn btn-primary add_Btn"
							onClick={(e) => {
								this.props.history.push("/");
							}}
						>
							Go to Home
						</button>
					) : (
						""
					)}
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		...state.theme,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {};
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(withErrorHandler(NoData, axios));
