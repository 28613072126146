
import { EditorState, ContentState, convertFromHTML, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlReactParse from "html-react-parser";
import { Children, createElement } from "react";

export function handleDescription(description) {
    if (!description) return null;
    const editorState = EditorState.createWithContent(
        typeof description == 'string' ? ContentState.createFromBlockArray(
            convertFromHTML(description)
        ) : convertFromRaw(description)
    );
    return htmlReactParse(draftToHtml(convertToRaw(editorState.getCurrentContent())))
}
// export function When({ children }) {
//     return children.map((child, idx) => {
//         if (child.type.name === "Case") {
//             const { condition } = child.props;
//             const conditionResult = Boolean(condition);
//             if (!conditionResult) return null;
//             if (child.props.component.key) return child;
//             return { ...child, key: idx };
//         } else if (child.type.name === "Default") {
//             if (child.props.component.key) return child;
//             return { ...child, key: idx };
//         }
//         return null;
//     });
// }

// export function Switch({ children }) {
//     let matchChild = null;
//     let defaultChild = null;
//     Children.forEach(children, child => {
//         if (!matchChild && child.type.name === "Case") {
//             const { condition } = child.props;
//             const conditionResult = Boolean(condition);
//             if (conditionResult) matchChild = child;
//         } else if (!defaultChild && child.type.name === "Default") {
//             defaultChild = child;
//         }
//     })
//     return matchChild ?? defaultChild ?? null;
// }

export function ConditionalRender({ tag, condition, children, ...rest }) {
    if (!Boolean(condition)) return null;
    if (!tag) return children;
    return createElement(tag, rest ?? null, children);
};

// export const Case = ({ children }) => children;
// export const Default = ({ children }) => children;

export function Each({ renderItem, items, ...rest }) {
    if (!Array.isArray(items)) return null;
    if (!items?.length) return null;
    if (typeof renderItem !== "function") throw new Error("renderItem must be a function");
    return Children.toArray(items.map((item, idx) => renderItem(item, idx, rest)));
}
