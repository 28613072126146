import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SmashFooterAccordian from "./SmashFooterAccordian";
import NewLogo from "../../../../assets/i/updatelogoblue.svg";
import { useHistory } from "react-router-dom";
const SmashBurgerMobFooter = (props) => {
  const storeData = useSelector((state) => {
    return { ...state.mall.storeFrontData?.store?.data };
  });
  const storeData1 = useSelector((state) => {
    return { ...state.mall.storeFrontData?.config[0] };
  });
  const history = useHistory();
  const isShopPage = history.location.pathname.includes("/products");
  return (
    <>
      <div className={`${isShopPage ? "footerHeight" : ""} d-block d-md-none springMobilefooter`}>
        <SmashFooterAccordian />
        {(storeData1?.social_insta != undefined && storeData1?.social_insta != "") ||
        (storeData1?.social_insta != undefined && storeData1?.social_insta != "") ||
        (storeData1?.social_tiktok != undefined && storeData1?.social_tiktok != "") ||
        (storeData1?.social_twitter != undefined && storeData1?.social_twitter != "") ||
        (storeData1?.social_youtube != undefined && storeData1?.social_youtube != "") ? (
          <div className="footer_box d-flex justify-content-between">
            <span className="text-white font-14 font-weight-bold">Follow Us</span>
            <div className="d-flex ">
              {storeData1?.social_fb != undefined && storeData1?.social_fb != "" ? (
                <a href={storeData1?.social_fb} _target="blank" className="d-flex align-items-center">
                  <i className="icon-fb-i mr-2"></i>
                  Facebook
                </a>
              ) : (
                ""
              )}

              {storeData1?.social_insta != undefined && storeData1?.social_insta != "" ? (
                <a href={storeData1?.social_insta} _target="blank" className="d-flex align-items-center">
                  <i className="icon-insta-i mr-2"></i>
                  Instagram
                </a>
              ) : (
                ""
              )}

              {storeData1?.social_tiktok != undefined && storeData1?.social_tiktok != "" ? (
                <a href={storeData1?.social_tiktok} _target="blank" className="d-flex align-items-center">
                  <i className="icon-tiktok-i mr-2"></i>
                  TikTok
                </a>
              ) : (
                ""
              )}

              {storeData1?.social_twitter != undefined && storeData1?.social_twitter != "" ? (
                <a href={storeData1?.social_twitter} _target="blank" className="d-flex align-items-center">
                  <i className="icon-twitter-i mr-2"></i>
                  Twitter
                </a>
              ) : (
                ""
              )}

              {storeData1?.social_youtube != undefined && storeData1?.social_youtube != "" ? (
                <a href={storeData1?.social_youtube} _target="blank" className="d-flex align-items-center">
                  <i className="icon-youtube-i mr-2"></i>
                  YouTube
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="footer_box text-center copyright_Box">
          <p className="text-white">
            <p>{props?.content?.copy_right_text}</p>
          </p>
          <p className="mt-2 text-white">
            Powered by{" "}
            <Link>
              <img src="/assets/i/logo.svg" alt="" fluid className="mb-2 ml-2 org-logo" />
              <img src={NewLogo} alt="" fluid className="mb-2 ml-2 org-logo-two d-none" />
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default SmashBurgerMobFooter;
