export const AddOnsModal = (payload) => (dispatch) => {
    dispatch(AddOnsModalFlag(payload));
}

export const AddOnsModalFlag = (payload) => {
    return { type: 'TOGGLE_ADD_ONS_MODAL', payload }

}
export const AddOnsOpen = (payload) => {
    return { type: 'TOGGLE_DROPDOWNS', payload }

}

export const AddAddOns = (addOn, Type, product_id = null) => {
    return { type: "ADD_ADD_ONS", payload: { addOn, Type, product_id } };
};

export const SelectAllAddOns = (selectToggle, addon) => {
    return { type: 'SELECT_ALL_ADD_ONS', payload: { selectToggle, addon } }
}


export const unSetStates = () => {
    return { type: 'UNSETSTATES' }
}


