import React from "react";
import { Container, Button, Image, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import NewLogo from "../../../../assets/i/updatelogoblue.svg";
import { FiYoutube } from "react-icons/fi";
// import { Link } from "react-router-dom";

const SpringThemeFooter = (props) => {
  const storeData = useSelector((state) => {
    return { ...state.mall.storeFrontData?.store?.data };
  });
  const storeData1 = useSelector((state) => {
    return { ...state.mall.storeFrontData?.config[0] };
  });
  const storefrontData = useSelector((state) => {
    return { ...state.mall.storeFrontData?.store };
  });
  const currentMenu = useSelector((state) => state.theme.current_menu);

  return (
    <div className="d-none d-md-block spring_footer">
      <Container>
        <div className="top-footer">
          <Row className="justify-content-between">
            <Col md="4">
              <div className="footer_box">
                <p>{props?.content?.copy_right_text}</p>
                <p className="mt-2">
                  Powered by{" "}
                  <a href="#">
                    <img
                      src="https://www.dukan.pk/demo/dtemp/ptImg1650968743-45315746628252.svg"
                      alt=""
                      fluid
                      className="mb-2 ml-2 org-logo"
                    />
                    <img src={NewLogo} alt="" fluid className="mb-2 ml-2 org-logo-two d-none" />
                  </a>
                </p>
              </div>
            </Col>
            {/* 834677 */}
            {window.$storeId === "834677" ? (
              <Col md="2">
                <div className="footer_box">
                  <h4>Learning</h4>
                  <div className="footer_Links">
                    <a
                      target="_blank"
                      href="https://www.dukan.pk/ui/ptImg1677487888-39840079575843.pdf"
                      className="d-block font-12"
                    >
                      Puppy Food and Nutrition
                    </a>
                    <a
                      target="_blank"
                      href="https://www.dukan.pk/ui/ptImg1677488101-36683319575547.pdf"
                      className="d-block font-12"
                    >
                      How to Look after your Cat
                    </a>
                    <a
                      target="_blank"
                      href="https://www.dukan.pk/ui/ptImg1677488187-13345369575173.pdf"
                      className="d-block font-12"
                    >
                      Appropriate Feeding Directions for Cat Food
                    </a>
                    <a
                      target="_blank"
                      href="https://www.dukan.pk/ui/ptImg1677488226-83644349575708.pdf"
                      className="d-block font-12"
                    >
                      Heat Stroke for Dogs_ Know everything you need to
                    </a>
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )}

            <Col md="2">
              <div className="footer_box">
                {window.$storeId === "833825" ? (
                  <h4>Fuego</h4>
                ) : window.$storeId === "842215" ? (
                  <h4>TheBarCode</h4>
                ) : (
                  <h4>Company</h4>
                )}

                <div className="footer_Links">
                  {currentMenu?.length > 0 &&
                    currentMenu?.map((menu, index) => {
                      return (
                        <Link className="d-block font-12" to={menu.link}>
                          {menu.title}
                        </Link>
                      );
                    })}
                      {window.$storeId === "923821" ? (
                  <>
                    <Link className="d-block font-12" to="/returns-shipping-policies">
                    Return & Shipping Policies
                        </Link>
                   
                  </>
                ) : (
                  ""
                )}
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="footer_box">
                <h4>Contact Us</h4>
                <div className="footer_Links">
                  {storeData?.store_email != null ? (
                    <a href={`mailto:${storeData?.store_email}`} className="d-block font-12">
                      Email: {storeData?.store_email}
                    </a>
                  ) : (
                    ""
                  )}

                  {storeData?.contact_mobile != null && storefrontData.show_contact_mobile === "Y" ? (
                    <a href={`tel:${storeData?.contact_mobile}`} className="d-block font-12">
                      Call us: {storeData?.contact_mobile}
                    </a>
                  ) : (
                    ""
                  )}

                  {storeData?.open_time != "" && storeData?.close_time != "" ? (
                    <Link onClick={(e) => e.preventDefault()} to="#" className="d-block font-12 timeblock">
                      Time: {storeData?.open_time} - {storeData?.close_time}
                    </Link>
                  ) : (
                    ""
                  )}
                  {/* <a href="#" className="d-block font-12">
                    Time: {props?.content?.availability}
                  </a> */}
                </div>

                {window.$storeId === "839687" ? (
                  <>
                    <h4 className="mt-3">Our Seasonal Pop-Ups</h4>
                    <div className="footer_Links">
                      <a
                        target="_blank"
                        href="https://instagram.com/cafedecomo?igshid=MzRlODBiNWFlZA=="
                        className="d-block font-12"
                      >
                        Paola’s Asian Diner (Winter)
                      </a>
                      <a
                        target="_blank"
                        href="https://instagram.com/paolas_upstairs?igshid=MzRlODBiNWFlZA=="
                        className="d-block font-12"
                      >
                        Paola's Roman Holiday (Summer)
                      </a>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
            {(storeData1?.social_insta != undefined && storeData1?.social_insta != "") ||
            (storeData1?.social_insta != undefined && storeData1?.social_insta != "") ||
            (storeData1?.social_tiktok != undefined && storeData1?.social_tiktok != "") ||
            (storeData1?.social_twitter != undefined && storeData1?.social_twitter != "") ||
            (storeData1?.social_youtube != undefined && storeData1?.social_youtube != "") ? (
              <Col md="2">
                <div className="footer_box">
                  <h4>Follow Us</h4>
                  <div className="d-flex footer_Links flex-column">
                    {storeData1?.social_fb != undefined && storeData1?.social_fb != "" ? (
                      <a rel="noreferrer" href={storeData1?.social_fb} target="_blank" className="d-flex align-items-center">
                        <i className="icon-fb-i mr-2"></i>
                        Facebook
                      </a>
                    ) : (
                      ""
                    )}

                    {storeData1?.social_insta != undefined && storeData1?.social_insta != "" ? (
                        <a
                          rel="noreferrer"
                          href={storeData1?.social_insta}
                          target="_blank"
                          className="d-flex align-items-center">
                        <i className="icon-insta-i mr-2"></i>
                        Instagram
                      </a>
                    ) : (
                      ""
                    )}

                    {storeData1?.social_tiktok != undefined && storeData1?.social_tiktok != "" ? (
                      <a rel="noreferrer" href={storeData1?.social_tiktok} target="_blank" className="d-flex align-items-center">
                        <i className="icon-tiktok-i mr-2"></i>
                        TikTok
                      </a>
                    ) : (
                      ""
                    )}

                    {storeData1?.social_twitter != undefined && storeData1?.social_twitter != "" ? (
                      <a rel="noreferrer" href={storeData1?.social_twitter} target="_blank" className="d-flex align-items-center">
                        <i className="icon-twitter-i mr-2"></i>
                        Twitter
                      </a>
                    ) : (
                      ""
                    )}

                    {storeData1?.social_youtube != undefined && storeData1?.social_youtube != "" ? (
                      <a rel="noreferrer" href={storeData1?.social_youtube} target="_blank" className="d-flex align-items-center">
                        <i className=" mr-2">
                          <FiYoutube />
                        </i>
                        YouTube
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default SpringThemeFooter;
