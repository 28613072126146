import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import mallReducer from "./store/reducers/mallReducer";
import PageReducer from "./store/reducers/PageReducer";
import ThemeReducer from "./store/reducers/ThemeReducer";
import { ksHomePageReducer, ksGettingStartedReducer, ksExecutiveTeamReducer, kstestimonialsReducer, ksFaqReducer } from "./store/reducers/KsReducer";


import reportWebVitals from './reportWebVitals';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/lib/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css'
import "react-toastify/dist/ReactToastify.css";
import WebSkeletonHome from "./components/mall/sub-pages/WebSkeletonHome";
import WebSkeletonStoreFront from "./components/mall/sub-pages/StoreFrontSkeleton";
import WebSkeletonProductDetail from "./components/mall/sub-pages/ProductSkeleton";
import Config from './config'
import './App.css';
import ShopReducer from './store/reducers/ShopReducer';
import WishlistReducer from './store/reducers/WishlistReducer';
import App from './App/index';
import AddOnsReducer from './store/reducers/AddOnsReducer';

let params = new URLSearchParams(window.location.search);
let baseUrl = window.location.pathname.split('/')[1];
window.$AssetsUrl = 'https://assets.dukan.pk/' + baseUrl + '/assets/';

window.$baseUrl = baseUrl;

window.$betaUrl = Config.base;
window.$successURL = '';
window.$retryIntialData = 1;
const generateUUID = () => { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

var Skelton = <WebSkeletonStoreFront />;
window.$isStoreFront = true;
window.$isFromEasypaisaApp = false;
window.$isFromJazzCashApp = false;
window.$isFromJazzCashAppToken = '';
window.$easyPaisaOpenId='';
window.$easyPaisalat='';
window.$easyPaisalong='';
window.$easyPaisaAuthCode='';
if (window.location.href.indexOf('dukanbazar.pk') > 0) {
    window.$isStoreFront = false;
    Skelton = <WebSkeletonHome />;
}
if (window.location.href.indexOf('easypaisa-') > 0 || window?.mp) {
    window.$isFromEasypaisaApp = true;
    if(params.get('openId') && params.get('openId')!== ''){
        window.$easyPaisaOpenId = params.get('openId');
    }else{
        window.$easyPaisaOpenId = ''
    }
}
if (window.location.href.indexOf('jazzcashminiapps-') > 0) {
    window.$isFromJazzCashApp = true;
    window.$isFromJazzCashAppToken = params.get('token');
}


window.$isShortURL = true
if (window.location.href.indexOf('Retail') > 0) {
    window.$isShortURL = false
}
if (window.location.href.indexOf('products') > 0) {
    Skelton = <WebSkeletonProductDetail />;
}
if (!window.localStorage.getItem("tempSession")) {
    window.localStorage.setItem('tempSession', generateUUID())
    window.localStorage.setItem('tempSessionTime', new Date().toLocaleString())
}
// window.localStorage.setItem("tempCartToken", "")
window.$isCombinedView = window.location.href.indexOf('combined-view') > 0 ? true : false;
window.$isPreview = window.location.href.indexOf('previewcase') > 0 ? true : false;
window.$isWebView = window.location.href.indexOf('webview') > 0 ? true : false;
window.$isSharedApp = window.location.href.indexOf('sharedapp') > 0 ? true : false;
window.$storeUrl = Config.siteUrl;
window.$storeId = '';
window.$serviceToken = "";
window.$showBranchModal = false;
window.$allowBranchModal = false;
window.$flag = 0;
window.$apiRoot = 'services';
window.$apiCall = '/rest/api';
let token = params.get('id');
window.$token = token;
window.$showLoader = false;
window.$loadFirst = true;
window.$previousUrl = ''
window.$isMarket = false;
window.$editStoreToken = '';
window.pixelArray = {
    'Food': '607224747040502',
    'Pharmacy': '637847824003210',
    'Retail_Grocery': '506109267897726',
    'Electronics': '507777240680034',
    'Health_Beauty': '337017648089648',
    'Fashion': '1077808346405170',
    'Baby_Toys': '505311590887087',
    'Sports_Outdoor': '2441143726020681',
    'Automotive_Motorbike': '4972014362842602',
    'Home_Lifestyle': '963596191030678'
}
const composeEnhancers = (process.env.REACT_APP_STAGE === 'demo' || process.env.REACT_APP_STAGE === 'dev' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        "theme",
        "page",
        "shop",
        "ksHomePage",
        "ksGettingStarted",
        "ksExecutiveTeam",
        "kstestimonials",
        "ksFaq"
    ],
    wishlist: ["mall", "addons"]
}

const rootReducer = combineReducers({
    mall: mallReducer,
    theme: ThemeReducer,
    page: PageReducer,
    shop: ShopReducer,
    wishlist: WishlistReducer,
    addons: AddOnsReducer,
    ksHomePage: ksHomePageReducer,
    ksGettingStarted: ksGettingStartedReducer,
    ksExecutiveTeam: ksExecutiveTeamReducer,
    kstestimonials: kstestimonialsReducer,
    ksFaq: ksFaqReducer
});



const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))
const persistor = persistStore(store)
const app = (
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter basename={Config.basename}>
                    <Suspense fallback={Skelton}>
                        <App token={token} />
                    </Suspense>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>
)

ReactDOM.render(
    app,
    document.getElementById('root')
);
reportWebVitals();
serviceWorkerRegistration.unregister();
// export {store}