import axios from "../../axios_purchase";
import { toast } from "react-toastify";

const GET_HOME_PAGE_REQUEST = "GET_KS_HOME_PAGE_REQUEST";
const GET_HOME_PAGE_SUCCESS = "GET_KS_HOME_PAGE_SUCCESS";
const GET_HOME_PAGE_FAILURE = "GET_KS_HOME_PAGE_FAILURE";

const GET_GETTING_STARTED_REQUEST = "GET_GETTING_STARTED_REQUEST";
const GET_GETTING_STARTED_SUCCESS = "GET_GETTING_STARTED_SUCCESS";
const GET_GETTING_STARTED_FAILURE = "GET_GETTING_STARTED_FAILURE";

const GET_EXECUTIVE_TEAM_REQUEST = "GET_EXECUTIVE_TEAM_REQUEST";
const GET_EXECUTIVE_TEAM_SUCCESS = "GET_EXECUTIVE_TEAM_SUCCESS";
const GET_EXECUTIVE_TEAM_FAILURE = "GET_EXECUTIVE_TEAM_FAILURE";

const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

const GET_TESTIMONIALS_REQUEST = "GET_TESTIMONIALS_REQUEST";
const GET_TESTIMONIALS_SUCCESS = "GET_TESTIMONIALS_SUCCESS";
const GET_TESTIMONIALS_FAILURE = "GET_TESTIMONIALS_FAILURE";

function handleRequest({ type, payload }) {
    return { type, payload };
}

const getHomePageData = () => (dispatch) => {
    dispatch(handleRequest({ type: GET_HOME_PAGE_REQUEST }));
    let url = `${window.$storeUrl}${window.$apiRoot}/knowledgeStream/getHomePageSectionData`;
    axios
        .post(url)
        .then((res) => {
            if (res.data.success) {
                dispatch(
                    handleRequest({
                        type: GET_HOME_PAGE_SUCCESS,
                        payload: res?.data?.response,
                    })
                );
            }
        })
        .catch((error) => {
            handleRequest({
                type: GET_HOME_PAGE_FAILURE,
                payload: "Something went wrong",
            });
            if (!navigator.online) {
                toast.error("You are offline", {
                   position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                    theme: "dark",
                });
            }
        });
};

const getGettingStarted = (page_id) => dispatch => {
    dispatch(handleRequest({ type: GET_GETTING_STARTED_REQUEST }));
    let url = `${window.$storeUrl}${window.$apiRoot}/Page/getPage`;
    axios
        .post(url, { page_id })
        .then((res) => {
            if (res?.data?.data?.content) {
                dispatch(
                    handleRequest({
                        type: GET_GETTING_STARTED_SUCCESS,
                        payload: res?.data?.data?.content,
                    })
                );
            }
        })
        .catch((error) => {
            handleRequest({
                type: GET_GETTING_STARTED_FAILURE,
                payload: "Something went wrong",
            });
            if (!navigator.online) {
                toast.error("You are offline", {
                   position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                    theme: "dark",
                });
            }
        });
}

const getExecutiveTeam = (page_id) => dispatch => {
    dispatch(handleRequest({ type: GET_EXECUTIVE_TEAM_REQUEST }));
    let url = `${window.$storeUrl}${window.$apiRoot}/Page/getPage`;
    axios
        .post(url, { page_id })
        .then((res) => {
            if (res?.data?.data?.content) {
                dispatch(
                    handleRequest({
                        type: GET_EXECUTIVE_TEAM_SUCCESS,
                        payload: res?.data?.data?.content,
                    })
                );
            }
        })
        .catch((error) => {
            handleRequest({
                type: GET_EXECUTIVE_TEAM_FAILURE,
                payload: "Something went wrong",
            });
            if (!navigator.online) {
                toast.error("You are offline", {
                   position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                    theme: "dark",
                });
            }
        });
}

const getFaqs = (page_id) => dispatch => {
    dispatch(handleRequest({ type: GET_FAQ_REQUEST }));
    let url = `${window.$storeUrl}${window.$apiRoot}/Page/getPage`;
    axios
        .post(url, { page_id })
        .then((res) => {
            if (res?.data?.data?.content) {
                dispatch(
                    handleRequest({
                        type: GET_FAQ_SUCCESS,
                        payload: res?.data?.data?.content,
                    })
                );
            }
        })
        .catch((error) => {
            handleRequest({
                type: GET_FAQ_FAILURE,
                payload: "Something went wrong",
            });
            if (!navigator.online) {
                toast.error("You are offline", {
                   position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                    theme: "dark",
                });
            }
        });
}

const getTestimonials = () => dispatch => {
    dispatch(handleRequest({ type: GET_TESTIMONIALS_REQUEST }));
    let url = `${window.$storeUrl}/rest/api/KsTestimonials/index`;
    axios
        .post(url)
        .then((res) => {
            console.log("🚀 ~ file: KsActions.js:117 ~ .then ~ res:", res)
            if (res?.data?.response) {
                dispatch(
                    handleRequest({
                        type: GET_TESTIMONIALS_SUCCESS,
                        payload: res?.data?.response,
                    })
                );
            }
        })
        .catch((error) => {
            handleRequest({
                type: GET_TESTIMONIALS_FAILURE,
                payload: "Something went wrong",
            });
            if (!navigator.online) {
                toast.error("You are offline", {
                   position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                    theme: "dark",
                });
            }
        });
}

export {
    GET_HOME_PAGE_REQUEST,
    GET_HOME_PAGE_SUCCESS,
    GET_HOME_PAGE_FAILURE,
    getHomePageData,

    GET_GETTING_STARTED_REQUEST,
    GET_GETTING_STARTED_SUCCESS,
    GET_GETTING_STARTED_FAILURE,
    getGettingStarted,

    GET_EXECUTIVE_TEAM_REQUEST,
    GET_EXECUTIVE_TEAM_SUCCESS,
    GET_EXECUTIVE_TEAM_FAILURE,
    getExecutiveTeam,

    GET_FAQ_REQUEST,
    GET_FAQ_SUCCESS,
    GET_FAQ_FAILURE,
    getFaqs,

    GET_TESTIMONIALS_REQUEST,
    GET_TESTIMONIALS_SUCCESS,
    GET_TESTIMONIALS_FAILURE,
    getTestimonials,
};
