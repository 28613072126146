import React from "react";
import OtpCode from "./OtpCode";

function OtpForm() {
  return (
    <>
      <div className="login-form  mx-auto w-100">
    
      
        <div className="otp-form">
          <p className="font-14  mb-2 ">
          Enter 4 digit OTP sent on your number.

          </p>
<OtpCode />
          <p className="font-14  my-2 ">Resend code in 00:53</p>
        </div>

       
      </div>
    </>
  );
}

export default OtpForm;
