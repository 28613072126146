import axios from "../../axios_purchase";
import { toast } from "react-toastify";
import { analyticsFunnel } from "../../constants";
import { store } from '../../index'

const makeAnalyticsFunnel = (event, content, content_id) => dispatch => {
    let activePluginsIds = store.getState('mall').mall?.storeFrontData?.activePluginsIds;
    let logStoreVisit = true
    // console.log('storeFrontData?.activePluginsIds.includes(applyTaxPlugin)' + activePluginsIds.includes(analyticsFunnel))
     let url = window.$storeUrl + window.$apiRoot + '/store/analyticsFunnel'
    let session_id;
    let sessionTime;
    let diffInMinutes = 0;
    if (window.localStorage.getItem('tempSession') && window.localStorage.getItem('tempSession') !== '') {
        session_id = window.localStorage.getItem('tempSession');
        sessionTime = window.localStorage.getItem("tempSessionTime");
    } else {
        session_id = generateUUID();
        sessionTime = new Date().toLocaleString();
        window.localStorage.setItem('tempSession', session_id);
        window.localStorage.setItem('tempSessionTime', sessionTime)
    }
    if (window.localStorage.getItem("store_visit")) {
        let starTime = new Date(sessionTime)
        let endDate = new Date();
        let s = endDate.getTime() - starTime.getTime()
        diffInMinutes = Math.round(s / 60000);
        if (diffInMinutes <= 30 && event === 'storevisit') {
            logStoreVisit = false;
        }
    }


    if (!activePluginsIds.includes(analyticsFunnel)) {
        logStoreVisit = false;
    }

    if (logStoreVisit && window.location.href.indexOf('ikon')< 0 && window.location.href.indexOf('localhost')< 0) {
        axios
            .post(url,
                {
                    "session_id": session_id,
                    "store_id": window.$storeId,
                    "event": event,
                    "content": content,
                    "content_id": content_id
                })
            .then((res) => {
                console.log(res)

            })
            .catch((error) => {
                if (!navigator.online) {
                    toast.error("AF - You are offline", {
                       position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
                        theme: "dark",
                    });
                }
            });
    }

}
const syncCartItemsFromServer = () => {
    
    let cartData = store.getState('mall').mall?.cartData;
    // let mallData = store.getState('mall');
    // mallData.mall?.cartData = [];
    // store.setState('mall')
    let cartarray = (typeof (cartData) != 'undefined' && cartData.length > 0) ? cartData : [];
    // { type: 'UPDATE_CART_DETAIL',[]}
    localStorage.setItem("cartItems", JSON.stringify([]))
    console.log('cartarray', cartarray)
    if (cartarray.length > 0) {
        cartarray.map((cartItem, index) => {
            console.log('cartItem', cartItem, index);
            fetchProductDetail(cartItem, cartItem.quantity, cartItem.addons, index)
        })
    }
}

const fetchProductDetail = (product, quantity, addons, index) => {
    const postData = {
        product_id: product?.product_id,
        store_id: window.$storeId,
    }
    axios.post(window.$storeUrl + window.$apiRoot + '/mall/productDetail', postData).then(
        (res) => {
            updateCartItem(res.data.product_data, addons)
        }
    ).catch(err => {
        console.log(err)
    })
}

const updateCartItem = (newProductArray, selectedAddOns, index) => {
    let cartData = store.getState('mall').mall?.cartData
    delete newProductArray?.customer_reviews;
    delete newProductArray?.slider_image;
    delete newProductArray?.description;
    let newlySelectedAdons = []
    let data = newProductArray?.addons?.map(element => {
        if (element?.is_required === 'Y') {
            const existedIndex = selectedAddOns?.findIndex(obj => {
                return parseInt(obj.add_on_id) === parseInt(element?.add_on_id)
            })
            if (existedIndex === -1) {
                newlySelectedAdons.push(element)
            }
        }
    })

    var addonString = [];
    if (newlySelectedAdons?.length > 0) {
        newProductArray.addons = newlySelectedAdons;
    } else {
        newProductArray.addons = [];
    }

    let oldadddonString = []
    let newcartArr = []
    let cartarray = (typeof (cartData) != 'undefined' && cartData.length > 0) ? cartData : [];
    // // cartarray.splice(index, 1)
    // if (cartarray.length > 0) {

    // }

    newcartArr  = [...cartarray, newProductArray]
    console.log('cartarraycartarraycartarraycartarray', newcartArr);
    localStorage.setItem("cartItems", JSON.stringify(newcartArr))
    return { type: 'UPDATE_CART_DETAIL',newcartArr}
    // updateCartItems(cartarray)

    // makeAnalyticsFunnel('updateCartFeature', JSON.stringify(cartarray), newProductArray.product_id)

}

const generateUUID = () => { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export {
    makeAnalyticsFunnel,
    syncCartItemsFromServer,
};
