import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SpringLayout from "./SpringLayout.jsx";
import { AddOnsModal } from "../../../store/actions/addonsactions";
import { getHomePageData } from "../../../store/actions/KsActions.js";

function mapStateToProps(state) {
    return {
        ...state.mall,
        ...state.theme,
        ...state.addons,
    };
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            AddOnsModal,
            getHomePageData,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SpringLayout);
