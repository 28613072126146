import React, { useEffect, useState, lazy } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../../../../config";
import { SketchPicker } from "react-color";
import dark from "../../../../assets/i/ThemeImages/dark.svg";
import light from "../../../../assets/i/ThemeImages/light.svg";
import custom from "../../../../assets/i/ThemeImages/custom.svg";
import { useSelector } from "react-redux";
import NewLogo from "../../../../assets/i/updatelogoblue.svg";
import { FiYoutube } from "react-icons/fi";
const FooterAccordian = lazy(() => import("./FooterAccordian"));

const SpringMobfooter = (props) => {
  const storeData1 = useSelector((state) => {
    return { ...state.mall?.storeFrontData };
  });
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });
  const [open, setOpen] = useState(false);
  // destructuring rgba from state
  const { r, g, b, a } = sketchPickerColor;
  const [showFooter, setShowFooter] = useState("d-none");
  useEffect(() => {
    // if (window.location.pathname === '/' || window.location.pathname === config.base) {

    //   if (props?.content?.onlyOnHome) {
    //     setShowFooter('d-block')
    //   }
    // }

    if (window.location.pathname.indexOf("product-detail") > 0 || window.location.pathname.indexOf("products") > 0) {
      setShowFooter("d-none");
    } else {
      setShowFooter("d-block");
    }
  }, []);
  return (
    <>
      <div className={showFooter + " d-md-none springMobilefooter"}>
        <FooterAccordian />
        {(storeData1?.config[0]?.social_insta != undefined && storeData1?.config[0]?.social_insta != "") ||
        (storeData1?.config[0]?.social_insta != undefined && storeData1?.config[0]?.social_insta != "") ||
        (storeData1?.config[0]?.social_tiktok != undefined && storeData1?.config[0]?.social_tiktok != "") ||
        (storeData1?.config[0]?.social_twitter != undefined && storeData1?.config[0]?.social_twitter != "") ||
        (storeData1?.config[0]?.social_youtube != undefined && storeData1?.config[0]?.social_youtube != "") ? (
          <div className="footer_box d-flex justify-content-between">
            <span className="text-white font-14 font-weight-bold">Follow Us</span>
            <div className="d-flex ">
              {storeData1?.config[0]?.social_fb != undefined && storeData1?.config[0]?.social_fb != "" ? (
                <a _target="blank" href={storeData1?.config[0]?.social_fb} className="d-flex align-items-center text-white mx-2">
                  <i className="icon-fb-i mr-2"></i>
                  Facebook
                </a>
              ) : (
                ""
              )}

              {storeData1?.config[0]?.social_insta != undefined && storeData1?.config[0]?.social_insta != "" ? (
                <a
                  _target="blank"
                  href={storeData1?.config[0]?.social_insta}
                  className="d-flex align-items-center text-white mx-2"
                >
                  <i className="icon-insta-i mr-2"></i>
                  Instagram
                </a>
              ) : (
                ""
              )}

              {storeData1?.config[0]?.social_tiktok != undefined && storeData1?.config[0]?.social_tiktok != "" ? (
                <a
                  _target="blank"
                  href={storeData1?.config[0]?.social_tiktok}
                  className="d-flex align-items-center text-white mx-2"
                >
                  <i className="icon-tiktok-i mr-2"></i>
                  TikTok
                </a>
              ) : (
                ""
              )}

              {storeData1?.config[0]?.social_twitter != undefined && storeData1?.config[0]?.social_twitter != "" ? (
                <a
                  _target="blank"
                  href={storeData1?.config[0]?.social_twitter}
                  className="d-flex align-items-center text-white mx-2"
                >
                  <i className="icon-twitter-i mr-2"></i>
                  Twitter
                </a>
              ) : (
                ""
              )}

              {storeData1?.config[0]?.social_youtube != undefined && storeData1?.config[0]?.social_youtube != "" ? (
                <a
                  _target="blank"
                  href={storeData1?.config[0]?.social_youtube}
                  className="d-flex align-items-center text-white mx-2"
                >
                  <i className=" mr-2">
                    <FiYoutube />
                  </i>
                  YouTube
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="footer_box text-center copyright_Box">
          <p className="text-white">{props?.content?.copy_right_text}</p>
          <p className="mt-2 text-white">
            Powered by{" "}
            <a href="#">
              <img
                src="https://www.dukan.pk/demo/dtemp/ptImg1650968743-45315746628252.svg"
                alt=""
                className="mb-2 ml-2 org-logo"
              />
              <img src={NewLogo} alt="" fluid className="mb-2 ml-2 org-logo-two d-none" />
            </a>
          </p>
        </div>
      </div>
      <div className="addsectionBox d-flex d-md-none align-items-center justify-content-center themeupgrade">
        <Button
          className="w-100 d-flex align-items-center text-white mx-2  justify-content-center addSecBtn bg-transparent font-weight-bold"
          name="upgrade"
        >
          Upgrade Website Theme
        </Button>
      </div>
      <div className="customize_color bg-white p-2 d-block d-md-none position-relative">
        <div className="d-flex align-items-center text-white mx-2  justify-content-center">
          <div className="d-flex align-items-center text-white mx-2">
            <Button variant="link" className="customize_btn active" name="light">
              <img src={light} alt="" />
            </Button>
            <span>Light Mode</span>
          </div>
          <div className="d-flex align-items-center text-white mx-2" name="dark">
            <Button variant="link" className="customize_btn active">
              <img src={dark} alt="" />
            </Button>
            <span>Dark Mode</span>
          </div>
          <div className="d-flex align-items-center text-white mx-2" name="custom">
            <Button variant="link" className="customize_btn ">
              <img src={custom} alt="" />
            </Button>
            <span>Customize</span>
          </div>
        </div>
        <div className="d-flex align-items-center text-white mx-2  customize_color_wrapper pl-2 py-3">
          <Button
            variant="link"
            className="background color-text px-2"
            onClick={() => setOpen(!open)}
            name="background"
          >
            <span
              style={{
                background: `rgba(${r},${g},${b},${a})`,
              }}
            ></span>
            Background
          </Button>
          <Button variant="link" onClick={() => setOpen(!open)} className="primary-text color-text px-2" name="primary">
            <span
              style={{
                background: `rgba(${r},${g},${b},${a})`,
              }}
            ></span>
            Primary Text
          </Button>
          <Button
            variant="link"
            onClick={() => setOpen(!open)}
            className="secondary-text color-text px-2"
            name="secondary"
          >
            <span
              style={{
                background: `rgba(${r},${g},${b},${a})`,
              }}
            ></span>
            Secondary Text
          </Button>
          <Button variant="link" onClick={() => setOpen(!open)} className="icons color-text px-2" name="Icons">
            <span
              style={{
                background: `rgba(${r},${g},${b},${a})`,
              }}
            ></span>
            Iocns
          </Button>
          <Button variant="link" onClick={() => setOpen(!open)} className="primary-btn color-text px-2" name="primary">
            <span
              style={{
                background: `rgba(${r},${g},${b},${a})`,
              }}
            ></span>
            Primary Button
          </Button>
          <Button
            variant="link"
            onClick={() => setOpen(!open)}
            className="secondary-btn color-text px-2"
            name="secondary"
          >
            <span
              style={{
                background: `rgba(${r},${g},${b},${a})`,
              }}
            ></span>
            Secondary Button
          </Button>
        </div>
        <button className="save-text mw-100 mt-2" name="save">
          Save
        </button>
        {open && (
          <div className="color_picker py-3 text-right position-absolute">
            <Button name="close" variant="link" className="mr-4" onClick={() => setOpen(false)}>
              <img src="/assets/i/ThemeImages/close.svg" alt="" />
            </Button>

            <SketchPicker
              className="w-75 m-auto"
              color={sketchPickerColor}
              onChange={(color) => {
                setSketchPickerColor(color.rgb);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SpringMobfooter;
