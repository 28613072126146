import React, { lazy, useEffect, useState, Suspense } from "react";
import { Container, Form } from "react-bootstrap";
import axios from "../../../../axios_purchase";
import { Link, useHistory, useLocation } from "react-router-dom";
import config from "../../../../config";
import { useSelector, useDispatch } from "react-redux";
import { updateUserLoggedInData } from "../../../../store/actions/mallActions";
import WebSkeletonHome from "../../../mall/sub-pages/StoreFrontSkeleton";
import { toast } from "react-toastify";
import { unSetWishListArray } from "../../../../store/actions/WishlistActions";
const RatingModal = lazy(() => import("../../../mall/sub-pages/RatingModal"));
const SidebarMenuItems = lazy(() => import("../../partials/SidebarMenuItems"));
const HeaderEditSection = lazy(() => import("./HomeModal/HeaderEditSection"));
const SignInModal = lazy(() => import("../../../mall/sub-pages/SignInModal"));
const RegisterModal = lazy(() => import("../../../mall/sub-pages/RegisterModal"));
const ForgetModal = lazy(() => import("../../../mall/sub-pages/ForgetModal"));
const Header57 = lazy(() => import("../../theme57/sub_pages/Header57"));
const DefaultThemeComponent = lazy(() => import("../../DefaultThemeHeader"));
const YozoHeader = lazy(() => import("../../Yozo/YozoComponents/YozoHeader"));
const ZeenithHeader = lazy(() => import("../../ZenithTheme/ZeenithHeader"));
const OstudioHeader = lazy(() => import("../../oStudio/oStudioComponents/OstudioHeader"));
const TelemartHeader = lazy(() => import("../../Telemart/Header"));

const ThemeHeader = (props) => {
    const [active, setActive] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [blockClass, setBlockClass] = useState("d-none");
    const [productSearch, setProductSearch] = useState([]);
    const history = useHistory();
    const [showSingIn, setShowSingIn] = useState(false);
    const [showModalData, setShowModalData] = useState(false);
    const [showForget, setShowForget] = useState(false);
    const userLoggedInData = useSelector((state) => state.mall.userLoggedInData);
    // const headerContent = useSelector(state => state.theme.header_content);
    const currentMenu = useSelector((state) => state.theme.current_menu);
    const currentLayout = useSelector((state) => state.theme.current_layout);
    const cartData = useSelector((state) => state.mall.cartData);
    const [recentOrder, setRecentOrder] = useState("");
    const [activeBtnClass, setActiveBtnClass] = useState("");
    const [welcomeMessage, setWelcomeMessage] = useState(false);
    // const [showBackButton, setshowBackButton] = useState(true)
    const [randomKey, setRandomKey] = useState(Math.random());
    const [showBackButtonOnHomePage, setshowBackButtonOnHomePage] = useState(true);
    const [activeUrl, setActiveUrl] = useState("");
    const [show, setShow] = useState(false);
    const [headerClass, setHeaderClass] = useState("");
    const [headerComponents, setHeaderComponents] = useState({
        Header57,
        DefaultThemeComponent,
        ZeenithHeader,
        YozoHeader,
        OstudioHeader,
        TelemartHeader,
    });
    const location = useLocation();
    const storeData = useSelector((state) => {
        return { ...state.mall.storeFrontData };
    });

    function handleClose() {
        setShow(false);
    }

    const dispatch = useDispatch();

    const showModal = (type) => {
        if (type === "signup") {
            setRandomKey(Math.random());
            setShowModalData(true);
            setShowSingIn(false);
            setShowForget(false);
        } else if (type === "forget") {
            setRandomKey(Math.random());
            setShowModalData(false);
            setShowSingIn(false);
            setShowForget(true);
        } else {
            setRandomKey(Math.random());
            setShowModalData(false);
            setShowSingIn(true);
            setShowForget(false);
        }
    };

    const hideModal = (type) => {
        if (type === "signup") {
            setShowModalData(false);
        } else if (type === "forget") {
            setShowForget(false);
        } else {
            setShowSingIn(false);
        }
    };

    function handleInactive() {
        setActive(false);
    }

    const changeSearch = (e) => {
        var queryString = require("querystring");
        var searchValue = e.target.value;
        setSearchValue(e.target.value);
        if (searchValue.length >= 3 && searchValue != "") {
            let postData = {
                searchValue: searchValue,
                store_id: window.$storeId,
            };
            axios
                .post(window.$storeUrl + window.$apiRoot + "/storefront/search", postData)
                .then((res) => {
                    if (res.data.code === "11") {
                        setBlockClass("d-block");

                        setProductSearch(res.data.data);
                    } else {
                        setBlockClass("d-none");
                        setProductSearch([]);
                    }
                })
                .catch();
        } else {
            setBlockClass("d-none");
            setProductSearch([]);
        }
    };
    const selectProduct = (item) => {
        setBlockClass("d-none");

        history.push(
            "/products/" +
                item.product_name
                    .split("%")
                    .join("-percent")
                    .split("/")
                    .join("-")
                    .split("_")
                    .join("-")
                    .split(" ")
                    .join("-") +
                "~" +
                btoa(item.product_id)
        );
    };
    const searchDetail = (e) => {
        if (!window.$isStoreFront) {
            setBlockClass("d-none");
            setProductSearch([]);
            history.push(`/category-detail/${btoa(e.target.value)}/product`);
        } else {
            e.preventDefault();
        }
    };

    const logoutData = () => {
        localStorage.removeItem("user_data");
        dispatch(
            updateUserLoggedInData({
                mobile_no: "",
                password: "",
                is_loggedIn: false,
                token: "",
                user_id: "",
                user_name: "",
                email: "",
            })
        );
        toast.success("Logged out successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            theme: "dark",
        });
        dispatch(unSetWishListArray([]));
    };

    const goBackNow = () => {
        if (window.location.pathname === "/" || config.base.includes(window.location.pathname)) {
            window.location.href = window.location.href + `?inapp://exit`;
        } else {
            history.go(-1);
        }
    };
    const getMyOrders = () => {
        var userData = {
            token: this.props.userLoggedInData?.token,
            type: "home",
            store_id: window.$storeId,
            isStoreFront: window.$isStoreFront ? "Y" : "N",
        };
        axios
            .post(window.$storeUrl + window.$apiRoot + "/customer/getCustomerOrders", userData)
            .then((res) => {
                if (res.data.status) {
                    const recentOrderRetrivedFromCall = res.data.data?.length > 0 ? res.data.data[0] : {};
                    this.setState({ recentOrder: recentOrderRetrivedFromCall });
                }
            })
            .catch();
    };

    useEffect(() => {
        let defatulTArray = [
            {
                name: "shop",
                route: "shop",
            },
            {
                name: "services",
                route: "services",
            },
            {
                name: "testimonials",
                route: "testimonials",
            },
            {
                name: "faq",
                route: "faq",
            },
            {
                name: "smashfeeds",
                route: "smashfeeds",
            },
            {
                name: "contactus",
                route: "contactus",
            },
            {
                name: "blog",
                route: "blog",
            },
            {
                name: "cart-detail",
                route: "cart-detail",
            },
            {
                name: "wishlist",
                route: "wishlist",
            },
            {
                name: "checkout",
                route: "checkout",
            },
            {
                name: "product-detail",
                route: "product-detail",
            },
            {
                name: "products",
                route: "product-detail",
            },
            {
                name: "my-account",
                route: "my-account",
            },
            {
                name: "about us",
                route: "about-us",
            },
            {
                name: "about-us",
                route: "about-us",
            },
            {
                name: "our story",
                route: "our-story",
            },
            {
                name: "aboutus",
                route: "aboutus",
            },
            {
                name: "requestquoat",
                route: "requestquoat",
            },
            {
                name: "view-location",
                route: "view-location",
            },
            {
                name: "my-wallet",
                route: "my-wallet",
            },
            {
                name: "page",
                route: "page",
            },
        ];
        var pathFound = defatulTArray.filter((items) => {
            if (window.location.pathname.indexOf(items.name) > 0) {
                return items;
            }
        });
        if (pathFound.length > 0) {
            setActiveUrl(pathFound[0].route);
        }

        if (window.location.pathname === "/" || config.base.includes(window.location.pathname)) {
            setWelcomeMessage(true);
            setshowBackButtonOnHomePage(false);
        } else if (window.location.pathname.indexOf("editStore") > 0) {
            setWelcomeMessage(true);
        } else {
            setHeaderClass("header57static");
        }

        if (location.pathname === "/") {
            setHeaderClass("");
        } else {
            setHeaderClass("header57static");
        }
    }, []);
    useEffect(() => {
        if (props.showLoginForm) {
            showModal();
        }
    }, [props.showLoginForm]);

    const generateHeaderComponent = () => {
        let find = currentLayout.components.filter((items) => items.name === "ThemeHeader");
        if (find.length > 0) {
            let SubModules = headerComponents[find[0]?.sub_component];
            if (SubModules) {
                return (
                    <SubModules
                        cartData={cartData}
                        showModal={showModal}
                        userLoggedInData={userLoggedInData}
                        logoutData={logoutData}
                        headerClass={headerClass}
                        setActive={setActive}
                        headerData={find[0]}
                        currentMenu={currentMenu}
                        storeData={storeData}
                    />
                );
            } else {
                return (
                    <DefaultThemeComponent
                        setShow={setShow}
                        location={location}
                        userLoggedInData={userLoggedInData}
                        logoutData={logoutData}
                        showModal={showModal}
                        showBackButtonOnHomePage={showBackButtonOnHomePage}
                        goBackNow={goBackNow}
                        setActive={setActive}
                        cartData={cartData}
                        setActiveBtnClass={setActiveBtnClass}
                        currentMenu={currentMenu}
                        activeUrl={activeUrl}
                    />
                );
            }
        }
    };
    const checkOpenLink = (item) => {
        history.push(item?.link);
    };
    return (
        <>
            {generateHeaderComponent()}
            <div
                className={`search_bar ${
                    active && "active searchbartwo_position"
                } position-absolute d-flex align-items-center w-100 ${!welcomeMessage ? "searchbar_position" : ""}`}
            >
                <Container className="position-relative w-100" fluid>
                    <Form.Group controlId="formBasicEmail" className="mb-0">
                        <Form.Control
                            type="text"
                            placeholder="Search Products"
                            onChange={(e) => {
                                changeSearch(e);
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" || e.keyCode === 13) {
                                    searchDetail(e);
                                }
                            }}
                        />
                    </Form.Group>
                    <i className="icon-close-i position-absolute" onClick={handleInactive}></i>

                    <div className={`${blockClass} search-dropdown  position-absolute py-2 w-100 bg-white px-3`}>
                        {productSearch.length > 0 &&
                            productSearch.map((item) => (
                                <Link
                                    onClick={() => {
                                        setActive(false);
                                        setBlockClass("d-none");
                                    }}
                                    className="font-14  d-flex py-2  border-bottom justify-content-between"
                                    to={
                                        "/products/" +
                                        item.product_name
                                            .split("%")
                                            .join("-percent")
                                            .split("/")
                                            .join("-")
                                            .split("_")
                                            .join("-")
                                            .split(" ")
                                            .join("-") +
                                        "~" +
                                        btoa(item.product_id)
                                    }
                                >
                                    {item.product_name}
                                </Link>
                            ))}
                    </div>
                </Container>
            </div>

            <SignInModal showSingIn={showSingIn} hideModal={hideModal} showModal={showModal} />

            <RegisterModal showModalData={showModalData} hideModal={hideModal} showModal={showModal} />

            <ForgetModal showForget={showForget} hideModal={hideModal} showModal={showModal} />

            {window.$isStoreFront &&
            userLoggedInData?.is_loggedIn &&
            recentOrder?.ratings?.rating_id == 0 &&
            recentOrder?.order_status === "65" ? (
                <RatingModal recentOrder={recentOrder} />
            ) : (
                ""
            )}

            <SidebarMenuItems
                checkOpenLink={checkOpenLink}
                onClickbtn={setActiveBtnClass}
                activebtn={activeBtnClass == "active" ? "active" : ""}
            />

            <div onClick={setActiveBtnClass} className={`overlaysidebar  overlayspring ${activeBtnClass}`}></div>

            <HeaderEditSection show={show} handleClose={handleClose} />
        </>
    );
};

export default ThemeHeader;
