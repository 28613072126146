export const addDefaultValue = (key,value) =>{
            return { type:'SET_KEY_VALUE_DATA',obj:{ [key]: value }}
}
export const addBestSellingProducts = (data) =>{
    return { type: 'SET_BEST_SELLING_PRODUCTS',data}
}

export const addFeaturedData = (data) =>{
    return { type: 'SET_FEATURED_PRODUCTS',data}
}
export const addBannerImages = (data) =>{
    return { type: 'SET_BANNER_IMAGE',data}
}

export const addCategoriesData = (data) =>{
    return { type: 'SET_CATEGORIES_DATA',data}
}
export const addProductData = (data) =>{
    return { type: 'SET_PRODUCT_DATA',data}
}
export const updateCartItems = (data) => {
    let sessionTime = new Date().toLocaleString();
    localStorage.setItem("cartItems", JSON.stringify(data))
    if(data.length > 0){
        window.localStorage.setItem('cartTime', sessionTime);
    }
    return { type: 'UPDATE_CART_DETAIL',data}
}
export const setRelatedProducts = (data) =>{
    return { type: 'SET_RELATED_PRODUCTS',data}
}
export const updateSearchItems = (data) =>{
    return { type: 'UPDATE_SEARCH_ITEMS',data}
}
export const updateMiddleBar = (data) =>{
    return { type: 'UPDATE_MIDDLE_BAR',data}
}
export const updateFilterData = (data) =>{
    return { type: 'UPDATE_FILTER_DATA',data}
}
export const updateSubcategoryData = (data) =>{
    return { type: 'UPDATE_SUBCATEGORY_DATA',data}
}
export const updateSubcategoryChildData = (data) =>{
    return { type: 'UPDATE_SUBCATEGORY_CHILD_DATA',data}
}
export const updateBestSellingDetail = (data) =>{
    return {type:'BEST_SELLING_UPDATE',data}
}
export const updateFeaturedData = (data) =>{
    return {type:'UPDATE_FEAUTURED_DATA',data}
}
export const updateFinalCartData = (data) =>{
    return {type:'UPDATE_FINAL_CART',data}
}

export const updateUserLoggedInData = (data) =>{
    return {type:'UPDATE_USER_LOGGED_IN_DATA',data}
}
export const addFacetData = (data) =>{
    return {type:'ADD_FACET_DATA',data}
}
export const addOfferBanner = (data) =>{
    return {type:'ADD_OFFER_BANNER',data}
}
export const customerOrderData = (data) =>{
    return {type:'CUSTOMER_ORDER_DATA',data}
}
export const addCustomerWalletData = (data) =>{
    return {type:'CUSTOMER_WALLET_DATA',data}
}

// store front actions start

export const loadInitialData = (data) =>{
    return { type: 'LOAD_INITIAL_DATA',data}
}

export const loadMarketData = (data) =>{
    return { type: 'LOAD_MARKET_DATA',data}
}

export const setStoreFrontProducts = (data) =>{
    return { type: 'SET_STOREFRONT_PRODUCTS',data}
}

export const setMarketStores = (data) =>{
    return { type: 'SET_MARKET_STORES',data}
}
export const setMarketProducts = (data) =>{
    return { type: 'SET_MARKET_PRODUCTS',data}
}
export const setMarketDeals = (data) =>{
    return { type: 'SET_MARKET_DEALS',data}
}
export const setMarketCategories = (data) =>{
    return { type: 'SET_MARKET_CATEGORIES',data}
}
export const setStoreFrontFeaturedProducts = (data) =>{
    return { type: 'SET_STOREFRONT_FEATURED_PRODUCTS',data}
}

export const removeCategories = () =>{
    return { type: 'REMOVE_CATEGORIES'}
}

export const removeMarketStoreType = () =>{
    return { type: 'REMOVE_MARKET_STORE_TYPE'}
}

export const filteredProductsSuccess = (cat_id, sub_cat_id, filteredProducts) => {
    return {
        type: 'SET_PRODUCTS_FILTER',
        cat_id: cat_id,
        sub_cat_id: sub_cat_id,
        filteredProducts: filteredProducts
    };
};

export const setFilteredMarketStores = (storeType, data) => {
    return {
        type: 'SET_FILTERED_MARKET_STORES',
        storeType: storeType,
        data: data
    };
};

export const fetchProductsFail = (error) => {
    return { type: 'FETCH_PRODUCTS_FAIL', error: error };
};

export const fetchProductsStart = () => {
    return { type: 'FETCH_PRODUCTS_START' };
};

export const appendProducts = (moreProducts) => {
    return { type: 'LOAD_MORE_PRODUCTS', moreProducts: moreProducts };
};
// store front actions end
export const resetCartItems =(data)=>{
    return {type:'RESET_CART_DATA',data}
};
export const setWalletConfigs =(data)=>{
    return {type:'SET_WALLET_CONFIGS',data}
};

export const setProductLike =(data) =>{
    return {type:'SET_PRODUCT_LIKE',data}
}

export const setUtmParams =(data) =>{
    return {type:'SET_UTM_PARAMS',data}
}
