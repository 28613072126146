const initialState = {
    openCloseToggle: false,
    selectedAddOnIndex: '',
    selectedAddOnIds: [],
    multipleSelectedAddOnIds: {},
    selectedAddOns: [],
    multipleSelectedAddOns: {},
    addOnsModal: false,
    currentAddOns: {},
};

const AddOnsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_DROPDOWNS':
            return {
                ...state,
                selectedAddOnIndex: action.payload,
                openCloseToggle: !state.openCloseToggle
            };
        case 'TOGGLE_ADD_ONS_MODAL':
            if (action?.payload?.close) return { ...state, addOnsModal: false };
            state.selectedAddOnIndex = '';
            state.selectedAddOnIds = [];
            state.selectedAddOns = [];
            state.currentAddOns = {};
            const oProduct = action?.payload?.originalProduct;
            const product = action?.payload?.product;
            const varient_id = action?.payload?.varient_id;
            let selectedAddOnsData = {}
            if (product !== undefined) {
                let addOns = [];
                const variants = oProduct?.varients;
                variants?.forEach(variant => {
                    variant?.value?.forEach(element => {
                        element?.variable_value?.forEach(vvalue => {
                            if (vvalue?.addons !== null) {
                                addOns = vvalue?.addons;
                            }
                        })
                    });
                })
                if (product?.sizes !== "" && typeof (product?.sizes) !== undefined && product?.sizes !== undefined) {
                    const selectedVarient = product?.sizes?.filter(element => {
                        if (element?.auto_id === varient_id)
                            return element
                    });
                    if (selectedVarient) {
                        selectedAddOnsData = {
                            product: product,
                            product_name: product?.product_name !== undefined ? product?.product_name : product?.name,
                            product_id: product?.product_id,
                            addons: addOns,
                            variation_id: selectedVarient[0]?.variation_id,
                            variation_name: selectedVarient[0]?.var_name,
                            quantity: oProduct?.quantity
                        };
                    } else {
                        selectedAddOnsData = {
                            product: product,
                            product_name: product?.product_name !== undefined ? product?.product_name : product?.name,
                            product_id: product?.product_id,
                            addons: product?.addons,
                            variation_id: 0,
                            variation_name: '',
                            quantity: oProduct?.quantity
                        };
                    }

                } else {

                    selectedAddOnsData = {
                        product: product,
                        product_name: product?.product_name !== undefined ? product?.product_name : product?.name,
                        product_id: product?.product_id,
                        addons: product?.addons,
                        variation_id: 0,
                        variation_name: '',
                        quantity: oProduct?.quantity
                    };
                }
            }
            return {
                ...state,
                addOnsModal: !state.addOnsModal,
                currentAddOns: selectedAddOnsData,
            };
        case 'ADD_ADD_ONS':
            const addOns = state.selectedAddOns;
            const addOnIds = state.selectedAddOnIds;
            const addOn = action.payload?.addOn
            const type = action.payload?.Type
            const product_id = action.payload?.product_id;
            const multipleSelectedAddOns = state.multipleSelectedAddOns;
            const multipleSelectedAddOnIds = state.multipleSelectedAddOnIds;
            if (product_id) {
                const selectedProduct = multipleSelectedAddOns[product_id];
                if (selectedProduct) {
                    if (type === "checkbox") {
                        const added = selectedProduct.find((obj, index) => {
                            if (obj.id === addOn.id) {
                                selectedProduct.splice(index, 1);
                                multipleSelectedAddOnIds[product_id].splice(index, 1);
                            }
                            return obj.id === addOn.id;
                        });
                        if (!added) {
                            selectedProduct.push(addOn);
                            multipleSelectedAddOnIds[product_id].push(addOn.id);
                        }
                    }
                    if (type === "radio") {
                        const index = multipleSelectedAddOns[product_id].findIndex(
                            (obj) => obj.add_on_id === addOn.add_on_id
                        );
                        if (multipleSelectedAddOns[product_id] && index > -1) {
                            selectedProduct.splice(index, 1);
                            multipleSelectedAddOnIds[product_id].splice(index, 1);
                            multipleSelectedAddOns[product_id] = selectedProduct;
                        }
                        multipleSelectedAddOns[product_id].push(addOn);
                        multipleSelectedAddOnIds[product_id].push(addOn.id);
                    }
                } else {
                    multipleSelectedAddOns[product_id] = [addOn];
                    multipleSelectedAddOnIds[product_id] = [addOn.id];
                }
            } else {
                if (type === 'radio') {

                    const existedIndex = addOns?.findIndex(obj => {
                        return obj.add_on_id === addOn.add_on_id
                    })
                    if (existedIndex === -1) {
                        addOns.push(addOn);
                        addOnIds.push(parseInt(addOn.id))
                    } else {
                        const existedIndexId = addOns?.findIndex(obj => {
                            return obj.id === addOn.id
                        })

                        if (existedIndexId > -1) {
                            addOns.splice(existedIndexId, 1);
                            addOnIds.splice(existedIndexId, 1);
                        }
                        else {
                            addOns[existedIndex] = addOn;
                            addOnIds[existedIndex] = parseInt(addOn.id);
                        }

                    }


                }

                if (type === 'checkbox') {
                    const existedIndex = addOns?.findIndex(obj => {
                        return obj.id === addOn.id
                    })

                    if (existedIndex === -1) {
                        addOns.push(addOn);
                        addOnIds.push(parseInt(addOn.id))
                    } else {
                        addOns.splice(existedIndex, 1);
                        addOnIds.splice(existedIndex, 1);
                    }
                }
            }
            return {
                ...state,
                selectedAddOnIds: addOnIds,
                selectedAddOns: addOns
            }
        case 'SELECT_ALL_ADD_ONS':
            const addOns2 = state.selectedAddOns;
            const addOnId2 = state.selectedAddOnIds;
            state.currentAddOns?.addons?.forEach(addon => {

                if (parseInt(addon.id) === parseInt(action?.payload?.addon?.add_on_id)) {
                    addon?.items?.forEach(res => {
                        if (action?.payload?.selectToggle) {
                            if (addOnId2.indexOf(parseInt(res.id)) === -1) {
                                addOns2.push(res);
                                addOnId2.push(parseInt(res.id))
                            }

                        } else {
                            addOns2.splice(addOnId2.indexOf(parseInt(res.id)), 1);
                            addOnId2.splice(addOnId2.indexOf(parseInt(res.id)), 1);
                        }
                    })
                }
            })
            return {
                ...state,
                selectedAddOnIds: addOnId2,
                selectedAddOns: addOns2
            }
        case 'UNSETSTATES':
            return {
                openCloseToggle: false,
                selectedAddOnIndex: '',
                selectedAddOnIds: [],
                multipleSelectedAddOnIds: {},
                selectedAddOns: [],
                multipleSelectedAddOns: {},
                addOnsModal: false,
                currentAddOns: {},
            };
        default:
            return state;
    }
};

export default AddOnsReducer;