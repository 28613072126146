import React, { Component } from 'react';
import OtpInput from "react-otp-input";

export default class OtpCode extends Component {
  state = { otp: "" };

  handleChange = otp => this.setState({ otp });

  render() {
    return (
      <OtpInput
        value={this.state.otp}
        onChange={this.handleChange}
        numInputs={4}
        isInputSecure={true}
        className="otpinput"
        containerStyle="otpcontainer"
      />
    );
  }
}

