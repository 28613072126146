import React, { Component } from 'react'
const withErrorHandler = (WrappedComponent, axios) => {
    return class extends Component {
        state = {
            error: null
        }

        componentWillMount() {
            this.reqInterceptor = axios.interceptors.request.use(req => {
                this.setState({
                    error: null
                })
                return req;
            },
            config => {
                const token = localStorage.getItem('token')
                if(token) {
                    config.headers.token = token
                }
                return config
            })
            this.resInterceptor = axios.interceptors.response.use(res => res, error => {
                this.setState({
                    error: error
                })
            })
        }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor)
            axios.interceptors.response.eject(this.resInterceptor)
        }

        errorConfirmedHandler = () => {
            this.setState({
                error: null
            })
        }

        render() {
            return (
                <>
                    
                    {/* {this.state.error ? <Error /> : <WrappedComponent {...this.props}/>} */}
                    <WrappedComponent {...this.props}/>
                </>
            )
        }

    }
}

export default withErrorHandler

// import React, { useState, useEffect } from 'react';
// const withErrorHandler = (WrappedComponent, axios) => {
//     const [state, setState] = useState({ error: null });

//     useEffect(() => {
//         const reqInterceptor = axios.interceptors.request.use(req => {
//             setState({
//                 error: null
//             })
//             return req;
//         },
//         config => {
//             const token = localStorage.getItem('token')
//             if(token) {
//                 config.headers.token = token
//             }
//             return config
//         })
//         const resInterceptor = axios.interceptors.response.use(res => res, error => {
//             setState({
//                 error: error
//             })
//         })
//         return () => {
//             axios.interceptors.request.eject(reqInterceptor)
//             axios.interceptors.response.eject(resInterceptor)
//         };
//     }, []);

//     return <WrappedComponent {...this.props}/>
// }

// export default withErrorHandler