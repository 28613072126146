import React from "react";
import { Container, Button, Image, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NewLogo from "../../../../assets/i/updatelogoblue.svg";
import { useHistory } from "react-router-dom";
const SmashBurgerFooter = (props) => {
  const storeData = useSelector((state) => {
    return { ...state.mall.storeFrontData?.store?.data };
  });
  const storeData1 = useSelector((state) => {
    return { ...state.mall.storeFrontData?.config[0] };
  });
  const storefrontData = useSelector((state) => {
    return { ...state.mall.storeFrontData?.store };
  });

  const currentMenu = useSelector((state) => state.theme.current_menu);
  const history = useHistory();
  const isShopPage = history.location.pathname.includes("/products");

  return (
    <div className={`${isShopPage ? "footerHeight" : ""} d-none d-md-block spring_footer py-3`}>
      <Container>
        <div className="top-footer">
          <Row className="justify-content-between">
            <Col md="3">
              <div className="footer_box">
                <h4>Company</h4>
                <div className="footer_Links">
                  {currentMenu?.length > 0 &&
                    currentMenu?.map((menu, index) => {
                      return (
                        <Link className="d-block font-12" to={menu.link}>
                          {menu.title}
                        </Link>
                      );
                    })}
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="footer_box">
                <h4>Contact Us</h4>
                <div className="footer_Links">
                  {storeData?.store_email != "" &&
                  storeData?.store_email != undefined &&
                  storeData?.store_email != null ? (
                    <a href="#" className="d-block font-12">
                      Email: {storeData?.store_email}
                    </a>
                  ) : (
                    ""
                  )}
                  {storeData?.contact_mobile != null && storefrontData.show_contact_mobile === "Y" ? (
                    <a href="#" className="d-block font-12">
                      Call us: {storeData?.contact_mobile}
                    </a>
                  ) : (
                    ""
                  )}
                  {storeData?.open_time != "" && storeData?.close_time != "" ? (
                    <a href="#" className="d-block font-12">
                      Time: {storeData?.open_time} - {storeData?.close_time}
                    </a>
                  ) : (
                    ""
                  )}
                  {window.$storeId === "827135" || window.$storeId === "843155" ? (
                    <>
                      <a href="#" className="d-block font-12">
                        Gulberg Branch: 51-MM Alam Road, Block B 1, Gulberg 3, Lahore
                      </a>
                      <a href="#" className="d-block font-12">
                        DHA Branch: Gold Crest, Grand Mall, Sector DD Dha Phase 4, Lahore
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Col>

            {(storeData1?.social_insta != undefined && storeData1?.social_insta != "") ||
            (storeData1?.social_insta != undefined && storeData1?.social_insta != "") ||
            (storeData1?.social_tiktok != undefined && storeData1?.social_tiktok != "") ||
            (storeData1?.social_twitter != undefined && storeData1?.social_twitter != "") ||
            (storeData1?.social_youtube != undefined && storeData1?.social_youtube != "") ? (
              <Col md="2">
                <div className="footer_box">
                  <h4>Follow Us</h4>
                  <div className="d-flex footer_Links flex-column">
                    {storeData1?.social_fb != undefined && storeData1?.social_fb != "" ? (
                      <a href={storeData1?.social_fb} _target="blank" className="d-flex align-items-center">
                        <i className="icon-fb-i mr-2"></i>
                        Facebook
                      </a>
                    ) : (
                      ""
                    )}

                    {storeData1?.social_insta != undefined && storeData1?.social_insta != "" ? (
                      <a href={storeData1?.social_insta} _target="blank" className="d-flex align-items-center">
                        <i className="icon-insta-i mr-2"></i>
                        Instagram
                      </a>
                    ) : (
                      ""
                    )}

                    {storeData1?.social_tiktok != undefined && storeData1?.social_tiktok != "" ? (
                      <a href={storeData1?.social_tiktok} _target="blank" className="d-flex align-items-center">
                        <i className="icon-tiktok-i mr-2"></i>
                        TikTok
                      </a>
                    ) : (
                      ""
                    )}

                    {storeData1?.social_twitter != undefined && storeData1?.social_twitter != "" ? (
                      <a href={storeData1?.social_twitter} _target="blank" className="d-flex align-items-center">
                        <i className="icon-twitter-i mr-2"></i>
                        Twitter
                      </a>
                    ) : (
                      ""
                    )}

                    {storeData1?.social_youtube != undefined && storeData1?.social_youtube != "" ? (
                      <a href={storeData1?.social_youtube} _target="blank" className="d-flex align-items-center">
                        <i className="icon-youtube-i mr-2"></i>
                        YouTube
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )}
            <Col md="4">
              <div className="footer_box">
                <p>{props?.content?.copy_right_text}</p>
                <p className="mt-2">
                  Powered by{" "}
                  <Link>
                    <img src="/assets/i/logo.svg" alt="" fluid className="mb-2 ml-2 org-logo" />
                    <img src={NewLogo} alt="" fluid className="mb-2 ml-2 org-logo-two d-none" />
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default SmashBurgerFooter;
