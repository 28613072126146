import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
import CheckoutLogin from "./CheckoutLogin";
import { Modal, Form, Button, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../axios_purchase";
import { toast } from "react-toastify";
import { updateUserLoggedInData } from "../../../store/actions/mallActions";

function SignInModal(props) {
	const storeData = useSelector((state) => {
		return { ...state.mall.storeFrontData };
	});

	const userLoggedInData = useSelector((state) => {
		return { ...state.mall.userLoggedInData };
	});

	const dispatch = useDispatch();

	const [mobile, setMobile] = useState("");
	const [password, setpassword] = useState("");
	const [showSpinner, setshowSpinner] = useState("");
	const [showError, setshowError] = useState("");

	useEffect(() => {
		setMobile("");
		setpassword("");
		setshowError("");
		setshowSpinner("");
	}, [props.showSingIn]);

	const signInHandler = () => {
		setshowSpinner(true);
		const postData = {
			[storeData?.config[0]?.customer_identifier]: mobile,
			password: password,
			store_id: window.$storeId,
		};

		axios
			.post(window.$storeUrl + window.$apiRoot + "/customer/VerifyLoginwPassword", postData)

			.then((res) => {
				if (res.data.success === "Y") {
					setshowSpinner(false);
					toast.success(res.data.response.msg, {
						position: toast.POSITION.BOTTOM_RIGHT,
						hideProgressBar: true,
						theme: "dark",
					});
					let successData = res.data.response.data;
					let userData = userLoggedInData;
					userData.is_loggedIn = true;
					userData.mobile_no = successData.customer_mobile;
					userData.token = successData.token;
					userData.user_id = successData.customer_id;
					userData.user_name = successData.customer_name;
					userData.email = successData.customer_email;
					let event = new CustomEvent("clospopup", { close: true });

					localStorage.setItem("user_data", JSON.stringify(userData));
					dispatch(updateUserLoggedInData(userData));
					if (window.$isWebView) {
						window.ReactNativeWebView.postMessage(JSON.stringify(userData));
					}
					props.hideModal("");

					toast.success(res.data.response.message, {
						position: toast.POSITION.BOTTOM_RIGHT,
						hideProgressBar: true,
						theme: "dark",
					});
				} else {
					toast.error(res.data.response.msg, {
						position: toast.POSITION.BOTTOM_RIGHT,
						hideProgressBar: true,
						theme: "dark",
					});
				}
			})
			.catch((err) => {
				toast.error("Please check your internet connection and try again", {
					position: toast.POSITION.BOTTOM_RIGHT,
					hideProgressBar: true,
					theme: "dark",
				});

				setshowSpinner(false);
			});
	};

	return (
		<>
			{window.$isStoreFront && storeData?.config[0]?.checkout_without_login === "Y" && props.showSingIn ? (
				<Modal
					show={props.showSingIn}
					onHide={(e) => {
						props.hideModal("");
					}}
					className="SignupModalnew"
					dialogClassName="modal-410 "
					centered
				>
					<Modal.Header className="border-0 p-0 d-flex justify-content-between align-items-start" closeButton>
						<Modal.Title className=" w-100 d-flex justify-content-between align-items-center">
							<div>
								<span className="font-16 font-weight-bold">Login</span>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-0">
						<Form className="signup-form">
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								{storeData?.config[0]?.customer_identifier === "mobile" ? (
									<>
										<Form.Label className="font-14 mb-1">Mobile Number</Form.Label>
										<input
											type="number"
											className="h-44 form-control"
											maxLength={11}
											value={mobile}
											onChange={(e) => {
												setMobile(e.target.value);
											}}
										/>
									</>
								) : (
									<>
										<Form.Label className="font-14 mb-1">Email</Form.Label>
										<input
											type="email"
											className="h-44 form-control"
											value={mobile}
											onChange={(e) => {
												setMobile(e.target.value);
											}}
										/>
									</>
								)}
							</Form.Group>

							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								<Form.Label className="font-14 mb-1 d-flex justify-content-between align-items-center">
									Password
									<Nav.Link
										onClick={() => {
											props.hideModal("");
											props.showModal("forget");
										}}
										className="font-12 font-weight-normal forgotaction p-0"
									>
										Forgot password?
									</Nav.Link>
								</Form.Label>
								<Form.Control
									type="password"
									className="h-44"
									value={password}
									onChange={(e) => {
										setpassword(e.target.value);
									}}
								/>
							</Form.Group>

							<Button
								onClick={() => {
									signInHandler();
								}}
								className={`${
									mobile.length === 0 || password.length === 0 ? "in-active" : ""
								} d-flex justify-content-center align-items-center w-100 my-btn btn border-0 mt-3 mb-3 create-btn h-44`}
							>
								Login
							</Button>
							<div className="member-box text-center d-flex justify-content-center font-14 mb-0 info-fill">
								Don't have an account?
								<Nav.Link
									href=""
									className="ml-1 p-0 font-14"
									onClick={() => {
										props.hideModal("");
										props.showModal("signup");
									}}
								>
									Sign Up
								</Nav.Link>
							</div>
						</Form>
					</Modal.Body>
				</Modal>
			) : (
				<Modal
					className="favouriteModal loginModal"
					show={props.showSingIn}
					onHide={(e) => {
						props.hideModal("");
					}}
				>
					<Modal.Header className="p-0 border-0" closeButton>
						<Modal.Title className="font-18 font-weight-bold">Sign In</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-0">
						<CheckoutLogin
							changeReloadModal={props.hideModal}
							source="login"
							hideText={true}
							guestLogin={window.$isStoreFront ? storeData?.config[0]?.guest_user_register : false}
						/>

						<div className="member-box mb-0 mt-3 text-center font-weight-normal">
							Don't have an account?{" "}
							<a
								onClick={() => {
									if (window.$storeId === "841705") {
										window.location.href = "/applynow?c=true";
									} else {
										props.hideModal("");
										props.showModal("signup");
									}
								}}
							>
								Create an account
							</a>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}

export default SignInModal;
