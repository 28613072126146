const initialState = {
    id:"",
    mobile_banner: null,
    web_banner: null,
    name: '',
    content: '',
    loader: false,
    show_banner: true,
    pagecount: 0,
    pageList: []
};

const PageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PAGE_STATES':
            state[action.obj.key] = action.obj.value
            return {
                ...state,
                loader: action.obj.loader
            };
        case 'SET_LOADER':
            return {
                ...state,
                loader: action.obj.loader
            };
        case 'SET_PAGE_COUNT':
            return {
                ...state,
                pagecount: action.pagecount
            };
        case 'SET_PAGE_LIST':
            return {
                ...state,
                pageList: action.payload
            }
        case 'UNSET_STATES':
            return {
                ...state,
                id:"",
                mobile_banner: null,
                web_banner: null,
                name: '',
                content: '',
                loader: false,
                show_banner: true,
                pageCount: 0,
                pageList: []
            };
        default:
            return state;
    }
};

export default PageReducer;