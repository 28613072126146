import React, { useState, useEffect, lazy, Suspense, Fragment } from "react";
import withErrorHandler from "../../withErrorHandler/withErrorHandler";
import { Button } from "react-bootstrap";
import topArrow from "../../../assets/images/ThemeImages/top_arrow.svg";
import axios from "../../../axios_purchase";
import "animate.css";
import { whatsAppChatPlugin } from "../../../constants";
import WebSkeletonHome from "../../../components/mall/sub-pages/StoreFrontSkeleton";
import BranchModal from "../../../components/storefront/SmashBurger/SmashComponents/BranchModal";
// import MetaDataComponent from "../../../components/storefront/Subcomponents/Home/MetaDataComponent";
import { useDispatch, useSelector } from "react-redux";
import WhatsappContact from "../../../components/storefront/UI/WhatsappChat";
import SmashAddsOnModal from "../../../components/storefront/SmashBurger/SmashComponents/SmashAddsOnModal";
import SmashBurgerHeader from "../../../components/storefront/SmashBurger/SmashComponents/SmashBurgerHeader";
import SpringMobfooter from "../../../components/storefront/ThemeSpringtide/ThemeSpringtideComponents/SpringMobfooter";
import SpringThemeFooter from "../../../components/storefront/ThemeSpringtide/ThemeSpringtideComponents/SpringThemeFooter";
import ThemeHeader from "../../../components/storefront/ThemeSpringtide/ThemeSpringtideComponents/ThemeHeader";
import SmashBurgerMobFooter from "../../../components/storefront/SmashBurger/SmashComponents/SmashBurgerMobFooter";
import SmashBurgerFooter from "../../../components/storefront/SmashBurger/SmashComponents/SmashBurgerFooter";
import KnowledgeHeader from "../../../components/storefront/KnowledgeStreams/knowledgeComponents/KnowledgeHeader.js";
import KsFooter from "../../../components/storefront/KnowledgeStreams/knowledgeComponents/KsFooter.js";
import KsMobFooter from "../../../components/storefront/KnowledgeStreams/knowledgeComponents/KsMobFooter.js";
const MetaDataComponent = lazy(() => import("../../../components/storefront/Subcomponents/Home/MetaDataComponent"));
// import WhatsappContact  from "../../../../components/storefront/UI/WhatsappChat";
// const WhatsappContact = lazy(() =>
//     import("../../../components/storefront/UI/WhatsappChat")
// );
// const SmashAddsOnModal = lazy(() =>
//     import(
//         "../../../components/storefront/SmashBurger/SmashComponents/SmashAddsOnModal"
//     )
// );
// const SmashBurgerHeader = lazy(() =>
//     import(
//         "../../../components/storefront/SmashBurger/SmashComponents/SmashBurgerHeader"
//     )
// );
// const SpringMobfooter = lazy(() =>
//     import(
//         "../../../components/storefront/ThemeSpringtide/ThemeSpringtideComponents/SpringMobfooter"
//     )
// );
// const SpringThemeFooter = lazy(() =>
//     import(
//         "../../../components/storefront/ThemeSpringtide/ThemeSpringtideComponents/SpringThemeFooter"
//     )
// );
// const ThemeHeader = lazy(() =>
//     import(
//         "../../../components/storefront/ThemeSpringtide/ThemeSpringtideComponents/ThemeHeader"
//     )
// );
// const SmashBurgerMobFooter = lazy(() =>
//     import(
//         "../../../components/storefront/SmashBurger/SmashComponents/SmashBurgerMobFooter"
//     )
// );
// const SmashBurgerFooter = lazy(() =>
//     import(
//         "../../../components/storefront/SmashBurger/SmashComponents/SmashBurgerFooter"
//     )
// );
// const KnowledgeHeader = lazy(() =>
//     import(
//         "../../../components/storefront/KnowledgeStreams/knowledgeComponents/KnowledgeHeader.js"
//     )
// );
// const KsFooter = lazy(() =>
//     import(
//         "../../../components/storefront/KnowledgeStreams/knowledgeComponents/KsFooter.js"
//     )
// );
// const KsMobFooter = lazy(() =>
//     import(
//         "../../../components/storefront/KnowledgeStreams/knowledgeComponents/KsMobFooter.js"
//     )
// );

const components = {
	KnowledgeHeader,
	KsFooter,
	KsMobFooter,
	ThemeHeader,
	SpringMobfooter,
	SpringThemeFooter,
	SmashBurgerHeader,
	SmashBurgerMobFooter,
	SmashBurgerFooter,
};

function SpringLayout({
	// state
	userLoggedInData,
	current_layout,
	addOnsModal,
	products,
	children,
	storeFrontData,

	// function
	getHomePageData,
	AddOnsModal,

	// rest props
	...rest
}) {
	const [state, setState] = useState({
		showScrollBtn: false,
		showModal: true,
	});

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setState({
				showScrollBtn: window.pageYOffset > 200 ? true : false,
			});
		});
		if (current_layout?.theme === "KnowledgeStream") getHomePageData();
	}, []);

	return (
		<>
			<main>
				<div className="body_springtide position-relative" style={{ scrollBehavior: "smooth" }}>
					{current_layout?.components?.length
						? current_layout?.components?.map((current_component, i) => (
								<LayoutComponent
									key={i}
									{...{
										storeFrontData,
										children,
										current_component,
										userLoggedInData,
										state,
									}}
								/>
						  ))
						: ""}

					{!current_layout?.layoutType && (
						<SmashAddsOnModal show={addOnsModal} handleClose={() => AddOnsModal()} products={products} />
					)}
				</div>
				<BranchModal show={window.$showBranchModal} />
				<MetaDataComponent />
			</main>
		</>
	);
}

export default withErrorHandler(SpringLayout, axios);

function LayoutComponent({ current_component: { name, content }, children, storeFrontData, userLoggedInData, state }) {
	if (name === "children") {
		return <Fragment>{children}</Fragment>;
	} else if (name === "others") {
		const scrollToTop = () =>
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		return (
			<Fragment>
				<div className={`p-0  fixed-bottom ${state.showScrollBtn ? "box_btn" : "whatsapp_btn"}`}>
					{(storeFrontData.store.show_contact_whatsapp === "Y" ||
						storeFrontData.store.show_contact_mobile === "Y") &&
					storeFrontData?.activePluginsIds.includes(whatsAppChatPlugin) ? (
						<WhatsappContact whatsapp={storeFrontData.whatsapp} />
					) : (
						""
					)}
				</div>

				<Button
					variant="link"
					className={
						state.showScrollBtn
							? "p-0 box_btn box_btn_up fixed-bottom fixed-uparrow"
							: "p-0 box_btn box_btn_up fixed-bottom d-none"
					}
					onClick={scrollToTop}
				>
					<img className="uparrowicon" src={topArrow} alt="" />
				</Button>
			</Fragment>
		);
	}
	const SpacificFectory = components[name];
	if (SpacificFectory) {
		return <SpacificFectory userData={userLoggedInData} content={content} />;
	}
	return <></>;
}
