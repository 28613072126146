import React, { useState } from "react";
import { Modal, Image } from "react-bootstrap";
import Ratingbrand from '../../../assets/i/rating_icon.svg';
import { useHistory } from "react-router";
function RatingModal(props) {
  const [showRatingModal, setShowRatingModal] = useState(true);
  const history = useHistory();
  return (
    <>
      <Modal
        className="favouriteModal loginModal ratingModalmain"
        id="ratingModal"
        centered
        show={showRatingModal}

      >
        <Modal.Header
          className="p-0 border-0 head-notification mb-0 position-absolute"
        // closeButton
        >
          <Modal.Title className="font-18 font-weight-bold"></Modal.Title>
        </Modal.Header>
        <div className="modal-content p-0">
          <div className="bodymodal bodyRatingmodal p-0 text-center">
            <h5 className="font-weight-bold mx-auto font-20 text-center">
              Rate your recent order
            </h5>

            <p>
              You placed an order on {props.recentOrder.order_new_date} {props.recentOrder.order_new_time}. How was your experience with
              us? Please share your feedback.
            </p>
            <div className="text-center">
              <Image src={Ratingbrand} alt="" className="mb-3" />
            </div>
          </div>
          <a
            className="co-btn d-flex justify-content-center align-items-center mx-auto font20"

            onClick={() => {
              history.push(`/order-rating/${btoa(props.recentOrder.main_order)}/${btoa(props.recentOrder.store_id)}`)
            }}>
            Rate Now
          </a>
        </div>
      </Modal>
    </>
  );
}

export default RatingModal;
