import React, { useState, useEffect } from "react";

const MatrixEffect = () => {
    const [showLogo, setShowLogo] = useState(false);
    const [showContainer, setShowContainer] = useState(false);

    useEffect(() => {
        const logoTimeout = setTimeout(() => {
            setShowLogo(true);
        }, 2000);

        const containerTimeout = setTimeout(() => {
            setShowContainer(true);
        }, 6000);

        return () => {
            clearTimeout(logoTimeout);
            clearTimeout(containerTimeout);
        };
    }, []);

    const renderOText = () => {
        const oTexts = [];
        for (let i = 0; i < 50; i++) {
            const lightOs = [];
            for (let j = 0; j < 50; j++) {
                lightOs.push(
                    <div className="light-o" key={j}>
                        o
                    </div>
                );
            }
            oTexts.push(
                <div className="o-text" key={i}>
                    {lightOs}
                </div>
            );
        }
        return oTexts;
    };

    return (
        <div className={`container-animation mw-100 ${showContainer ? "dissolve" : ""}`}>
            <div className={`background-animation d-flex ${showLogo ? "show1" : ""}`}>{renderOText()}</div>
            <div className="o-word">O</div>
            <div className={`logo ${showLogo ? "show" : ""}`}>
                <img className="finallogo" src="/assets/i/final_logo.svg" alt="" />
            </div>
        </div>
    );
};

export default MatrixEffect;
