import { Button, Image } from "react-bootstrap";
import React, { Component } from "react";
import GreyCross from "../../../assets/i/gray-cross.svg";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import axios from "../../../axios_purchase";
import SignInModal from "../../../components/mall/sub-pages/SignInModal";
import RegisterModal from "../../../components/mall/sub-pages/RegisterModal";
import ForgetModal from "../../../components/mall/sub-pages/ForgetModal";
import { toast } from "react-toastify";
import { FaChevronRight } from "react-icons/fa";

import { updateUserLoggedInData } from "../../../store/actions/mallActions";
class Sidebar extends Component {
	state = {
		activeCategory: "",
		showSingIn: false,
		showModalData: false,
		showForget: false,
		staticClass: "",
		keydata: Math.random(),
	};
	showCategory = (type) => {
		if (type == "backsidebar") {
			this.setState({ activeCategory: "" });
		} else {
			this.setState({ activeCategory: "active" });
		}
	};
	showModal = (type) => {
		this.props.onClickbtn("backBtn");
		if (type === "signup") {
			this.setState(() => ({
				keydata: Math.random(),
				showModalData: true,
				showSingIn: false,
				showForget: false,
			}));
		} else if (type === "forget") {
			this.setState(() => ({
				showForget: true,
				showSingIn: false,
				voucher_modal: false,
				showModalData: false,
				showLocation: false,
			}));
		} else if (type === "voucher_modal") {
			this.setState(() => ({
				showForget: false,
				showSingIn: false,
				voucher_modal: true,
				showModalData: false,
				showLocation: false,
			}));
		} else if (type === "location_model") {
			this.setState(() => ({
				showLocation: true,
				showSingIn: false,
				showForget: false,
				showModalData: false,
				voucher_modal: false,
			}));
		} else {
			this.setState(() => ({
				showSingIn: true,
				showForget: false,
				showModalData: false,
				voucher_modal: false,
				showLocation: false,
			}));
		}
	};

	hideModal = (type) => {
		if (type === "signup") {
			this.setState(() => ({
				showModalData: false,
			}));
		} else if (type === "forget") {
			this.setState(() => ({
				showForget: false,
			}));
		} else if (type === "location_model") {
			this.setState(() => ({
				showLocation: false,
			}));
		} else if (type === "voucher_modal") {
			this.setState(() => ({
				voucher_modal: false,
			}));
		} else {
			this.setState(() => ({
				showSingIn: false,
				keydata: Math.random(),
			}));
		}
	};

	logoutData = () => {
		localStorage.removeItem("user_data");
		this.props.updateUserLoggedInData({
			mobile_no: "",
			password: "",
			is_loggedIn: false,
			token: "",
			user_id: "",
			user_name: "",
			email: "",
		});
		this.props.onClickbtn("backBtn");
		this.setState(() => ({
			keydata: Math.random(),
		}));
		toast.success("Logged out successfully!", {
			position: toast.POSITION.BOTTOM_RIGHT,
			hideProgressBar: true,
			theme: "dark",
		});
	};

	handleClickLink = (link) => () => {
		if (this.props.location.pathname !== link) {
			this.props.history.push(link);
		}
		this.props.onClickbtn("backBtn");
	};

	render() {
		const { handleClickLink } = this;
		return (
			<>
				<div className={`${this.props.activebtn} sidebar bg-white storefrontSidebar`}>
					<div className="sidebar-head">
						<Button
							variant="link"
							className="p-0 font-14 d-flex align-items-center closeBtn"
							onClick={(e) => {
								this.props.onClickbtn("backBtn");
							}}
						>
							<Image src={GreyCross} />
							<span className="position-relative">Close</span>
						</Button>
					</div>
					<div className="sidebarbody">
						{/* <ul className="sidebar-links">
              <li>
                <Link
                  className="d-flex align-items-center justify-content-between"
                  to="/contact"
                >
                  Contact Us{" "}
                </Link>
              </li>
            </ul> */}
						<Button variant="link" className="p-0 ml-0 text-dark d-none align-items-center d-md-none mb-3">
							<p>
								<b className="font-weight-bold">Branch:</b> Smash DHA Lahore
							</p>
							<i className="font-12 ml-1">
								<FaChevronRight />
							</i>
						</Button>
						<h3 className="font-18 font-weight-bold mb-3">Account</h3>

						<ul className="sidebar-links border-0 mb-0">
							{/* {
                window.$storeId != 792303 ? (<li><Link className="d-flex align-items-center justify-content-between" to="/testimonials">Testimonials</Link></li>) : ''
              }
              {
                window.$storeId != 792303 ? (<li><Link className="d-flex align-items-center justify-content-between" to="/faq">Faqs</Link></li>) : ''
              }
              <li><Link className="d-flex align-items-center justify-content-between" to="/contactus">Contact Us</Link></li>
              {
                window.$storeId != 792303 ? (<li><Link className="d-flex align-items-center justify-content-between" to="/blog">Blogs</Link></li>) : ''
              }
              {
                window.$storeId == 792303 ? (<li><Link className="d-flex align-items-center justify-content-between" to="/aboutus">About us</Link></li>) : ''
              } */}
							{this.props?.current_menu?.length > 0 &&
								this.props?.current_menu?.map((menu, index) => {
									return (
										<>
											{!window.$isFromEasypaisaApp || !window?.mp ? (
												<li>
													<a
														className="d-flex align-items-center justify-content-between"
														onClick={handleClickLink(menu?.link)}
													>
														{menu.title}
													</a>
												</li>
											) : menu?.link.indexOf("shop") > 0 ? (
												<li>
													<a
														className="d-flex align-items-center justify-content-between"
														onClick={handleClickLink(menu?.link)}
													>
														{menu.title}
													</a>
												</li>
											) : (
												""
											)}
										</>
									);
								})}

							{this.props.userLoggedInData?.is_loggedIn && (
								<>
									{!window.$isFromEasypaisaApp ? (
										<li>
											<a
												className="d-flex align-items-center justify-content-between"
												onClick={handleClickLink("/my-account")}
											>
												My Account
											</a>
										</li>
									) : (
										""
									)}

									<li>
										<a
											className="d-flex align-items-center justify-content-between"
											onClick={handleClickLink("/order-details")}
										>
											My Orders
										</a>
									</li>
									{window.$storeId === "816691" || window.$storeId === 816691 ? (
										<li>
											<a
												className="d-flex align-items-center justify-content-between"
												onClick={handleClickLink("/my-appointments")}
											>
												My Appointments
											</a>
										</li>
									) : (
										""
									)}
									<li>
										<a
											className="d-flex align-items-center justify-content-between"
											onClick={handleClickLink("/wishlist")}
										>
											My Wishlist
										</a>
									</li>
									{!window.$isFromEasypaisaApp && window.$storeId !== "923821" ? (
										<li>
											<a
												className="d-flex align-items-center justify-content-between"
												onClick={handleClickLink("/my-wallet")}
											>
												My Wallet
											</a>
										</li>
									) : (
										""
									)}

									{!window.$isFromEasypaisaApp || !window?.mp ? (
										<li>
											<a
												className="d-flex align-items-center justify-content-between"
												onClick={(e) => {
													this.logoutData();
												}}
											>
												Logout
											</a>
										</li>
									) : (
										""
									)}
								</>
							)}
							{!this.props.userLoggedInData?.is_loggedIn &&
								!window.$isFromEasypaisaApp &&
								!window?.mp && (
									<>
										<li>
											<a
												className="d-flex align-items-center justify-content-between"
												onClick={() => {
													this.showModal();
												}}
											>
												Login
											</a>
										</li>
										<li>
											<a
												className="d-flex align-items-center justify-content-between"
												onClick={() => {
													this.showModal("signup");
												}}
											>
												Sign Up
											</a>
										</li>
									</>
								)}
						</ul>

						{window.$isSharedApp ? (
							<ul className="sidebar-links border-0 mb-0 nativelinks">
								<li>
									{" "}
									<a
										href="inapp://switchStore"
										className="d-flex align-items-center justify-content-between"
									>
										Switch Store
									</a>
								</li>
								<li>
									{" "}
									<a
										href="inapp://sellOnline"
										className="d-flex align-items-center justify-content-between"
									>
										Sell Online
									</a>
								</li>
							</ul>
						) : (
							""
						)}
					</div>
				</div>
				<SignInModal showSingIn={this.state.showSingIn} hideModal={this.hideModal} showModal={this.showModal} />

				<RegisterModal
					onchangeDefaultValue={this.onchangeDefaultValue}
					onChangePhoneNumber={this.onChangePhoneNumber}
					showModalData={this.state.showModalData}
					hideModal={this.hideModal}
					showModal={this.showModal}
				/>

				<ForgetModal showForget={this.state.showForget} hideModal={this.hideModal} showModal={this.showModal} />
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		...state.mall,
		...state.theme,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		updateUserLoggedInData: (data) => dispatch(updateUserLoggedInData(data)),
	};
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(withErrorHandler(Sidebar, axios));
