import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  updateCartItems,
  updateFinalCartData,
} from "../../../../store/actions/mallActions";
import config from "../../../../config";
const BranchModal = ({title, ...props}) => {
  const storeData = useSelector((state) => {
    return { ...state.mall.storeFrontData }
  })
  const branches = storeData?.branches?.data?.list;
  const [selectedbranch, setselectedbranch] = useState('');
  const [selectedBranchData, setselectedBranchData] = useState('')
  // const [showBranchModal, setshowBranchModal] = useState(false);
  const dispatch = useDispatch();

  const setBranchNow = () => {
    if (selectedbranch !== '') {
      localStorage.setItem("branchHost", selectedbranch)
      localStorage.setItem("branchData", selectedBranchData)
      localStorage.removeItem("cart_data");
      localStorage.removeItem("final_cart");
      dispatch(updateFinalCartData([]))
      dispatch(updateCartItems([]))
      setTimeout(() => {
        window.location.href = `/${config.stage === 'live' ? '' : config.stage}`
      }, 500);

    } else {
      toast.success('Select branch first.')
    }

  }

  const handleBranchClick = (element) => () => {
    setselectedbranch(element.url)
    setselectedBranchData(JSON.stringify(element))
  }
  // alert(props.show)
  return (
    <>
      <Modal show={props.show} className="branchModal" centered>
        <Modal.Header className="border-0 mb-0 branchesmodalheader" >
          <Modal.Title className=" w-100 d-flex justify-content-center align-items-center">
            <span className="font-24 font-weight-bold">Please Select Your {window.$BranchModalTitle ?? title ?? `Nearest Branch` }</span>
            {/* <Image src="/assets/i/close.svg" className="crs" /> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 position-relative pt-4">
          <div className="text-center">
            {/* {
              storeData?.store?.data?.store_logo !== '' ?
                <img
                  src={storeData?.store?.data?.store_logo}
                  alt=""
                  style={{height: "40px"}}
                  className="mb-3"
                />
                : storeData?.store?.data?.name
            } */}


            <Row className="branchrow">
              {
                branches.map((element, index) => (
                    <Col sm="12" key={index}>
                      <Button className={`w-100 outlineCta ${selectedbranch === element.url ? 'active' : ''}`}
                        onClick={handleBranchClick(element)}
                      >{element.name}{element?.branch_name ?  ` -  ${element?.branch_name}` : ''}</Button>
                    </Col>
                ))
              }
            </Row>
            <div className="text-center">
              <Button variant="link" className={`seeMorecta ${selectedbranch !== '' ? '' : 'disabledbtn'} `}
                onClick={setBranchNow}
              >
                confirm
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BranchModal;
