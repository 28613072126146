import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import App from "./App"
import {
    updateUserLoggedInData,
    updateCartItems,
    updateFinalCartData,
    removeCategories,
    loadMarketData,
    loadInitialData,
    setStoreFrontProducts,
    addDefaultValue,
} from "../store/actions/mallActions";

import {
    getCurrentTheme as getCurrentThemeFunc,
    setCurrentMenu as setCurrentMenuFunc,
    SetCurrentTheme as setCurrentThemeFunc,
    SetHeaderContent as setHeaderContentFunc,
    SetCurrentTestimonials as setCurrentTestimonialsFunc,
    SetCurrentFAQs as setCurrentFaqsFunc,
    UNSetCurrentTheme as unSetCurrentTheme
} from "../store/actions/ThemeActions";


function mapStateToProps(state) {
    return {
        ...state.mall,
        ...state.theme,
    };
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateUserLoggedInData,
            updateCartItems,
            updateFinalCartData,
            loadInitialData,
            loadMarketData,
            setStoreFrontProducts,
            removeCategories,
            addDefaultValue,
            setCurrentThemeFunc,
            setCurrentTestimonialsFunc,
            setCurrentFaqsFunc,
            setHeaderContentFunc,
            getCurrentThemeFunc,
            setCurrentMenuFunc,
            unSetCurrentTheme
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(App);
