const initialState = {
    products: [],
    wishListProducts:[],
};

const WishlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_WISHLIST_PRODUCTS':
            return {
                ...state,
                products: action.payload
            };
        case 'UNSET_WISHLIST_PRODUCTS':
            return {
                ...state,
                products:state.products.filter(pro => pro !== action.product_id)
            };
        case 'UPDATE_WISH_LIST':
            return {
                ...state,
                wishListProducts:(!state.wishListProducts.some((id)=> parseInt(id) === parseInt(action.payload))) ?
                    [...state.wishListProducts,action.payload]:state.wishListProducts.filter(pro => pro !== action.payload)
            }
        case 'UNSET_WISH_LIST':
            return {
                ...state,
                wishListProducts:[]
            }
        default:
            return {...state};
    }
};

export default WishlistReducer;