import axios from "../../axios_purchase";



export const unSetStates = () => {
    return { type: 'UNSET_WISHLIST_PRODUCTS', obj: {} }
}




export const setProducts = (payload) => {
    return { type: 'SET_WISHLIST_PRODUCTS', payload }
}


export const updateWishList = (payload) => {
    return { type: 'UPDATE_WISH_LIST', payload }
}

export const updateWishListArray = (payload) => {
    return { type: 'UPDATE_WISH_LIST_ARRAY', payload }
}
export const unSetWishListArray = (payload) => {
    return { type: 'UNSET_WISH_LIST', payload }
}


export const getAllWishlistProducts = (payload) => {
    return (dispatch, getState) => {
        let url = window.$storeUrl + window.$apiRoot + '/Wishlist/getAll';
        axios.post(url, payload).then(
            (res) => {
                if (res.data.status) {
                    dispatch(unSetStates(payload.product_id))
                    dispatch(updateWishList(payload.product_id))
                    let products = [];
                    if (res.data.data.length > 0) {

                        res.data.data.forEach(element => {
                            if(window.$storeId === element.store_id)
                                products.push(element.product_id);
                        });
                        dispatch(setProducts(products))
                    }
                }
            }
        ).catch((e) => {
        })
    }

}

export const addRemoveWishlist = (payload) => {
    return (dispatch, getState) => {
        let url = window.$storeUrl + window.$apiRoot + '/Wishlist/addRemoveWishlist';
        axios.post(url, payload).then(
            (res) => {
                if (res.data.status) {
                    dispatch(getAllWishlistProducts(payload))

                }



            }
        ).catch((e) => {
        })
    }

}

