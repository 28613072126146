import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom"

function useVisibility() {
  const [visibility, setVisibility] = useState(false)
  function handleVisibilityChange() {
    setVisibility(document.visibilityState === "hidden" ? false : true);
  }
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])
  return visibility;
}

function useHost(host = window?.location?.host) {
  const [state, setState] = useState(null);

  useEffect(() => {
    if (host) setState(host); else setState(null)
  }, [host]);

  return state;
}

/**
 * custom hook for query parameters
 */
function useQueryParams() {
  const location = useLocation();
  const history = useHistory();

  return queryParamsHandler({ location, history });
}

/**
 * Handle query parameters
 */
function queryParamsHandler({ location: { search }, history: { push } }) {
  const queryParams = new URLSearchParams(search);

  function getQureyParam() {
    let param = {};
    for (const [key, value] of queryParams.entries()) {
      param[key] = value
    }
    return param
  }

  function setQueryParam(name, value) {
    queryParams.set(name, value);
    push({
      search: queryParams.toString(),
    });
  }

  return [getQureyParam(), setQueryParam];
}

function useDebounce(value = null, delay = 500) {
  const [state, setState] = useState(null);
  useEffect(() => {
    const timeout = setTimeout(() => setState(value), delay)
    return () => {
      clearTimeout(timeout)
    };
  }, [value, delay]);

  return [state, setState];
}



export { useHost, useQueryParams, queryParamsHandler, useDebounce, useVisibility };