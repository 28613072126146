import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col, Alert, Image, Nav } from "react-bootstrap";
import axios_purchase from "../../../axios_purchase";
import { useDispatch, useSelector } from "react-redux";
import { updateUserLoggedInData } from "../../../store/actions/mallActions";
import OtpInput from "react-otp-input";
import axios from "../../../axios_purchase";
import PENICON from "../../../assets/i/editpen.svg";
import { toast } from "react-toastify";

function RegisterModal(props) {
	const mallSelect = useSelector((state) => {
		return { ...state.mall };
	});
	const storeData = useSelector((state) => {
		return { ...state.mall.storeFrontData };
	});
	const dispatch = useDispatch();
	const [cellNo, setCellNo] = useState("");
	const [full_name, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [nameError, setnameError] = useState("");
	const [phoneError, setphoneError] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setpasswordError] = useState("");
	const [password, setPassword] = useState("");
	const [otpSent, setotpSent] = useState(false);
	const [otp, setOtp] = useState("");
	const [startTimer, setStartTimer] = useState(0);

	const changeDefaultValue = (type, number) => {
		if (type === "cellNo") {
			setCellNo(number);
		} else if (type === "full_name") {
			setFullName(number);
		} else if (type === "email") {
			setEmail(number);
		} else if (type === "password") {
			setPassword(number);
		} else {
			setPassword(number);
		}
	};
	useEffect(() => {
		setotpSent(false);
		setCellNo("");
		setFullName("");
		setEmail("");
		setPassword("");
	}, [props.showModalData]);
	const registerUser = () => {
		if (validateForm()) {
			let registerData = {
				[storeData?.config[0]?.customer_identifier]:
					storeData?.config[0]?.customer_identifier === "mobile" ? cellNo : email,
				source: "register",
				isPasswordEnabled:
					window.$isStoreFront && storeData?.config[0]?.checkout_without_login
						? storeData?.config[0]?.checkout_without_login
						: "N",
				store_id: window.$storeId,
			};

			axios_purchase
				.post(window.$storeUrl + window.$apiRoot + "/customer/otp", registerData)
				.then((res) => {
					if (res.data.code === "11") {
						setotpSent(true);
						setStartTimer(59);
						// startSMSTimer()
						toast.success(res.data.response.msg, {
							position: toast.POSITION.BOTTOM_RIGHT,
							hideProgressBar: true,
							theme: "dark",
						});
					} else {
						toast.error(res.data.response.msg, {
							position: toast.POSITION.BOTTOM_RIGHT,
							hideProgressBar: true,
							theme: "dark",
						});
					}
				})
				.catch();
		} else {
		}
	};

	const validateForm = () => {
		let nameError = "";
		let phoneError = "";
		let passwordError = "";
		let emailError = "";
		if (full_name.length === 0) {
			nameError = "Full Name is Required";
			setnameError(nameError);
		}
		if (storeData?.config[0]?.customer_identifier === "mobile") {
			if (cellNo.length === 0) {
				phoneError = "Mobile Number is Required";
				setphoneError(phoneError);
			}
		} else {
			if (email.length === 0) {
				emailError = "Email is Required";
				setEmailError(emailError);
			}
		}

		if (window.$isStoreFront && storeData?.config[0]?.checkout_without_login === "Y") {
			if (password.length === 0) {
				phoneError = "Password is Required";
				setpasswordError(phoneError);
			}
		}

		if (phoneError || nameError || passwordError || emailError) {
			return false;
		}

		return true;
	};
	const handleOtpChange = (otp) => {
		setOtp(otp);
		if (otp.length > 3) {
			otpHandler(otp);
		}
	};

	const otpHandler = (otp) => {
		const postData = {
			mobile: cellNo,
			code: otp,
			email: email,
			password: password,
			name: full_name,
			register: "Y",
			indentifier: storeData?.config[0]?.customer_identifier,
			isPasswordEnabled:
				window.$isStoreFront && storeData?.config[0]?.checkout_without_login
					? storeData?.config[0]?.checkout_without_login
					: "",
			store_id: window.$storeId,
		};

		axios
			.post(window.$storeUrl + window.$apiRoot + "/customer/verifyLoginOTP", postData)

			.then((res) => {
				if (res.data.success === "Y") {
					let successData = res.data.response.data;

					let userData = mallSelect.userLoggedInData;
					userData.is_loggedIn = true;
					userData.mobile_no = successData.customer_mobile;
					userData.token = successData.token;
					userData.user_id = successData.customer_id;
					userData.user_name = successData.customer_name;
					userData.email = successData.customer_email;
					// userData.password = successData.password;

					localStorage.setItem("user_data", JSON.stringify(userData));
					if (window.$isWebView) {
						window.ReactNativeWebView.postMessage(JSON.stringify(userData));
					}
					dispatch(updateUserLoggedInData(userData));
					props.hideModal("signup");
				} else {
				}
			})
			.catch((err) => {});
	};

	const resendOtpHandler = () => {
		if (startTimer <= 1) {
			const postData = {
				[storeData?.config[0]?.customer_identifier]:
					storeData?.config[0]?.customer_identifier === "mobile" ? cellNo : email,
				source: "register",
			};

			axios
				.post(window.$storeUrl + window.$apiRoot + "/customer/otp", postData)

				.then((res) => {
					if (res.data.response.code === "11") {
						setotpSent(true);

						setStartTimer(59);
						// startSMSTimer()
					}
				})
				.catch((err) => {
					this.setState({
						showSpinner: false,
					});
				});
		}
	};
	return (
		<>
			{window.$isStoreFront && storeData?.config[0]?.checkout_without_login === "Y" && !otpSent ? (
				<Modal
					className="SignupModalnew"
					dialogClassName="modal-410 "
					centered
					show={props.showModalData}
					onHide={() => {
						props.hideModal("signup");
					}}
				>
					<Modal.Header className="border-0 p-0 d-flex justify-content-between align-items-start" closeButton>
						<Modal.Title className=" w-100 d-flex justify-content-between align-items-center">
							<div>
								<span className="font-16 font-weight-bold">Sign Up</span>
								<p className="font-13 font-weight-normal info-fill">
									Please fill in the information below
								</p>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-0">
						<Form className="signup-form">
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								<Form.Label className="font-14 mb-1">Name</Form.Label>
								<Form.Control
									type="text"
									className="h-44"
									onChange={(e) => {
										changeDefaultValue("full_name", e.target.value);
									}}
								/>
								{nameError.length > 0 && <span className="text-danger font-12">{nameError}</span>}
							</Form.Group>
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								<Form.Label className="font-14 mb-1 d-flex justify-content-between align-items-center">
									Mobile{" "}
									<span className="font-12 font-weight-normal info-fill">
										{storeData?.config[0]?.customer_identifier === "email" ? "Optional" : ""}
									</span>
								</Form.Label>
								<Form.Control
									type="number"
									className="h-44"
									maxLength={11}
									onChange={(e) => {
										changeDefaultValue("cellNo", e.target.value);
									}}
								/>
								{phoneError.length > 0 && <span className="text-danger font-12">{phoneError}</span>}
							</Form.Group>
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								<Form.Label className="font-14 mb-1 d-flex justify-content-between align-items-center">
									Email{" "}
									<span className="font-12 font-weight-normal info-fill">
										{storeData?.config[0]?.customer_identifier === "mobile" ? "Optional" : ""}
									</span>
								</Form.Label>
								<Form.Control
									type="email"
									className="h-44"
									onChange={(e) => {
										changeDefaultValue("email", e.target.value);
									}}
								/>
								{emailError.length > 0 && <span className="text-danger font-12">{emailError}</span>}
							</Form.Group>
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								<Form.Label className="font-14 mb-1">Password</Form.Label>
								<Form.Control
									type="password"
									className="h-44"
									onChange={(e) => {
										changeDefaultValue("password", e.target.value);
									}}
								/>
								{passwordError.length > 0 && (
									<span className="text-danger font-12">{passwordError}</span>
								)}
							</Form.Group>

							<Button
								className="d-flex justify-content-center align-items-center w-100 my-btn btn border-0 mt-3 mb-3 create-btn h-44"
								onClick={(e) => {
									registerUser();
								}}
							>
								Continue
							</Button>
							<div className="login-form  mx-auto w-100">
								{otpSent ? (
									<div className="otp-form">
										<div className=" form-group">
											<div className="verifaid-box d-flex justify-content-between align-items-center w-100">
												<span className="font14 reg_mobile">
													{storeData?.config[0]?.customer_identifier === "mobile"
														? cellNo
														: email}
												</span>
												<button
													type="button"
													onClick={() => setotpSent(false)}
													className="p-0 btn btn-link"
												>
													<img src={PENICON} className="" alt="edit" />
												</button>
											</div>
										</div>
										<p className="font-14  mb-2 ">
											Enter 4 digit OTP sent on your{" "}
											{storeData?.config[0]?.customer_identifier === "mobile"
												? "number"
												: "email"}
											.{" "}
										</p>

										<OtpInput
											value={otp}
											onChange={handleOtpChange}
											numInputs={4}
											isInputSecure={true}
											className="otpinput"
											containerStyle="otpcontainer"
											shouldAutoFocus={true}
										/>
									</div>
								) : (
									""
								)}
							</div>
							<div className="member-box text-center d-flex justify-content-center font-14 mb-0 info-fill">
								Already have an account?
								<Nav.Link
									href="#"
									className="ml-1 p-0 font-14"
									onClick={() => {
										props.showModal("");
									}}
								>
									Login
								</Nav.Link>
							</div>
						</Form>
					</Modal.Body>
				</Modal>
			) : (
				<Modal
					className="favouriteModal loginModal"
					show={props.showModalData}
					onHide={() => {
						props.hideModal("signup");
					}}
				>
					<Modal.Header className="p-0 border-0" closeButton>
						<Modal.Title className="font-18 font-weight-bold">
							Create an Account
							<span className="font-14 font-weight-normal d-block info-fill">
								Please fill in the information below
							</span>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-0">
						<Form className={`${otpSent ? "d-none" : ""} modal-form`} autocomplete="off">
							<Row>
								<Col md="12">
									<Form.Group>
										<Form.Label className="font-14">Mobile Number</Form.Label>
										<Form.Control
											className="font-14"
											type="tel"
											required
											value={cellNo}
											onChange={(e) => {
												changeDefaultValue("cellNo", e.target.value);
											}}
											maxLength="11"
										/>

										{phoneError.length > 0 && (
											<span className="text-danger font-12">{phoneError}</span>
										)}
									</Form.Group>
								</Col>
								<Col md="12">
									<Form.Group>
										<Form.Label className="font-14">Full Name</Form.Label>
										<Form.Control
											className="font-14"
											type="text"
											value={full_name}
											required
											onChange={(e) => {
												changeDefaultValue("full_name", e.target.value);
											}}
										/>

										{nameError.length > 0 && (
											<span className="text-danger font-12">{nameError}</span>
										)}
									</Form.Group>
								</Col>
								<Col md="12">
									<Form.Group>
										<Form.Label className="font-14">Email</Form.Label>
										<Form.Control
											className="font-14"
											type="text"
											value={password}
											onChange={(e) => {
												changeDefaultValue("", e.target.value);
											}}
											required
										/>
									</Form.Group>
								</Col>
							</Row>
						</Form>
						<div className="login-form  mx-auto w-100">
							{otpSent ? (
								<div className="otp-form">
									<div className=" form-group">
										<div className="verifaid-box d-flex justify-content-between align-items-center w-100">
											<span className="font14 reg_mobile">{cellNo}</span>
											<button
												type="button"
												onClick={() => setotpSent(false)}
												className="p-0 btn btn-link"
											>
												<img src={PENICON} className="" />
											</button>
										</div>
									</div>
									<p className="font-14  mb-2 ">Enter 4 digit OTP sent on your number.</p>

									<OtpInput
										value={otp}
										onChange={handleOtpChange}
										numInputs={4}
										isInputSecure={true}
										className="otpinput"
										containerStyle="otpcontainer"
										shouldAutoFocus={true}
									/>
								</div>
							) : (
								""
							)}
						</div>
						{!otpSent ? (
							<Button
								className="w-100 font-16 font-weight-bold text-white text-uppercase  create-btn d-flex align-items-center justify-content-center border-0"
								onClick={(e) => {
									registerUser();
								}}
							>
								create
							</Button>
						) : (
							""
						)}
						<div className="member-box mb-0 mt-3 text-center font-weight-normal">
							Already have an account?{" "}
							<a
								onClick={() => {
									props.showModal("");
								}}
							>
								Sign in
							</a>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</>
	);
}

export default RegisterModal;
