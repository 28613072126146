const defaultForm = () => ({
    categories: [],
    products: {},
    bestSellingProducts: [],
    featuredProducts: [],
    bannerImages: [],
    relatedProducts: [],
    middleBanner: [],
    bestSellingDetailProducts: [],
    updatedFeaturedProducts: [],
    storeFrontProducts: {},
    marketStores: {},
    marketProducts: {},
    marketDeals: {},
    marketCategories: {},
    storeFrontFeaturedProducts: {},
    storeFrontData: [],
    marketData: [],
    filteredProducts: [],
    filteredMarketStores: [],

})

const defaultState = {
    ...defaultForm(),
    cartData: [],
    searchCategoryData: [],
    filtersData: [],
    sub_categoryData: {},
    sub_category_child: {},
    finalCartData: [],
    userLoggedInData: {
        mobile_no: '',
        password: '',
        is_loggedIn: false,
        token: '',
        user_id: '',
        user_name: '',
        email: ''
    },
    facet_data: [],
    offer_banner: [],
    ordersData: [],
    backToHome: '/',
    userWallets: [],
    selectedCategory: 0,
    selectedSubcategory: 0,
    storeTotalProducts: 0,
    loadingProducts: false,
    alertMessage: '',
    alertClass: '',
    walletConfigs: {},
    productLikeSearch: [],
    loadingStorefrontData: false,
    utmParams: {},
    seletedMarketStoreType: 0,


}

const reducer = (state = defaultState, actions) => {
    switch (actions.type) {
        case 'SET_BEST_SELLING_PRODUCTS':
            return { ...state, bestSellingProducts: actions.data }
        case 'SET_FEATURED_PRODUCTS':
            return { ...state, featuredProducts: actions.data }
        case 'SET_BANNER_IMAGE':
            return { ...state, bannerImages: actions.data }
        case 'SET_CATEGORIES_DATA':
            return { ...state, categories: actions.data }
        case 'SET_KEY_VALUE_DATA':
            return { ...state, ...actions.obj }
        case 'SET_PRODUCT_DATA':
            return { ...state, products: actions.data }
        case 'SET_RELATED_PRODUCTS':
            return { ...state, relatedProducts: actions.data }
        case 'UPDATE_CART_DETAIL':
            return { ...state, cartData: actions.data }
        case 'UPDATE_SEARCH_ITEMS':
            return { ...state, searchCategoryData: actions.data }
        case 'UPDATE_MIDDLE_BAR':
            return { ...state, middleBanner: actions.data }
        case 'UPDATE_FILTER_DATA':
            return { ...state, filtersData: actions.data }
        case 'UPDATE_SUBCATEGORY_DATA':
            return { ...state, sub_categoryData: actions.data }
        case 'UPDATE_SUBCATEGORY_CHILD_DATA':
            return { ...state, sub_category_child: actions.data }
        case 'BEST_SELLING_UPDATE':
            return { ...state, bestSellingDetailProducts: actions.data }
        case 'UPDATE_FEAUTURED_DATA':
            return { ...state, updatedFeaturedProducts: actions.data }
        case 'UPDATE_FINAL_CART':
            return { ...state, finalCartData: actions.data }
        case 'UPDATE_USER_LOGGED_IN_DATA':
            return { ...state, userLoggedInData: actions.data }
        case 'ADD_FACET_DATA':
            return { ...state, facet_data: actions.data }

        case 'ADD_OFFER_BANNER':
            return { ...state, offer_banner: actions.data }
        case 'CUSTOMER_ORDER_DATA':
            return { ...state, ordersData: actions.data }
        case 'CUSTOMER_WALLET_DATA':
            return { ...state, userWallets: actions.data }

        // store front reducers start

        case 'LOAD_INITIAL_DATA':
            return { ...state, storeFrontData: actions.data }
        case 'LOAD_MARKET_DATA':
            return { ...state, marketData: actions.data }
        case 'SET_STOREFRONT_PRODUCTS':
            return { ...state, storeFrontProducts: actions.data }
        case 'SET_MARKET_STORES':
            return { ...state, marketStores: actions.data }
        case 'SET_MARKET_PRODUCTS':
            return { ...state, marketProducts: actions.data }
        case 'SET_MARKET_DEALS':
            return { ...state, marketDeals: actions.data }
        case 'SET_MARKET_CATEGORIES':
            return { ...state, marketCategories: actions.data }
        case 'SET_STOREFRONT_FEATURED_PRODUCTS':
            return { ...state, storeFrontFeaturedProducts: actions.data }
        case 'SET_PRODUCT_LIKE':
            return { ...state, productLikeSearch: actions.data }
        case 'SET_UTM_PARAMS':
            return { ...state, utmParams: actions.data }
        case 'SET_PRODUCTS_FILTER':
            return {
                ...state,
                selectedCategory: actions.cat_id,
                selectedSubcategory: actions.sub_cat_id,
                filteredProducts: actions.filteredProducts
            }
        case 'SET_FILTERED_MARKET_STORES':
            return {
                ...state,
                seletedMarketStoreType: actions.storeType,
                filteredMarketStores: actions.data
            }
        case 'REMOVE_CATEGORIES':
            return {
                ...state,
                selectedCategory: 0,
                selectedSubcategory: 0,
                filteredProducts: []
            }
        case 'REMOVE_MARKET_STORE_TYPE':
            return {
                ...state,
                seletedMarketStoreType: 0,
                filteredMarketStores: []
            }
        case 'FETCH_PRODUCTS_FAIL':
            return { ...state, loadingProducts: false }
        case 'FETCH_PRODUCTS_START':
            return { ...state, loadingProducts: true }
        case 'SET_WALLET_CONFIGS':
            return { ...state, walletConfigs: actions.data }
        case 'LOAD_MORE_PRODUCTS':
            return {
                ...state,
                loadingProducts: false,
                storeFrontProducts: state.storeFrontProducts.concat(actions.moreProducts),
            }
        // store front redecers end

        case "RESET_CART_DATA":
            return { ...state, finalCartData: [], cartData: [] }
        default:
            return state
    }
}
export default reducer